import Ads from 'Library/google';
import { addSlashes } from 'Library/helpers';

import { db, toggleDetailButton } from './collections';
import * as Common from './common';
import { $_ } from './common';

$(document).ready(function() {
    if (null !== document.querySelector('#detail')) {
        if (parseFloat(total_pack.replace(',', '.'), 10) > 0 && pack_items != null) {
            var next = current < total_pack ? current + 1 : 0;
            var prev = current > 0 ? current - 1 : total_pack - 1;

            var loadPack = true;
            if (Common.detectBrowser() == 'IE') {
                $('a.next').attr('href', $('.next-icon-1').attr('data-slug'));
                $('a.prev').attr('href', $('.prev-icon-1').attr('data-slug'));
                loadPack = false;
            }
            if (loadPack) set_list_pack(pack, icon_id);
        } else {
            $('.next').html('');
            $('.prev').html('');
            $('.prev-icon').hide();
            $('.next-icon').hide();
        }
        $('#detail').addClass('loaded');
        if (typeof toggleDetailButton == 'function') toggleDetailButton();
    }
});

var iconsSlider = function(speed) {
    //Private Variables
    var slider = this,
        active = false,
        svgActive = false,
        prev1,
        prev2,
        next1,
        next2,
        currentArr,
        packTotal = pack_items.length - 1,
        prevIcon2 = $('.prev-icon-2'),
        prevIcon1 = $('.prev-icon-1'),
        mainIcon = $('.main-icon'),
        nextIcon1 = $('.next-icon-1'),
        nextIcon2 = $('.next-icon-2'),
        headerTitle = $('.detail-header h1');

    updateItems();

    //Private Methods

    function updateItems() {
        (prev1 = current - 1 < 0 ? packTotal : current - 1),
            (prev2 = prev1 - 1 < 0 ? packTotal : prev1 - 1),
            (next1 = current + 1 > packTotal ? 0 : current + 1),
            (next2 = next1 + 1 > packTotal ? 0 : next1 + 1);

        currentArr = [
            pack_items[prev2].src,
            pack_items[prev1].src,
            pack_items[current].src,
            pack_items[next1].src,
            pack_items[next2].src,
        ];
    }

    function updateSelectors() {
        (prevIcon2 = $('.prev-icon-2')),
            (prevIcon1 = $('.prev-icon-1')),
            (mainIcon = $('.main-icon')),
            (nextIcon1 = $('.next-icon-1')),
            (nextIcon2 = $('.next-icon-2'));
    }

    //Public Methods
    this.setImages = function(direction) {
        if (direction === 'next') {
            prevIcon2
                .removeAttr('class')
                .addClass('next-icon next-icon-2')
                .not('[data-type="svg"]')
                .find('img')
                .attr('src', pack_items[next2].src);
            prevIcon1.removeAttr('class').addClass('prev-icon prev-icon-2');
            mainIcon.removeAttr('class').addClass('prev-icon prev-icon-1');
            nextIcon1.removeAttr('class').addClass('main-icon');
            nextIcon2.removeAttr('class').addClass('next-icon next-icon-1');
        }

        if (direction === 'prev') {
            prevIcon2.removeAttr('class').addClass('prev-icon prev-icon-1');
            prevIcon1.removeAttr('class').addClass('main-icon');
            mainIcon.removeAttr('class').addClass('next-icon next-icon-1');
            nextIcon1.removeAttr('class').addClass('next-icon next-icon-2');
            nextIcon2
                .removeAttr('class')
                .addClass('prev-icon prev-icon-2')
                .not('[data-type="svg"]')
                .find('img')
                .attr('src', pack_items[prev2].src);
        }

        updateSelectors();

        //if (svgActive && direction === 'next') getSVG(nextIcon2.not('[data-type="img"]'), pack_items[next2].src);
        //if (svgActive && direction === 'prev') getSVG(prevIcon2.not('[data-type="img"]'), pack_items[prev2].src);

        if (svgActive) {
            if (direction === 'next') {
                if (
                    (pack_items[next2].color == 1 && pack_items[next2].premium == 0) ||
                    (pack_items[next2].premium == 1 && gr.auth.me.premium)
                )
                    getSVG(nextIcon2.not('[data-type="img"]'), pack_items[next2].src);
                else nextIcon2.not('[data-type="img"]').html('<img src="' + pack_items[next2].src + '" />');
            }

            if (direction === 'prev') {
                if (
                    (pack_items[next2].color == 1 && pack_items[next2].premium == 0) ||
                    (pack_items[next2].premium == 1 && gr.auth.me.premium)
                )
                    getSVG(prevIcon2.not('[data-type="img"]'), pack_items[prev2].src);
                else prevIcon2.not('[data-type="img"]').html('<img src="' + pack_items[prev2].src + '" />');
            }
        }
    };

    this.navigation = function(direction) {
        var images = document.querySelectorAll('.gallery div.main-icon img');

        [].forEach.call(images, function(i) {
            var format = i.src.slice(-3);
            i.src = format == 'png' ? pack_items[current].png : pack_items[current].src;
        });

        if (!active) {
            active = true;
            svgActive = $('#detail .svg-version').hasClass('active');

            headerTitle.addClass('out');

            if (direction === 'next') {
                current++;

                prevIcon2.addClass('nextPos');
                prevIcon1.addClass('prevOut');
                mainIcon.addClass('mainPrev');
                nextIcon1.addClass('nextMain');
                nextIcon2.addClass('nextIn');

                ga('send', 'event', 'Detail View', 'Move', 'Next');
            }

            if (direction === 'prev') {
                current--;

                prevIcon2.addClass('prevIn');
                prevIcon1.addClass('prevMain');
                mainIcon.addClass('mainNext');
                nextIcon1.addClass('nextOut');
                nextIcon2.addClass('prevPos');

                ga('send', 'event', 'Detail View', 'Move', 'Previous');
            }

            if (current > packTotal) current = 0;
            if (current < 0) current = packTotal;

            updateItems();

            set_main();
            /*setTimeout(function() {
				// Count transition as pageview
				ga('send', 'pageview', window.location.href);
			}, 200);*/

            setTimeout(function() {
                headerTitle.removeClass('out');
            }, 300);

            setTimeout(function() {
                slider.setImages(direction);
                active = false;
            }, speed);
        }
    };
};

function set_main() {
    $('.main-icon-without-slide')
        .find('img')
        .attr('src', pack_items[current].src);

    get_equivalents(pack_items[current].id);

    if (pack_items[current].tags.length) {
        var ul = document.querySelector('ul.tags');
        ul.innerHTML = '';
        for (var i = 0; i < pack_items[current].tags.length; i++) {
            var li = document.createElement('li');
            var link = document.createElement('a');
            link.innerHTML = pack_items[current].tags[i].tag;
            link.href = pack_items[current].tags[i].slug;

            li.appendChild(link);
            ul.appendChild(li);
        }
    }

    var classIconType = pack_items[current].selection == 1 ? 'flaticon-selection' : 'hidden';
    classIconType = pack_items[current].premium == 1 ? 'icon icon--premium' : classIconType;

    var iconType = document.querySelector('#detail h1 span i');
    if (iconType !== null) {
        iconType.className = '';
        iconType.classList.add(classIconType);
    }

    document.querySelector('#detail h1 span').classList.toggle('premium', pack_items[current].premium == 1);

    var _search = pack_items[current].premium ? messages.common.free : 'premium';
    var _replace = pack_items[current].premium ? 'premium' : messages.common.free;
    $('h1 span.title').html(pack_items[current].description);
    var h1 = document.querySelector('h1');
    var text = h1.lastChild.nodeValue;
    h1.lastChild.nodeValue = text.replace(_search, _replace);

    /* modal-download */
    $_('.modal-download p.filename .description').innerHTML = pack_items[current].description;
    $_("meta[property='og:title']").content = pack_items[current].metaTwitterTitle;
    $_("meta[name='twitter:title']").content = pack_items[current].metaTwitterTitle;

    var share_image =
        pack_items[current].src
            .replace('www.flaticon', 'imageog.flaticon')
            .replace('svg/', 'png/512/')
            .replace('.svg', '.png') + '?size=1200x630f&pad=10,10,10,10&ext=png&bg=FFFFFFFF';

    $_('.modal-download p.filename img').src = pack_items[current].src;
    $_("meta[property='og:image']").content = share_image;
    $_("meta[name='twitter:image']").content = share_image;
    var tw_description = $_("meta[name='twitter:description']").content.split('.');
    tw_description[0] = tw_description[0].replace(_search, _replace);
    $_("meta[name='twitter:description']").content = tw_description.join('.');
    $_("meta[property='og:url']").content = pack_items[current].slug;
    $_("meta[property='og:description']").content = tw_description.join('.');
    $_('.modal-download .modal-body').classList.toggle('nopremium', !pack_items[current].selection);

    $_('#detail .publish_date').textContent = pack_items[current].published;

    var url = pack_items[current].slug;
    var category = document.querySelector('.category-icon');

    document.title =
        pack_items[current].description +
        ' -' +
        document.title
            .split('-')[1]
            .replace(Common.capitalize(category.innerHTML), Common.capitalize(pack_items[current].category))
            .replace(new RegExp('(' + _search + ')', 'gi'), Common.capitalize(_replace));

    /* category */
    if (pack_items[current].category) document.querySelector('.category-in-icon').classList.remove('hidden');
    else document.querySelector('.category-in-icon').classList.add('hidden');

    category.href = pack_items[current].category_url;
    category.innerHTML = pack_items[current].category;

    var upgrade_btn = $_('#fi-premium-upgrade-button .btn-upgrade');
    if (upgrade_btn) {
        upgrade_btn.href = ACCOUNTS_BASE_URL + 'premium/' + pack_items[current].pack_id;
    }

    if ('history' in window) {
        window.history.pushState({ flaticon: 'icon' }, pack_items[current].description, url);
    }

    //History.pushState({page:'icon'},pack_items[current].description,url);
    var url_path = url.replace(APP_URL, '');
    ga('send', 'pageview', '/' + url_path);

    $_('#download-form #icon_id').value = pack_items[current].id;
    $_('#download-form #author').value = pack_items[current].team_id;
    $_('#download-form #selection').value = pack_items[current].selection;
    $_('#download-form #colored').value = pack_items[current].color;
    $_('#download-form #premium').value = pack_items[current].premium;
    $_('#download-form #keyword').value = pack_items[current].description;

    set_list_tags();

    if (typeof toggleDetailButton == 'function') toggleDetailButton();

    if (window.innerWidth > 480)
        $('html,body').animate(
            {
                scrollTop: 0,
            },
            'slow',
        );

    let sponsorBannerElement = document.getElementById('s-p');

    if (null !== sponsorBannerElement) {
        sponsorBannerElement.dataset.term = pack_items[current].keyword;
        const settings = {
            premium: true,
            element: sponsorBannerElement,
            term: sponsorBannerElement.dataset.term,
        };
        SponsorBanner.insert(settings);
    }
}

function get_equivalents(id) {
    if (!id) return false;
    if (!iconsSlider.icon_equivalent_wrapper) return false;
    /*
	$.getJSON('/ajax/get_equivalents/'+id, function(data){
		iconsSlider.icon_equivalent_wrapper.innerHTML = '';

		for (var i in data)
		{
			data[i].active = (id == i) ? ' active' : '';
			data[i].id = i;

			iconsSlider.icon_equivalent_wrapper.insertAdjacentHTML("beforeend", iconsSlider.icon_equivalent_tpl(data[i]));
		}
	});
	*/

    $.ajax({
        url: '/ajax/get_equivalents/' + id,
        type: 'GET',
        async: true,
        dataType: 'json',
        success: function(data) {
            iconsSlider.icon_equivalent_wrapper.innerHTML = '';
            for (var i in data) {
                data[i].active = id == i ? ' active' : '';
                data[i].id = i;

                iconsSlider.icon_equivalent_wrapper.insertAdjacentHTML(
                    'beforeend',
                    iconsSlider.icon_equivalent_tpl(data[i]),
                );
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            iconsSlider.icon_equivalent_wrapper.innerHTML = '';
            console.error('AX ERROR[' + errorThrown + ']: ' + textStatus);
        },
    });
}

//
// Equivalents configuration
//
function configure_items_equivalents() {
    iconsSlider.icon_equivalent_wrapper = document.querySelector('.detail-styles .detail_equivalent_icons');

    if (iconsSlider.icon_equivalent_wrapper) {
        iconsSlider.icon_equivalent_tpl = Handlebars.tag('detail-icon-equivalent');
        iconsSlider.icon_equivalent_wrapper = document.querySelector('.detail-styles .detail_equivalent_icons');
    }
}

export function toggleSliderDetailButton() {
    if (null !== document.querySelector('#detail')) {
        if (window.toggleDetailBtnInterval) clearInterval(window.toggleDetailBtnInterval);

        if (
            typeof pack_items != 'undefined' &&
            null !== pack_items &&
            pack_items instanceof Array &&
            (current == parseInt(current, 10) || current == 0)
        ) {
            var icon_id = parseInt(pack_items[current].id, 10);
            var icon_name = pack_items[current].keyword;
            var icon_team = parseInt(pack_items[current].team_id, 10);
            var icon_color = parseInt(pack_items[current].color, 10);
        } else {
            var icon_id = parseInt(document.getElementById('icon_id').value, 10);
            var icon_name = document.querySelector('#download-form #keyword').value;
            var icon_team = parseInt(document.getElementById('team').value, 10);
            var icon_color = parseInt(document.getElementById('colored').value, 10);
            var icon_premium = '0';
        }

        if (window.db instanceof Object) {
            var method = 'addIcon';
            var collection = Collection.getActive();
            if (
                window.db.queryAll(collection.bookmark_id, {
                    query: {
                        id: icon_id,
                    },
                }).length
            ) {
                method = 'delIcon';
            }

            var btn = document.getElementById('detail-icon-button');
            if (btn) {
                var txt_btn;

                if (method === 'delIcon') {
                    btn.classList.add('btn-danger');
                    txt_btn = messages.collection.delete_icon;
                } else {
                    btn.classList.remove('btn-danger');
                    txt_btn = messages.collection.add_icon;
                }

                if (typeof btn.querySelector('span') != 'undefined') btn.querySelector('span').innerHTML = txt_btn;

                btn.setAttribute(
                    'onclick',
                    'Widget.' +
                        method +
                        '(' +
                        icon_id +
                        ",'" +
                        addSlashes(icon_name) +
                        "'," +
                        icon_team +
                        ', ' +
                        icon_color +
                        ', ' +
                        icon_premium +
                        ')',
                );

                btn.get('i').classList.toggle('icon--removecollection', method == 'delIcon');
            }
        } else {
            window.toggleDetailBtnInterval = setInterval(toggleDetailButton, 1000);
        }
    }
}

/*
 * Firsts tagged icons with a minimun of icons
 */

function set_list_tags() {
    var tags_list = pack_items[current].tags
        .map(function(tag) {
            return tag.tag_id;
        })
        .join();
    $.ajax({
        url: '/ajax/search/tags',
        data: {
            tags: tags_list,
            pack: pack,
        },
        type: 'POST',
        async: true,
        dataType: 'json',
        success: function(json) {
            var icons = json.items;
            var total = json.total;
            var tag = json.tag;
            $(INFINITY.css_selector).html('');
            $('section .tags a span').html(total);

            if (parseInt(total) > 0) {
                var breadcrumb = $('section.related-tags a.badge:last-child');
                breadcrumb.attr({
                    href: '/' + tag.slug,
                    title: tag.name,
                });
                breadcrumb.html(
                    messages.common.view_all +
                        ' - <span>' +
                        Common.capitalize(tag.name) +
                        '</span> ' +
                        messages.common.icons,
                );
                document.querySelector('.related-header p').innerHTML = `${messages.common.more}
                <a class="text-link" href="/${tag.slug}" title="${messages.common.more} ${Common.capitalize(
                    tag.name,
                )} ${messages.common.icons}">
                ${Common.capitalize(tag.name)} </a> ${messages.common.icons}`;
                $('.related-header a.badge span').html(tag.total);
                INFINITY.setUrl = false; //Global infinity.js
                INFINITY.css_selector = 'section.related-tags ul.icons';
                INFINITY.tplLoadItem(icons, false);
                var sponsorFreepikElement = document.getElementById('fi-premium-sponsor-freepik');
                if (sponsorFreepikElement.querySelector('iframe')) {
                    sponsorFreepikElement.querySelector('iframe').remove();
                    sponsorFreepikElement.insertAdjacentHTML('beforeend', SponsorBanner.getFreepikSponsor(tag.name));
                }
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.error('AX ERROR[' + errorThrown + ']: ' + textStatus);
        },
    });
}

/*
 * Icons to a tag
 */
function set_list_pack(pack, icon) {
    if (typeof ie !== 'undefined' && ie) {
        $('#detail').addClass('loaded ie9');
        return;
    }

    if (!(pack_items instanceof Array) || !pack_items.length) return;

    var mySlider = new iconsSlider(500);

    configure_items_equivalents();

    $('#detail a.prev, #detail a.next').fadeIn(250);

    $('#detail a.next').on('click', function(e) {
        e.preventDefault();
        mySlider.navigation('next');
    });

    $('#detail a.prev').on('click', function(e) {
        e.preventDefault();
        mySlider.navigation('prev');
    });

    $(document).on('keydown', function(e) {
        if (document.querySelector('.fi-modal input[type=checkbox]:checked')) {
            return;
        }

        var inputs = document.querySelectorAll(
            '.collection .collection-content.edit-mode .icons .collectionIcon .edit .collection-icon-rename, .collection .collection-header .edit-name.active .collection-name-input',
        );
        var focused = [].some.call(inputs, function(e) {
            return e == document.activeElement;
        });

        if (!focused) {
            if (e.keyCode == 39) mySlider.navigation('next');
            if (e.keyCode == 37) mySlider.navigation('prev');
        }
    });
}
