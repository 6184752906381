(function() {
    $(document).on('click', '.tag-field__items .remove', function(e) {
        let form = this.parent('form');
        remove_tag_and_reset_tag_value(this);
    });

    $(document).on('click', '.filters_delete_tag', function(e) {
        let form = document.querySelector('form[name="search-form"]');
        if (form) {
            form.querySelector('input[name="style_id"]').value = '';
        }
    });
})();

function remove_tag_and_reset_tag_value(remove_button) {
    clear_tag(remove_button.parentNode);
    remove_button.parentNode.remove(remove_button);
}

function reset_all_tags() {
    var close_button_tags = document.querySelectorAll('.tag-field__items .remove');
    if (close_button_tags.length) {
        close_button_tags.forEach(function(btn) {
            remove_tag_and_reset_tag_value(btn);
        });
    }

    var input_values = document.querySelectorAll('input[id*="main-search"]');
    if (input_values.length) {
        input_values.forEach(function(i) {
            i.value = '';
            // i.parentNode.removeChild(i);
            /**
             * FIXME, no entiendo que motivo se ha
             * tenido para tener que borrar este input
             * cuando previamente ya se esta borrando el
             * valor previamente asignado, esto esta
             * rompiendo ciertas partes de la web al no
             * comprobarse que estos elementos existan
             */
        });
    }
}

function clear_tag(tag) {
    var section = tag.dataset.section;
    var id_prefix = '_id';
    if (section === 'main_search_current_section') {
        id_prefix = '';
    }
    section = section.replace(/main_search_/, 'main-search-') + id_prefix;
    document.getElementById(section).value = '';
}
