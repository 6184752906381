import 'Variables';

import { q, ww } from 'bobjoll/ts/library/dom';
import { modal } from 'BobjollModal';
import { removeElement } from 'Library/helpers';
import { isMobile } from 'Library/helpers/mobile';
import { capitalize } from 'Library/helpers/text';
import { Downloads as NewDownloads } from 'Project/ts/common-app/downloads';
import { getModalDownload } from 'Project/ts/pods/modals/download/downloadModalService';
import { LazyModal } from 'Project/ts/pods/modals/lazyModal';
import User from 'Project/ts/pods/user/main';

import { download_collection } from './collections';
import * as Functions from './functions';
import { saveColor } from './svg.js';

const iconDetailView = 'icon-detail';
const uiconDetailView = 'uicons-detail-page';
const defaultDownloadSize = '512';
const checkCollectionOpenMobile = () => {
    return isMobile() && document.getElementById('collections').classList.contains('visible');
};

window.addEventListener('userSessionLoaded', () => {
    /**
     * EVENTS
     */

    // Close modal download
    $(document).on('click', '#download-premium', function() {
        document.getElementById('modal-download').checked = false;
        document.querySelector('body').classList.remove('modal-open');
    });

    // TODO: Check if its used
    // Download formats with color and size
    $(document).on('click', '#detail ul.size button', function() {
        var format = $('#format').val();
        var size = $(this).attr('data-size');

        $('#size').val(size);

        if (format == 'png' || format == 'svg') {
            check_user_licence_and_download();
        } else if (format === 'base64') {
            check_modal_download();

            download_final();
        }
    });

    /* New detail view downloads*/
    $(document).on('click', '#detail #download .btn, #detail #download .btn-svg a', function(e) {
        e.preventDefault();

        const format = $(this).attr('data-format');

        if (!format) {
            return;
        }

        let formatFormElement = document.querySelector('#download-form #format');

        if (ww.view.section == iconDetailView) {
            formatFormElement = document.querySelector('#detail #download-form #format');
        }
        if (ww.view.section == uiconDetailView) {
            formatFormElement = document.querySelector('#detail #download-uicon-form #format');
        }

        formatFormElement.value = format;

        if (
            format != 'png' &&
            format != 'jpg' &&
            !User.isPremium() &&
            !e.currentTarget.classList.contains('popover-button')
        ) {
            const detail = document.querySelector('#detail')
                ? document.getElementById('detail')
                : document.getElementById('detail-content');
            let iconTargetId = detail.dataset.elementid;

            (ww.view.section !== iconDetailView && ww.view.section !== uiconDetailView) || checkCollectionOpenMobile()
                ? LazyModal.callModalDownloadOnlyForPremium(iconTargetId)
                : getModalDownload(true);

            return;
        }

        if (
            format === 'png' ||
            format === 'jpg' ||
            format === 'svg' ||
            format === 'psd' ||
            format === 'eps' ||
            format === 'raw-svg'
        ) {
            check_user_licence_and_download();
        } else if (format === 'base64') {
            check_modal_download();

            download_final();
        }
    });

    $(document).on('click', '#detail ul.size a', e => {
        e.preventDefault();

        const currentSizeSelected = e.currentTarget;
        const size = currentSizeSelected.dataset.size;

        let sizesMenu = document.getElementById('select-size');

        if (!ww.view.section == uiconDetailView) {
            const sizesMenu = document.getElementById('select-size');
            if (sizesMenu) {
                sizesMenu.querySelector('span').innerHTML = `${size}px`;
            }
        }

        sizesMenu.querySelector('.popover-button').classList.remove('active');
        sizesMenu.querySelector('.popover-content').classList.remove('show-menu');

        if (ww.view.section == uiconDetailView || ww.Resource.resource.type === 'uicon') {
            document.querySelector('#download-uicon-form #size').value = size || defaultDownloadSize;
        } else {
            document.querySelector('#download-form #size').value = size || defaultDownloadSize;
            document.querySelector('#detail #download .btn').click();
        }
    });

    $(document).on('change', '#modal-download', function() {
        const copyButton = document.querySelector('.modal-download #copy-attributtion');
        copyButton.children[0].classList.add('active');
        copyButton.children[1].classList.remove('active');

        if (document.getElementById('modal-download').checked == false) {
            clean_download_form_and_modal();
            document.querySelector('body').classList.remove('modal-open');
        }
    });

    $(document).on('click', '.modal-download .modal-fade-screen', function() {
        const copyButton = document.querySelector('.modal-download #copy-attributtion');
        copyButton.children[0].classList.add('active');
        copyButton.children[1].classList.remove('active');
    });

    /**
     * Track download pack
     */
    $(document).on('click', '.track_download_pack', function(e) {
        if (!this.dataset.pack) return;

        if (UserSession.userInfo.logged) {
            if (!UserSession.userInfo.session.premium && this.dataset.premium == 1) {
                location.href = ACCOUNTS_BASE_URL + 'premium/' + this.dataset.pack;
                return;
            }

            document.querySelector('#form-download-pack [name="user_id"]').value = UserSession.userInfo.session.id;
            document.querySelector('#form-download-pack [name="pack_id"]').value = this.dataset.pack;

            User.updateUserStats(3000);

            if (UserSession.userInfo.session.premium) {
                let packId = document.querySelector('#form-download-pack [name="pack_id"]').value;
                NewDownloads.trackDownload(packId);
            } else {
                check_modal_download('pack', null);
            }
        }
    });

    $(document).on('click', '.modal__trigger', function() {
        const viewport = document.querySelector('#viewport');
        viewport.classList.remove('viewport');

        const trackType = this.dataset.type;
        initAttrModalMenu(trackType);
    });

    $(document).on('click', '.modal-attribution-new .copy__trigger', function() {
        this.previousElementSibling.select();
        document.execCommand('copy');
        this.children[1].classList.remove('active');
        this.children[2].classList.add('active');
    });
});

function check_user_licence_and_download() {
    if (!UserSession.userInfo.session.premium) {
        check_modal_download('icon');
    }
}

function direct_check_user_licence_and_download(icon) {
    if (!UserSession.userInfo.session.premium) {
        icon.classList.add('direct-download');
        check_modal_download('icon', icon);
    }
}

export function download_final(button = null) {
    saveColor(localStorage.color);

    var search = Functions.getUrlParameter('term')
        ? Functions.getUrlParameter('term')
        : Functions.getUrlParameter('word')
        ? Functions.getUrlParameter('word')
        : undefined;

    let formatElement;
    let idElement;
    const modal =
        ww.view.section !== iconDetailView || checkCollectionOpenMobile()
            ? document.querySelector('.modal-download')
            : document.querySelector('.modal-download-detail');

    if (ww.view.section === iconDetailView) {
        formatElement = document.querySelector('#detail #download-form #format');

        if (UserSession.userInfo.session.premium && button.dataset.format) {
            formatElement = document.querySelector('#detail #download-form #format');
            formatElement.value = button.dataset.format;
        }

        if (
            document.querySelector('#detail #download-form #size') &&
            !document.querySelector('#detail #download-form #size').value
        ) {
            document.querySelector('#detail #download-form #size').value = defaultDownloadSize;
        }

        if (button && button.dataset.format) {
            formatElement.value = button.dataset.format;
        }
    } else {
        if (button && button.parents('.icon--item')[0]) {
            idElement = button.parents('.icon--item')[0].dataset.id;
        } else if (modal) {
            idElement = modal.dataset.iconId;
        }

        formatElement = document.querySelector(`#download-form-${idElement} #format`);
    }

    if (formatElement && formatElement.value !== 'base64') {
        download_icon_post(search, button);
    } else {
        download_b64_post(search);
    }

    if (document.getElementById('modal-download') && formatElement && formatElement.value !== 'base64') {
        document.getElementById('modal-download').checked = false;
        document.querySelector('body').classList.remove('modal-open');
    }

    if (document.querySelector('.modal-download-detail')) {
        document.querySelector('.modal-download-detail').style.opacity = 0;
        removeElement(document.querySelector('.modal-download-detail'));
    }
}

function normalizeFields() {
    document.getElementById('author').value = document.getElementById('author').value || '0';
    document.getElementById('team').value = document.getElementById('team').value || '0';
    document.getElementById('pack').value = document.getElementById('pack').value || '0';
    document.getElementById('icon_id').value = document.getElementById('icon_id').value || '0';
    document.getElementById('format').value = document.getElementById('format').value || 'png';
    document.getElementById('color').value = document.getElementById('color').value || '#000000';
    document.getElementById('colored').value = document.getElementById('colored').value || '1';
    document.getElementById('size').value = document.getElementById('size').value || '512';
    document.getElementById('style').value = document.getElementById('style').value || 'Unknown';
    document.getElementById('keyword').value = document.getElementById('keyword').value || 'icon';
}

function trackDownload() {
    let format;
    if (ww.view.section == iconDetailView) {
        format = document.querySelector('#download-form #format').value;
    } else {
        format = document.getElementById('format').value;
    }

    let itemId = document.getElementById('icon_id').value;
    NewDownloads.trackDownload(itemId, format);

    normalizeFields();

    let iconType = 'icon';
    let downloadType = 'download';

    if (RESOURCE_TYPE === 'sticker') {
        iconType = RESOURCE_TYPE;
        downloadType = 'download-sticker';
    }

    if (ww.view.section == iconDetailView) {
        format = document.querySelector('#download-form #format').value;
    } else {
        format = document.getElementById('format').value;
    }

    if (format === 'raw-svg') {
        format = 'svg editable';
    }

    EventTracker.send('all', 'event', downloadType, iconType, format.toUpperCase(), {
        dimension8: document.getElementById('author').value,
        dimension2: document.getElementById('team').value,
        dimension10: document.getElementById('pack').value,
        dimension3: document.getElementById('icon_id').value,
        dimension4: document.getElementById('format').value,
        dimension5: document.getElementById('color').value,
        dimension6: document.getElementById('size').value,
        dimension12: document.getElementById('style').value,
        dimension7: document.getElementById('keyword').value,
    });

    EventTracker.send('gtm', 'event', downloadType, iconType, format.toUpperCase(), {
        dimension8: document.getElementById('author').value,
        dimension2: document.getElementById('team').value,
        dimension10: document.getElementById('pack').value,
        dimension3: document.getElementById('icon_id').value,
        dimension4: document.getElementById('format').value,
        dimension5: document.getElementById('color').value,
        dimension6: document.getElementById('size').value,
        dimension12: document.getElementById('style').value,
        dimension7: document.getElementById('keyword').value,
    });
}

function download_b64_post() {
    const iconImageElement = document.querySelector('.detail__icon__holder div[data-type="img"] img');

    if (!iconImageElement) {
        return;
    }

    User.isAllowedToDownload().then(allowed => {
        if (!allowed) {
            window.location.href = `${APP_URL}${messages.common.url_downloads_limit}`;
            return;
        }

        toDataURL(iconImageElement.src, function(imageBlob) {
            fillBase64Form(imageBlob);
            var formData = new FormData();
            formData.append('id', document.querySelector('#download-form #icon_id').value);
            var request = new XMLHttpRequest();
            request.open('POST', APP_URL + 'downloads/track-download');
            request.send(formData);
            trackDownload();
        });
    });
}

function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export function fillBase64Form(response) {
    var img = '<img src="' + response + '" />';

    $('#b64Image').val(img);

    var css = '<style type="text/css">';
    css += 'div.image {';
    css += 'max-width: 256px;';
    css += 'max-height: 256px;';
    css += 'background-image: url(' + response + ')';
    css += '}';
    css += '</style>';
    $('#b64css').val(css);

    document.getElementById('modal-base64').checked = true;
    newModalBase64();
    document.querySelector('body').classList.add('modal-open');
}

/**
 * Download file
 */
function download_icon_post(search = undefined, downloadButtonElement = null) {
    normalizeFields();
    let form = document.querySelector('#download-form');

    const isEmptySearch = ww.view.section === '404' && !!q('.empty-search');

    if (ww.view.section == iconDetailView || ww.view.section == 'category-detail') {
        if (ww.download_form) {
            form = ww.download_form;
        } else {
            form = document.querySelector('#detail #download-form');
        }
    } else if (
        (ww.view.section === 'icon-search' ||
            ww.view.section === 'style-search' ||
            ww.view.section === 'pack-detail' ||
            isEmptySearch) &&
        downloadButtonElement instanceof Event == false &&
        downloadButtonElement
    ) {
        let modal = downloadButtonElement.closest('.modal-download');
        if (modal) {
            let iconId = modal.dataset.iconId;
            if (iconId) {
                let icon = document.querySelector(`li.icon--item[data-id='${iconId}']:not(.collectionIcon)`);
                if (icon) {
                    form = icon.querySelector('#download-form-' + iconId);
                }
            }
        } else {
            form = ww.download_form;
        }
    }

    if (document.getElementById('modal-base64')) {
        document.getElementById('modal-base64').checked = false;
        cleanCopyBase64Button();
        document.querySelector('body').classList.remove('modal-open');
    }
    if (document.getElementById('modal-download')) {
        document.getElementById('modal-download').checked = false;
        document.querySelector('body').classList.remove('modal-open');
    }

    setTimeout(clean_download_form_and_modal, 2000);
    if (search !== undefined) {
        var inputSearch = document.createElement('input');
        inputSearch.type = 'hidden';
        inputSearch.name = 'search';
        inputSearch.value = search;
        form.appendChild(inputSearch);
    }

    trackDownload();
    form.submit();

    const isThumbnail = downloadButtonElement.closest('.icon--item') || downloadButtonElement.closest('.modal-download');
    const location = isThumbnail ? 'thumbnail' : 'resource_detail';

    const dataToSend = {
        event: 'ga4event',
        event_name: 'file_download',
        category: ww.Resource.resource.type === 'standard' ? 'icons' : `${ww.Resource.resource.type}s`,
        location: location,
        label: ww.Resource.resource.pack.name,
        file_id: ww.Resource.resource.id,
        file_name: ww.Resource.resource.name,
        author_name: ww.Resource.resource.author.name,
        file_extension: form.format.value,
        file_size: form.format.value === 'png'  ? form.size.value : '512',
        action: 'download', 
    }

    if(dataToSend.category === 'animated-icons') {
        dataToSend.category = 'animated_icons';
    }

    ww.EventTracker.send('ga4dl', dataToSend);

    const formSizeValueInput = document.querySelector('#detail #download-form #size')
    if(formSizeValueInput) {
        formSizeValueInput.value = defaultDownloadSize;
    }
}

export function direct_download_icon_post(e, format) {
    e.preventDefault();
    var icon = e.target.parents('.icon--item')[0];
    ww.Resource.setResourceFromElement(icon);

    if (ww.Resource.resource && ww.Resource.resource.type) {
        ww.Resource.setResourceConfig(ww.Resource.resource.type);
    }

    
    if (!UserSession.userInfo.session.premium && format === 'svg') {
        icon.classList.add('modal__trigger');
        icon.dataset.modal = 'modal-copy-svg-from-search-list-not-premium-user';
        LazyModal.callModalCopySvgFromSearchListNotPremiumUser();
        return;
    }
    

    prepare_forms_to_direct_download(icon, format);
    direct_check_user_licence_and_download(icon);
}

function prepare_forms_to_direct_download(icon, format) {
    if (check_if_download_form_exists(icon)) {
        bypass_download_form_and_create_new_form(icon, format);
    } else {
        create_temporal_download_form(icon, format);
    }
}

function remove_download_form_if_exists() {
    if (check_if_download_form_exists()) {
        return remove_form();
    }
    return false;
}

function bypass_download_form_and_create_new_form(icon, format) {
    document.getElementById('download-form-' + icon.dataset.id).setAttribute('id', 'detail-download-form');
    create_temporal_download_form(icon, format);
}

function clean_download_form_and_modal() {
    delete ww.download_form;
    if (document.querySelector('.icon--item.direct-download')) {
        document.querySelector('.icon--item.direct-download').classList.remove('direct-download');
    }
    if (document.getElementById('detail-download-form')) {
        document.getElementById('detail-download-form').setAttribute('id', 'download-form');
    }
}

function create_temporal_download_form(icon, format_type) {
    var values = {
        icon_id: icon.dataset.id,
        author: icon.dataset.author,
        team: icon.dataset.author,
        keyword: icon.dataset.name,
        pack: icon.dataset.pack,
        style: icon.dataset.style,
        format: format_type,
        color: '',
        colored: icon.dataset.color,
        size: '512',
        selection: icon.dataset.selection,
        premium: icon.dataset.premium,
        type: icon.dataset.icon_type,
    };

    var form = document.createElement('form');
    form.setAttribute('id', 'download-form-' + icon.dataset.id);
    form.setAttribute('method', 'GET');
    form.setAttribute('action', FLATICON_DOWNLOAD_URL + 'download/icon/' + icon.dataset.id);

    Object.keys(values).map(function(objectKey, index) {
        var value = values[objectKey];
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', objectKey);
        input.setAttribute('id', objectKey);
        input.setAttribute('value', value);
        form.appendChild(input);
    });

    if (values.premium == '0' && values.selection == '0') {
        document.querySelector('.modal-download section.modal-body').classList.add('nopremium');
    }

    icon.appendChild(form);
    ww.download_form = form;
}

function remove_form() {
    if (document.querySelector('.icon--item form#download-form')) {
        document
            .querySelector('.icon--item form#download-form')
            .parentNode.removeChild(document.querySelector('.icon--item form#download-form'));
        return true;
    }
    return false;
}

function check_if_download_form_exists(icon = null) {
    if (icon) {
        if (document.querySelector('#download-form #format')) {
            document.querySelector('#download-form #format').value = 'png';
        }

        return icon.querySelector('#download-form-' + icon.dataset.id) ? true : false;
    } else {
        return document.getElementById('download-form') ? true : false;
    }
}

export function download_pack() {
    let packId = document.querySelector('#form-download-pack [name="pack_id"]').value;
    NewDownloads.trackDownload(packId);

    $('#form-download-pack').trigger('submit');

    const downloadType = RESOURCE_TYPE === 'sticker' ? 'download-sticker' : 'download';

    EventTracker.send('ga', 'event', downloadType, 'pack');

    const dataToSend = {
        event: 'ga4event',
        event_name: 'pack_download',
        category: RESOURCE_TYPE === 'standard' ? 'icons' : `${RESOURCE_TYPE}s`,
        location: 'pack_detail',
        action: 'download',
        file_id: packId,
        file_extension: 'zip',
        file_size:'512',
        items_number: document.querySelector('#form-download-pack #items_number').value
    }

    if(dataToSend.category === 'animated-icons') {
        dataToSend.category = 'animated_icons';
    }

    ww.EventTracker.send('ga4dl', dataToSend);

    document.querySelector('#modal-download').checked = false;
    document.querySelector('body').classList.remove('modal-open');
}

var download_types = ['icon', 'pack', 'collection']; //TODO refactor
var ga_arguments_premium, mix_panel_json_premium, ga_arguments_copy, ga_credit_arguments_copy;
var licence;

export function check_modal_download(type, element_type) {
    const downloadTypes = {
        icon: 'icon',
        collection: 'collection',
        pack: 'pack',
    };

    if (ww.view.section === iconDetailView && type !== downloadTypes.collection && !element_type) {
        getModalDownload();
    }

    if (download_types.indexOf(type) == -1) {
        return;
    }

    let modal =
        ww.view.section !== iconDetailView ||
        type == downloadTypes.collection ||
        (element_type && element_type.classList.contains('direct-download'))
            ? document.querySelector('div.modal-download')
            : document.querySelector('.modal-download-detail');

    if (modal) {
        // SHOW & HIDE ELEMENTS
        modal_download_toogle_all(modal, type);

        // ICON
        if (type === downloadTypes.icon) {
            if (document.querySelector('.modal-body #download-free')) {
                document.querySelector('.modal-body #download-free').dataset.type = downloadTypes.icon;
            }
            check_modal_download_icon(modal, element_type);
        }

        // COLLECTION
        if (type === downloadTypes.collection) {
            ww.Resource.clearResource();
            document.querySelector('.modal-collection #download-free').dataset.type = downloadTypes.collection;
            check_modal_download_collection(modal);
        }

        // PACK
        if (type === downloadTypes.pack) {
            check_modal_download_pack(modal);
            document.querySelector('.modal-body #download-free').dataset.type = downloadTypes.pack;
        }

        if (
            ww.view.section !== iconDetailView ||
            type == downloadTypes.collection ||
            (element_type && element_type.classList.contains('direct-download'))
        ) {
            document.getElementById('modal-download').checked = true;
        }

        return;
    }
}

function modal_download_toogle_all(modal, type) {
    modal.classList.add('modal-' + type);

    modal.querySelector('#download-premium').classList.add('modal-' + type);
    modal.querySelector('#download-free').classList.add('modal-' + type);

    modal.querySelector('.modal-body').classList.remove('nopremium');

    //  SHOW ALL
    [].forEach.call(modal.querySelectorAll('.hidden'), function(all_element) {
        if (all_element && all_element.classList.contains('hidden')) {
            all_element.classList.remove('hidden');
        }
    });

    // GENERAL HIDE
    download_types.forEach(function(t) {
        if (t != type) {
            modal.classList.remove('modal-' + t);
            modal.querySelector('#download-premium').classList.remove('modal-' + t);
            modal.querySelector('#download-free').classList.remove('modal-' + t);

            [].forEach.call(modal.querySelectorAll('.modal-' + t), function(element_hide) {
                element_hide.classList.add('hidden');
            });
        }
    });
}

function setStartAttributionMessage(resourceType) {
    const resourceTypes = {
        icon: messages.attribution.icons_made_by,
        sticker: messages.attribution.stickers_made_by,
        standard: messages.attribution.icons_made_by,
        'animated-icon': messages.attribution.animatedIcon_made_by,
    };
    return resourceTypes[resourceType];
}

/**
 *
 */
function check_modal_download_icon(modal, element_type) {
    ga_arguments_premium = ['send', 'event', 'Detail View', 'Premium Download'];
    mix_panel_json_premium = { source: 'not-premium-detail', 'download-type': 'single', website: 'flaticon' };
    ga_arguments_copy = ['send', 'event', 'Detail View', 'Select Attribution Link'];
    ga_credit_arguments_copy = ['send', 'event', 'Detail View', 'Select Credit Attribution Link'];

    document.querySelector('.fi-modal #copy-attributtion').addEventListener('click', function() {
        this.children[0].classList.remove('active');
        this.children[1].classList.add('active');
        apply_ga_attributtion(this.closest('.copy-holder').querySelector('.input_atributtion_text'));
    });
    document.querySelector('.fi-modal #download-premium').addEventListener('click', apply_ga_download_premium);

    ww.download_form = document.getElementById('download-form');
    if (ww.view.section == iconDetailView) {
        ww.download_form = document.querySelector('#detail #download-form');
    }

    if (element_type) {
        // DESDE EL LISTADO
        const resourceData = ww.Resource.resource;
        let elementId = element_type.dataset.id;
        ww.download_form = document.querySelector(`#download-form-${elementId}`);
        modal.dataset.iconId = elementId;
        let iconImage = new Image();
        if (modal.querySelector('p.filename .icon-holder img')) {
            iconImage = modal.querySelector('p.filename .icon-holder img');
        }

        iconImage.src = element_type.querySelector('img').getAttribute('src');
        iconImage.classList.add('replaced-svg');
        let iconHolder = modal.querySelector('p.filename .icon-holder');
        iconHolder.insertAdjacentElement('afterbegin', iconImage);

        modal.querySelector('p.filename span.description').innerHTML = element_type.dataset.name;
        modal.querySelector('p.author span.description').innerHTML = 'By ' + element_type.dataset.author_name;

        let avatarImage = new Image();
        if (modal.querySelector('p.author img')) {
            avatarImage = modal.querySelector('p.author img');
        }
        avatarImage.src = element_type.dataset.author_avatar;
        avatarImage.classList.add('avatar');
        let avatarHolder = modal.querySelector('p.author');
        avatarHolder.insertAdjacentElement('afterbegin', avatarImage);

        if (element_type.dataset.selection == 0) {
            modal.querySelector('.modal-body').classList.add('nopremium');
        }

        if (resourceData) {
            licence = `<a href="${resourceData.keyword.link}" title="${
                resourceData.keyword.name
            } ${ww.Attribution.getAttributionTypeMessage(resourceData.type)}">${capitalize(
                resourceData.keyword.name,
            )} ${ww.Attribution.getStartAttributionMessage(resourceData.type)} ${
                resourceData.author.name
            } - Flaticon</a>`;
        } else {
            licence =
                '<div>' +
                setStartAttributionMessage(element_type.dataset.icon_type) +
                ' <a href="' +
                element_type.dataset.author_website +
                '" title="' +
                element_type.dataset.author_name +
                '">' +
                element_type.dataset.author_name +
                '</a> from <a href="' +
                APP_URL +
                '" title="Flaticon">' +
                window.location.host +
                '</a></div>';
        }
    } else {
        // DESDE EL DETALLE
        if (download_form) {
            let icon_info = document.querySelector('#detail')
                ? document.getElementById('detail')
                : document.getElementById('detail-content');
            let description = icon_info.querySelector('h1').innerHTML;

            if (
                ww.view.section !== iconDetailView ||
                checkCollectionOpenMobile() ||
                (element_type && element_type.classList.contains('direct-download'))
            ) {
                modal.querySelector('.filename .description').innerHTML = description;
            }

            if (
                ww.view.section !== iconDetailView ||
                checkCollectionOpenMobile() ||
                (element_type && element_type.classList.contains('direct-download'))
            ) {
                //TODO REFACTOR
                let main_img = icon_info.querySelector('.main-icon img');

                if (!main_img) {
                    main_img = icon_info.querySelector('.main-icon-without-slide img');
                }

                let iconImage = new Image();

                if (modal.querySelector('p.filename .icon-holder img')) {
                    iconImage = modal.querySelector('p.filename .icon-holder img');
                }

                iconImage.src = main_img.src;
                iconImage.classList.add('replaced-svg');
                iconImage.alt = description;

                let iconHolder = modal.querySelector('p.filename .icon-holder');
                iconHolder.insertAdjacentElement('afterbegin', iconImage);
            }

            var author_link = icon_info.querySelector('#author-url');
            var author_title = icon_info.querySelector('.author .username').title;
            var author_img = icon_info.querySelector('.author .avatar img');

            if (
                ww.view.section !== iconDetailView ||
                checkCollectionOpenMobile() ||
                (element_type && element_type.classList.contains('direct-download'))
            ) {
                let avatarImage = new Image();
                if (modal.querySelector('p.author img')) {
                    avatarImage = modal.querySelector('p.author img');
                }
                avatarImage.src = author_img.src;
                avatarImage.classList.add('avatar');
                let avatarHolder = modal.querySelector('p.author');
                avatarHolder.insertAdjacentElement('afterbegin', avatarImage);

                modal.querySelector('.author .description').innerHTML = 'By ' + author_title;
            }

            if (download_form.selection.value == 0) {
                modal.querySelector('.modal-body').classList.add('nopremium');
            }
            licence =
                '<div>' +
                setStartAttributionMessage(icon_info.dataset.icon_type) +
                ' <a href="' +
                author_link.value +
                '" title="' +
                author_title +
                '">' +
                author_title +
                '</a> from <a href="' +
                APP_URL +
                '" title="Flaticon">' +
                window.location.host +
                '</a></div>';
        }
    }

    if (
        ww.view.section !== iconDetailView ||
        checkCollectionOpenMobile() ||
        (element_type && element_type.classList.contains('direct-download'))
    ) {
        modal.querySelector('.copy-holder span').textContent = licence;
        modal.querySelector('.input_atributtion_text').setAttribute('value', licence);
    }
}

/**
 *
 */
function check_modal_download_pack(modal) {
    ga_arguments_premium = ['send', 'event', 'Pack', 'Premium Download'];
    mix_panel_json_premium = { source: 'not-premium-detail', 'download-type': 'pack', website: 'flaticon' };
    ga_arguments_copy = ['send', 'event', 'Pack', 'Select Attribution Link'];
    ga_credit_arguments_copy = ['send', 'event', 'Pack', 'Select Credit Attribution Link'];

    document.querySelector('.fi-modal #copy-attributtion').addEventListener('click', function() {
        this.children[0].classList.remove('active');
        this.children[1].classList.add('active');
        apply_ga_attributtion(this.closest('.copy-holder').querySelector('.input_atributtion_text'));
    });
    document.querySelector('.fi-modal #download-premium').addEventListener('click', apply_ga_download_premium);

    var packInfo = document.querySelector('#detail-pack-aside')
        ? document.getElementById('detail-pack-aside')
        : document.getElementById('detail-content');
    const author_link = packInfo.querySelector('#author-url').value;
    var author_title = packInfo.querySelector('.author .username').title;
    var author_img = packInfo.querySelector('.author .avatar img');

    let avatarImage = new Image();
    if (modal.querySelector('p.author img')) {
        avatarImage = modal.querySelector('p.author img');
    }
    avatarImage.src = author_img.src;
    avatarImage.classList.add('avatar');
    let avatarHolder = modal.querySelector('p.author');
    avatarHolder.insertAdjacentElement('afterbegin', avatarImage);

    modal.querySelector('.author .description').innerHTML = 'By ' + author_title;

    licence =
        '<div>' +
        setStartAttributionMessage(packInfo.dataset.icon_type) +
        ' <a href="' +
        author_link +
        '" title="' +
        author_title +
        '">' +
        author_title +
        '</a> from <a href="' +
        APP_URL +
        '" title="Flaticon">' +
        window.location.host +
        '</a></div>';

    modal.querySelector('.copy-holder span').textContent = licence;
    modal.querySelector('.input_atributtion_text').setAttribute('value', licence);

    modal.querySelector('.pack_name').innerHTML = document.querySelector('h1 .title').innerHTML;

    var sprite_pack = document.querySelector('meta[property="og:image"]');
}

function check_modal_download_collection(modal) {
    ga_arguments_premium = ['send', 'event', 'Collection', 'Premium Download'];
    mix_panel_json_premium = { source: 'not-premium-detail', 'download-type': 'collection', website: 'flaticon' };

    ga_arguments_copy = null;
    ga_credit_arguments_copy = null;

    modal.querySelector('#download-free').addEventListener('click', download_collection);
    modal.querySelector('#download-premium').addEventListener('click', apply_ga_download_premium);

    if (modal.querySelector('.author')) {
        modal.querySelector('.author').classList.add('hidden');
    }

    document.querySelector('.fi-modal #copy-attributtion').addEventListener('click', function() {
        this.children[0].classList.remove('active');
        this.children[1].classList.add('active');
        apply_ga_attributtion(this.closest('.copy-holder').querySelector('.input_atributtion_text'));
    });
}

/**
 *
 */
function apply_ga_attributtion(target = null) {
    target = target || document.querySelector('.fi-modal .input_atributtion_text');
    target.select();
    document.execCommand('copy');
    if (ga_arguments_copy) {
        ga(ga_arguments_copy[0], ga_arguments_copy[1], ga_arguments_copy[2], ga_arguments_copy[3]);
    }
}

/**
 *
 */
function apply_ga_attributtion_credit() {
    document.querySelector('.copy__text input').select();
    document.execCommand('copy');
    if (ga_credit_arguments_copy) {
        ga(
            ga_credit_arguments_copy[0],
            ga_credit_arguments_copy[1],
            ga_credit_arguments_copy[2],
            ga_credit_arguments_copy[3],
        );
    }
}

function apply_ga_download_premium(e) {
    if (e.currentTarget.dataset.trackArguments) {
        EventTracker.getArgsFromElementAndSend(e.currentTarget);
    }
    ga(ga_arguments_premium[0], ga_arguments_premium[1], ga_arguments_premium[2], ga_arguments_premium[3]);
}

export function open_attribution_modal() {
    window.Attribution.fromCollections = true;
    if (!UserSession.userInfo.session || UserSession.userInfo.session.premium === 0) {
        window.Attribution.initialize();
        window.Attribution.show();
        document.querySelector('.copy__trigger').addEventListener('click', apply_ga_attributtion_credit);
        return true;
    } else {
        return false;
    }
}

function get_url_param(param) {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
        vars[key] = value;
    });
    return vars[param];
}

function initAttrModalMenu(trackType) {
    let attrAccordionMenu = document.querySelectorAll('.accordion2__menu--item');
    let attrAccordionContent = document.querySelector('.accordion2__container');

    for (const z of attrAccordionMenu) {
        z.addEventListener('click', function() {
            for (const x of attrAccordionMenu) {
                x.classList.remove('active');
            }
            this.classList.add('active');

            let order = this.dataset.order;
            for (const y of attrAccordionContent.children) {
                y.classList.remove('active');
            }
            attrAccordionContent.children[order].classList.add('active');
        });
    }

    function insertTrackArguments(trackType) {
        let event = document.querySelector('.content-footer--text a');
        if (event) {
            if (trackType == 'icon') {
                event.dataset.trackArguments = 'ga, event, premium, icon-main-attribute-modal';
            }

            if (trackType == 'pack') {
                event.dataset.trackArguments = 'ga, event, premium, pack-main-attribute-modal';
            }
        }
    }

    insertTrackArguments(trackType);
}

function newModalBase64() {
    if (document.querySelector('.modal-base64')) {
        const body = document.querySelector('body'),
            modalAttributionNewLink = document.querySelector(
                ".modal-base64 button[data-modal='modal-attribution-new']",
            ),
            viewport = document.querySelector('#viewport'),
            copyAttributtionButtons = document.querySelectorAll('.fi-modal.modal-base64 .copy-attributtion'),
            modalCloseButton = document.querySelector('.modal-base64 .modal__close');

        let authorIconInfo = document.querySelector('#detail')
            ? document.querySelector('#detail')
            : document.querySelector('#detail-pack-aside');

        if (authorIconInfo) {
            const authorLink = authorIconInfo.querySelector('.author a')
                ? authorIconInfo.querySelector('.author a').href
                : authorIconInfo.querySelector('#author-url').value;
            const authorTitle = authorIconInfo.querySelector('.author .username').title;
            const modalBase64 = document.querySelector('div.modal-base64');
            const licence = `<div>${messages.attribution.icons_made_by} <a href="${authorLink}" title="${authorTitle}">${authorTitle}</a> from <a href="${APP_URL}" title="Flaticon">${window.location.host}</a></div>`;
            modalBase64.querySelector('.attribution__wrapper .copy-holder span textarea').textContent = licence;
            modalBase64.querySelector('.input_atributtion_text').setAttribute('value', licence);
        }

        modalAttributionNewLink.addEventListener('click', function() {
            modal.show('modal-attribution-new');
            viewport.classList.remove('viewport');
            const trackType = this.dataset.type;
            initAttrModalMenu(trackType);
        });

        copyAttributtionButtons.forEach(copyAttributtionButton => {
            copyAttributtionButton.addEventListener('click', function() {
                this.children[0].classList.remove('active');
                this.children[1].classList.add('active');
                event.target
                    .closest('.copy-holder')
                    .querySelector('textarea')
                    .select();
                document.execCommand('copy');
            });
        });

        modalCloseButton.addEventListener('click', function() {
            document.getElementById('modal-base64').checked = false;

            cleanFormatValue();
            cleanCopyBase64Button();
            body.classList.remove('modal-open');
        });
    }
}

export function cleanFormatValue() {
    const formatElement = document.querySelector('#detail #download-form #format');

    if (formatElement) {
        formatElement.value = '';
    }
}

function cleanCopyBase64Button() {
    const copyAttributtionButtons = document.querySelectorAll('.fi-modal.modal-base64 .copy-attributtion');
    copyAttributtionButtons.forEach(copyAttributtionButton => {
        if (copyAttributtionButton.children[1].classList.contains('active')) {
            copyAttributtionButton.children[0].classList.add('active');
            copyAttributtionButton.children[1].classList.remove('active');
        }
    });
}

if (RESOURCE_TYPE === 'animated-icon' && document.querySelector('#detail .download-dropdown-button')) {
    document.querySelector('#detail .download-dropdown-button').addEventListener('click', () => {
        getModalDownload();
    });
}
