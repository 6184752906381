import { ww } from 'bobjoll/ts/library/dom';
import { cookie } from 'Library/cookie';
import { addSlashes } from 'Library/helpers';
import xhr from 'Partials/xhr';
import { callHotjarTrigger } from 'Project/ts/common/hotjar.helpers';
import { Downloads } from 'Project/ts/common-app/downloads';
import getXHR from 'Project/ts/partials/xhr';
import { LazyModal } from 'Project/ts/pods/modals/lazyModal';
import User from 'Project/ts/pods/user/main';

import * as Common from './common';
import { $_ } from './common';
import * as FIdownload from './fi_download';
import * as Functions from './functions';
import { ModalDetail } from './modal_detail_history';
import { toggleSliderDetailButton } from './slider_icons';

const downloadValidateAPI = `${BASE_URL}ajax/download/validate`;

window.addEventListener('userSessionLoaded', () => {
    if (document.getElementById('collections-toggle')) {
        //
        // Edit Icon
        //
        $('.edit-icon-content').on('click', function(e) {
            e.stopPropagation();
        });
        $('.edit-icon-wrapper .close-button').on('click', function(e) {
            e.stopPropagation();
            Widget._openEditModal(false);
        });

        $(document).on('click', '.btn-edit-icon', function() {
            let formElement = document.querySelector('#download-form');
            if (window.view.section == 'icon-detail') {
                formElement = document.querySelector('#detail #download-form');
            }
            if (formElement) {
                Widget._openEditModal(true);
            }
        });

        $(document).on('click', '.context_btn-edit-icon', function(e) {
            e.preventDefault();
            var iconDetailLinkElement = e.currentTarget.closest('ul').querySelector('li .link-icon-detail');
            if (iconDetailLinkElement) {
                iconDetailLinkElement.dataset.edit = true;
                iconDetailLinkElement.click();
            }
        });

        $(document).on('click', '.only_registered .close-alert', function() {
            window.Editor.close();
            Widget.reset_widget();
        });

        $('.edit-icon-content .right > header button').on('click', Widget._toggleEditPanel);
        $('.edit-icon-content .left #download_button').on('click', function() {
            Widget._toggleEditPanel(true);
        });

        $('.collection').on('change', '.collection_icons_list li .edit input', function(e) {
            var checkbox = $(
                '.collection[data-order="0"] .collection-content.color-mode .icons li .edit label input:checked',
            );
            $('#edit-color').toggleClass('show', checkbox.length > 0);
        });

        $('.collections').on('change', '.collection_icons_list li .edit input', function(e) {
            var checkbox = $(
                '.collection[data-order="0"] .collection-content.edit-mode:not(.color-mode) .icons li .edit label input:checked',
            );
            var icon = this.parent('.icon--item');
            var bookmark_id = Collection.getActive().bookmark_id;
            Widget._openEditModal(checkbox.length > 0);

            $(document).on('click', '.collection_color_button', Widget.createCollectionCPicker);
        });

        $(document).on('click', '.edit-icon-content .right header button', function() {
            Widget._openEditModal(false);
            $('#edit-color').removeClass('show');
            $('.collection[data-order="0"] .collection_icons_list .icon--item .edit input[type=checkbox]:checked').prop(
                'checked',
                false,
            );
        });

        //
        // Drag&Drop
        //
        window.addEventListener(
            'dragstart',
            function(e) {
                e = e || event;
            },
            false,
        );
        window.addEventListener(
            'dragenter',
            function(e) {
                e = e || event;
                e.preventDefault();
            },
            false,
        );
        window.addEventListener(
            'dragleave',
            function(e) {
                e = e || event;
                e.preventDefault();
            },
            false,
        );
        window.addEventListener(
            'dragover',
            function(e) {
                e = e || event;
                e.preventDefault();
            },
            false,
        );
        window.addEventListener(
            'dragend',
            function(e) {
                e = e || event;
                e.preventDefault();
            },
            false,
        );
        window.addEventListener(
            'drop',
            function(e) {
                e = e || event;
                e.preventDefault();
            },
            false,
        );

        //
        // Sort mode
        //
        $(document).on('dragstart', '.collection-content.sort-mode .collectionIcon', function(e) {
            e.originalEvent.dataTransfer.setData('text/plain', 'icon');
            Icon.moveDragStart(e);
        });

        $(document).on('dragleave', '.collection-content.sort-mode .collectionIcon', function(e) {
            Icon.moveDragLeave(e);
        });

        $(document).on('drop', '.collection-content.sort-mode .collectionIcon', function(e) {
            Icon.moveDrop(e);
        });

        $(document).on('dragend', '.collection-content.sort-mode .collectionIcon', function(e) {
            Icon.moveDragEnd(e);
        });

        $(document).on('dragenter', '.collection-content.sort-mode .collectionIcon', function(e) {
            Icon.moveDragEnter(e);
        });

        //
        // Upload file
        //
        $('#btn-fetch-collection').on('click', Collection.fetch);

        $(document).on('click', '.overlay-drop .register button', function(e) {
            e.preventDefault();
            this.closest('.overlay-drop').style.display = 'none';
            this.closest('.overlay-drop').classList.remove('register');
        });

        $(document).on('click', '.overlay-drop .upgrade button', function(e) {
            e.preventDefault();
            this.parentNode.parentNode.style.display = 'none';
            this.parentNode.parentNode.classList.remove('upgrade');
        });

        $(document).on('click', '.overlay-drop .close-button', function() {
            this.closest('.overlay-drop').style.display = 'none';
            this.closest('.overlay-drop').classList.remove('upgrade');
        });

        $(document).on('click', '.collection-content:not(.sort-mode) .icon-upload', function(e) {
            e.preventDefault();
            this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('input.fileCollection').click();
        });

        $(document).on('change', '.fileCollection', function(e) {
            e.preventDefault();
            if (!Collection.checkUploadLimits(e)) return false;

            if (e.target.files.length) {
                for (var i = 0; i < e.target.files.length; i++) {
                    e.target.files[i].id = Date.now().toString() + i;
                    Icon.checkUploadSvg(e.target.files[i]);
                }
                document.getElementById('fileCollection-' + Collection.getActive().bookmark_id).value = '';
            }
        });

        $('.collections').on('click', '.help_link', function(e) {
            e.preventDefault();
            var win = window.open(this.href, '_blank');
            win.focus();
        });

        //$(".modal-collection-pack #download-collection-free").on("click", function(e) {
        $('.modal-download.modal-collection #download-free').on('click', function(e) {
            e.stopPropagation();

            $('[name="download-collection-form"].active-form').trigger('submit');
            $_('#modal-download').checked = false;
            $_('#modal-credit').checked = FIdownload.open_attribution_modal();
        });

        $('#modal-download').on('change', function(e) {
            if (!this.checked) {
                let collectionBtnDownload = document.querySelector('.collection.expanded .download-holder button');
                if (null !== collectionBtnDownload) {
                    collectionBtnDownload.classList.remove('disabled');
                    document.getElementById('collections').classList.remove('loading');
                }
            }
        });

        // $(".modal-collection-pack .modal-fade-screen").on("click" ,function(e){
        $('.modal-download .modal-fade-screen').on('click', function(e) {
            document.querySelector('.collection.expanded .download-holder button').classList.remove('disabled');
            document.getElementById('collections').classList.remove('loading');
        });

        $('#max-collections').on('click', '.max-collections__item', function(e) {
            e.stopPropagation();
            var btnConfirmElement = document.get('#max-collections #button-confirm');
            if (btnConfirmElement) {
                var collectionsSelected = document.querySelectorAll('input[name=choice]:checked');
                collectionsSelected.length == Collection.limit.collections
                    ? (btnConfirmElement.disabled = false)
                    : (btnConfirmElement.disabled = true);
            }
        });

        $('#max-collections').on('click', '#button-confirm', function() {
            var ids = [];

            document.querySelectorAll('input[name=choice]:checked').forEach(function(checkboxElement) {
                let collection = checkboxElement.closest('li');
                ids.push(parseInt(collection.getAttribute('data-bookmark'), 10));
            });

            window.db.update(
                'list',
                function(collection) {
                    return !~ids.indexOf(parseInt(collection.bookmark_id, 10));
                },
                function(collection) {
                    var element = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");
                    let elementHeader = $_(
                        ".collection[data-bookmark='" + collection.bookmark_id + "'] .collection-header",
                    );
                    let elementPopOverMenu = $_(
                        ".collection[data-bookmark='" +
                            collection.bookmark_id +
                            "'] .collection-header .header-options .popover-content",
                    );
                    let elementPopOverMenuChildren = elementPopOverMenu.querySelectorAll('p, hr, div');

                    for (var i = 0; i < elementPopOverMenuChildren.length; i++) {
                        if (elementPopOverMenuChildren[i].className != 'option-buttons') {
                            elementPopOverMenuChildren[i].remove();
                        }
                    }
                    element.classList.add('locked');
                    elementHeader.insertAdjacentHTML(
                        'beforeend',
                        `<div class="locked-options"><i class="icon icon--lock"></i></div>`,
                    );

                    var options = element.get('.popover .menu-options');
                    if (options) {
                        [].forEach.call(options.querySelectorAll('li button.collection-option'), function(option) {
                            if (!option.classList.contains('delete-collection')) {
                                option.classList.add('hidden');
                            }
                        });
                        if (options.querySelector('.locked-collection') != null) {
                            options.querySelector('.locked-collection').classList.remove('hidden');
                        }
                    }
                    //     options.parentNode.removeChild(options);
                    var download_wrapper = element.get('.download-wrapper');
                    if (download_wrapper) download_wrapper.parentNode.removeChild(download_wrapper);
                    var upgrade_btn = element.get('.upgrade_btn');
                    if (upgrade_btn) upgrade_btn.classList.remove('hidden');
                    collection.status = 0;
                    Collection.emit('set', { bookmark_id: collection.bookmark_id, status: 0 });
                    return collection;
                },
            );
            window.db.commit();

            $_('#max-collections').classList.remove('visible');
        });

        $('#collections').on('click', '.collection_share_button', function(ev) {
            var shareUrl;
            var shareLink = Array.from(ev.target.parentNode.offsetParent.children);
            shareLink.filter(item => {
                if (item.classList.contains('selectInput')) {
                    shareUrl = item.value;
                }
            });
            Collection.share(this.dataset.type, shareUrl);
        });

        $(document).on('click', '.collection-icon-delete, .collectionIcon .icon--removecollection', function(e) {
            e.stopPropagation();
            e.preventDefault();
            Widget.delIcon(parseInt(this.parent('.icon--item').dataset.id, 10));
        });

        $(document).on('click', '.icon--item .overlay button.add-remove', function(e) {
            e.stopPropagation();
            e.preventDefault();
            var elem = this.parent('.icon--item');
            var spanTxt = elem.get('.tooltip .content span');
            Widget[elem.classList.contains('selected') ? 'delIcon' : 'addIcon'](
                elem.dataset.id,
                elem.dataset.name,
                elem.dataset.team,
                elem.dataset.color,
                elem.dataset.premium,
                elem.dataset.custom_color,
                elem.dataset.icon_src,
            );

            if (spanTxt) {
                if (elem.classList.contains('selected')) {
                    spanTxt.innerHTML = 'Remove from Collection';
                } else {
                    spanTxt.innerHTML = 'Add to collection';
                }
            }
        });

        $(document).on('click', '.collection .collection-header', function(e) {
            //$(document).on("click", ".collection .header-options .flaticon-edit_collection_icons", function(e) {
            e.stopPropagation();
            window.launching = true;
            var parent = this.parent('.collection');
            //var parent = e.target.parentNode.parentNode;
            if (!parent.classList.contains('expanded')) {
                window.firstColorChange = true;
                Collection.setActive(parent.dataset.bookmark);
            }
            // $_("#list_collections_btn").disabled = false;
        });

        $(document).on('click', '.collection-header .edit-collection', function(e) {
            //$(document).on("click", ".collection .header-options .flaticon-edit_collection_icons", function(e) {
            e.stopPropagation();
            window.launching = true;
            var parent = this.parent('.collection');
            //var parent = e.target.parentNode.parentNode;
            if (!parent.classList.contains('expanded')) {
                window.firstColorChange = true;
                Collection.setActive(parent.dataset.bookmark);
            } else {
                if (!parent.classList.contains('locked')) {
                    var edit = parent.get('.edit-name');
                    edit.classList.add('active');
                    edit.get('input').select();
                }
            }
            // $_("#list_collections_btn").disabled = false;
        });

        $(document).on('click', '.collectionIcon .edit .popover-button', function() {
            Widget.createIconsCPicker(this);
        });

        $(document).on('click', '.collection .edit-name:not(.active), .edit-name.active .flaticon-disk', function(e) {
            e.stopPropagation();
            Collection.rename(this);
        });

        $(document).on('click', '.download-holder button', function(e) {
            e.stopPropagation();
            this.parent('.collection')
                .get('.collection-download')
                .classList.add('active');

            let downloadForms = document.querySelectorAll('[name="download-collection-form"]');
            for (const form of downloadForms) {
                form.classList.remove('active-form');
            }

            let downloadForm = this.parent('.collection').querySelector('[name="download-collection-form"]');
            downloadForm.classList.add('active-form');
        });

        $(document).on('click', '#collections ul.download li button', function(e) {
            e.stopPropagation();
            e.preventDefault();

            this.parent('.download-files')
                .get('.customize')
                .classList.add('active');
        });

        // Close icon customization in detail page
        $(document).on('click', '.collection-download.active .download-button', function(e) {
            e.stopPropagation();
            var collection = this.parent('.collection');
            collection.get('.collection-download .customize').classList.remove('active');
            collection.get('.collection-download').classList.remove('active');
            if (this.dataset.format != 'png' && !User.isPremium()) {
                LazyModal.callModalDownloadOnlyForPremium();
                return;
            }
            Collection.download(collection.dataset.bookmark, this.dataset.format, this.dataset.size);
        });

        $(document).on('click', '.collection-download.active .header-download button', function(e) {
            e.stopPropagation();
            var collection = this.parent('.collection');
            collection.get('.collection-download .customize').classList.remove('active');
            collection.get('.collection-download').classList.remove('active');
        });

        $(window).on('resize', function() {
            clearTimeout(window.resizeWindowInterval);
            window.resizeWindowInterval = setTimeout(function() {
                var collection_content = $_('.collection.expanded .collection-content');
                delete window.resizeWindowInterval;
            }, 500);
        });

        $(document).on('keyup', '.collection-name-input', function(e) {
            e.preventDefault();
            if (e.which == 13) Collection.rename(this);
            if (e.which == 27) {
                this.value = this.dataset.original;
                this.parentElement.classList.remove('active');
                this.blur();
            }
        });

        $(document).on('click', '.collection-icon-rename', function(e) {
            e.stopPropagation();
            this.select();
        });

        $(document).on('keyup', '.collection-icon-rename', function(e) {
            e.preventDefault();
            if (e.which == 13) Icon.rename(this);
            if (e.which == 27) this.value = this.dataset.original;
        });

        $(document).on('blur', '.collection-icon-rename', Icon.rename);


        // Toggle collections
        $(document).on('click', '#collections-toggle', function(e) {
            e.stopPropagation();
            Widget.toggle();
        });

        // Edit collection name
        // $(document).on("click", '.collection-header button.flaticon-edit_collection_icons', function(e) {
        $(document).on('click', '.collection-header button.edit-collection', function(e) {
            e.stopPropagation();
            var edit = this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector(
                '.edit-name',
            );
            closeCollectionsMenu();
            [].forEach.call(document.find('.collection .collection-header .edit-name'), function(e) {
                e.classList.remove('active');
            });

            edit.classList.add('active');
            edit.get('input').select();
        });

        // Toggle edit mode
        //$(document).on("click", '.collection-options button.flaticon-edit_collection_icons', Collection.openEditMode);
        //$(document).on("click", ".collection .collection-content .header-edit .btn-back", Collection.closeEditMode);
        $(document).on('click', '.collection-options .toggle-edit', Collection.openEditMode);
        $(document).on('click', '.collection .collection-content .header-edit .btn-back', Collection.closeEditMode);

        // Toggle sort mode
        $(document).on('click', '.collection-options .toggle-sort', Collection.openSortMode);
        $(document).on('click', '.collection .collection-content .header-sort .btn-back', Collection.closeSortMode);

        // Toggle share layer
        $(document).on('click', '.collection-options .toggle-share', Collection.toggleShareLayer);
        $(document).on('click', '.collection-share .close', Collection.toggleShareLayer);
        $(document).on('click', '.collection-options .toggle-backup', Collection.toggleBackupLayer);
        $(document).on('click', '.collection-backup .close', Collection.toggleBackupLayer);

        // Modal delete-collection
        $(document).on(
            'click',
            '.collection-header button.flaticon-bin, .collection.locked .locked-options button',
            function(e) {
                e.stopPropagation();
                var checkbox = $_('#modal-delete-collection');
                var btn = checkbox.parentNode.get('.modal-inner button.modal_delete_collection_btn');
                btn.dataset.bookmark = this.dataset.bookmark;
                checkbox.checked = true;
            },
        );

        $('.modal_delete_collection .modal_delete_collection_btn').on('click', function(e) {
            e.stopPropagation();
            var bookmark_id = this.getAttribute('data-bookmark');
            if (bookmark_id) {
                Collection.del(bookmark_id);
            }
            Common.closeModal(this);
        });

        $(document).on('click', '.collection .color-selector li button', function(e) {
            $(this.parent('.color-selector').nextElementSibling).colpickSetColor(this.dataset.color);
        });

        $(document).on('click', '.collection_color_button', Widget.createCollectionCPicker);

        /*
         *
         *
         * UPLOAD DE BACKUP
         *
         */

        $(document).on('change', '.upload-file-input', function(e) {
            var file = this.files[0];
            var textType = /text.*/;
            if (file.type.match(textType)) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    $.ajax({
                        url: APP_URL + 'Collections/restore_backup',
                        type: 'POST',
                        data: JSON.stringify(reader.result),
                        success: function(response) {
                            Collection.restore_backup(response);
                        },
                    });
                };
                reader.readAsText(file);
                this.value = '';
            } else {
                return;
            }
        });

        /*
         * Paint Collection
         */

        $(document).on('click', '#paint_collection', function() {
            if (document.getElementById('collections').classList.contains('expanded')) {
                document.querySelector('.paint-popover').classList.remove('popover-left');
                document.querySelector('.paint-popover').classList.add('popover-right');
            } else {
                document.querySelector('.paint-popover').classList.remove('popover-right');
                document.querySelector('.paint-popover').classList.add('popover-left');
            }
            Widget.createCollectionCPicker();
            document
                .querySelector('section[data-bookmark="' + Collection.getActive().bookmark_id + '"] .trigger_popover')
                .click();
        });

        document.getElementById('new_collection_btn').addEventListener('click', () => {
            const isSomeCollectionEmpty = document.querySelectorAll('.collection.empty');
            const maxNumberOfEmptyCollections = 1;
            if (isSomeCollectionEmpty.length >= maxNumberOfEmptyCollections && User.isLogged()) {
                Common.UserAlerts.warning('WARNING', messages.collection.empty_collection_limit, false);
            } else {
                EventTracker.send('all', 'event', 'collection', 'create');
                Collection.new();
            }
        });

        $(document).on('click', '.empty-collection-register .close', function() {
            document.querySelector('.icons-collection-noregister').style.display = 'none';
            document.querySelector('.collection-content').classList.remove('nologged-limit-reached');
        });

        $(document).on('click', '.collection-locked-inside .close', function() {
            this.closest('.collection-locked-inside').style.display = 'none';
        });

        const collectionTooltip = document.querySelector('.add-collections-holder');

        if (!User.isLogged() && !User.isPremium()) {
            collectionTooltip.children[1] ? collectionTooltip.children[1].remove() : null;
        } else {
            collectionTooltip.children[2] ? collectionTooltip.children[2].remove() : null;
        }
    }
    Collection.init();
});

$(document).on('click', '#collections-holder .download-holder button', function() {
    var bookmarkIconIds = window.db.queryAll(Collection.getActive().bookmark_id).map(a => a.id);

    $.ajax({
        url: APP_URL + 'ajax/collections/license',
        type: 'POST',
        data: {
            bookmarkIconIds: bookmarkIconIds,
        },
        success: function(response) {
            const modal = document.querySelector('div.modal-download');
            if (modal) {
                let license = '';
                response.forEach(function(author) {
                    const contributionUrl =
                        author.slug === 'freepik'
                            ? messages.attribution.freepik_url
                            : `${APP_URL}${messages.url.authors}/${author.slug}`;
                    license += `<div>${messages.attribution.icons_made_by} <a href="${contributionUrl}" title="${author.name}">${author.name}</a> from <a href="${APP_URL}" title="Flaticon">${window.location.host}</a></div>`;
                });

                modal.querySelector('.copy-wrapper').classList.remove('hidden');
                modal.querySelector('.copy-holder span').textContent = license;
                modal.querySelector('.input_atributtion_text').setAttribute('value', license);
            }
        },
    });
});

const initDownload = () => {
    $('[name="download-collection-form"].active-form').trigger('submit');
    $_('#modal-download').checked = false;

    FIdownload.open_attribution_modal();

    var bookmarkId = document.querySelector('.active-form [name="downC_bookmark_id"]').value;
    var collection = Collection.getCollectionData(bookmarkId);
    var items = [];
    if (collection && collection.icons.length > 0) {
        items = collection.icons.map(item => item.id);
    }

    Logs.pushLog(`download|type,collection|url,${window.location.href}|items,${items.join(';')}`, true);
    document.querySelector('.fi-modal #download-free').removeEventListener('click', download_collection);
};

export async function download_collection(event) {
    event.preventDefault();
    event.stopPropagation();

    try {
        await xhr(`${downloadValidateAPI}?resourceType=icon`, {
            withCredentials: true,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });

        initDownload();
    } catch (err) {
        if (err.status === 429) {
            let modalDownload = document.getElementById('modal-download');
            if (modalDownload) {
                modalDownload.checked = false;
                document.querySelector('body').classList.remove('modal-open');
            }
            window.location.href = `${APP_URL}${messages.common.url_downloads_limit}`;
        } else {
            console.error('Error validate download ', err);
        }
    }
}

function isValidColor(color) {
    return /((^[0-9A-F]{6}$|XXXXXX))/i.test(color);
}

/**
 *
 * ID           (Int) [protected]
 * bookmark_id  (String/Int)
 * name         (String)
 * color        (String)
 * created      (Int)
 * updated      (Int)
 * active       (Int)
 * order        (Int)
 * source       (String)
 */

function Icon(id, name, author, color, bookmark_id, premium, sort) {
    if (typeof id != 'number') throw 'Invalid icon id';
    if (!bookmark_id) throw 'Invalid bookmark_id';
    if (typeof author != 'number') throw 'Invalid author';

    this.premium = parseInt(premium) || 0;
    this.id = parseInt(id, 10);
    this.author = parseInt(author, 10);
    this.bookmark_id = bookmark_id;
    this.color = isValidColor(color) ? color : 'XXXXXX';
    this.sort = parseInt(sort, 10);

    if (typeof name != 'undefined') {
        this.name = name.toString().length ? Common.slug(name.toString()) : 'icon-' + this.id;
    } else {
        this.name = 'icon-' + this.id;
    }

    if (
        !window.db.queryAll(this.bookmark_id, {
            query: {
                id: this.id,
            },
        }).length
    ) {
        var data = {};

        var self = this;
        Icon.properties.forEach(function(i) {
            data[i] = self[i];
        });

        window.db.insertOrUpdate(
            this.bookmark_id,
            {
                id: data.id,
            },
            data,
        );

        window.db.commit();

        Widget.updateToggle();
    }
}

Icon.properties = ['id', 'name', 'author', 'color', 'premium', 'sort', 'imageUrl'];

Icon.set = function(id, values, bookmark_id, loading) {
    if (!id) return false; // borrar linea
    if (!id) throw 'Invalid icon id (Icon.set)';
    if (!bookmark_id) throw 'Invalid bookmark_id (Icon.set)';

    var updatable = ['color', 'name', 'sort', 'id', 'premium', 'imageUrl'];
    for (var k in values) {
        if (updatable.indexOf(k) == -1) {
            delete values[k];
        }
    }

    if (!window.db.tableExists(bookmark_id)) throw 'Table ' + bookmark_id + " doesn't exists (Icon.set)";
    if (!Object.keys(values).length) throw ('Nothing to update (Icon.set) ', values);

    var date = Common.getTimestamp();

    window.db.update(
        'list',
        {
            bookmark_id: bookmark_id,
        },
        function(collection) {
            collection.updated = date;
            return collection;
        },
    );

    window.db.update(
        bookmark_id,
        {
            id: id,
        },
        function(icon) {
            for (var i in values) {
                icon[i] = values[i];
            }

            values = {};

            Icon.properties.forEach(function(field) {
                values[field] = icon[field];
            });

            values.bookmark_id = bookmark_id;
            values.updated = date;

            if (!loading) {
                Collection.emit('put_icon', values);
            }
            return icon;
        },
    );
    window.db.commit();
};

Icon.refreshSVG = function(data) {
    var icon = $_(".collection[data-bookmark='" + data.bookmark_id + "'] .icon--item[data-id='" + data.original + "']");
    icon.dataset.color = data.color;
    var url = secureImgPath.imageUrl;

    icon.get('svg,img').outerHTML = '<img src="{0}" data-premium="4"></img>'.format(url);

    if (icon.dataset.color != 'XXXXXX') {
        Widget.getSVGCode({ id: data.original, bookmark: data.bookmark_id, src: url, premium: 4, color: data.color });
        Icon.set(data.original, { id: data.original, color: data.color }, data.bookmark_id, true);
        icon.dataset.id = data.original;
    } else {
        Widget.getSVGCode({ id: data.id, bookmark: data.bookmark_id, src: url, premium: 4, color: data.color });
        Icon.set(data.original, { id: data.id, premium: 4, color: data.color }, data.bookmark_id, true);
        icon.dataset.id = data.id;
    }

    if (typeof toggleDetailButton == 'function') toggleDetailButton();
};

Icon.toggle = function(id) {
    if (typeof toggleDetailButton == 'function') toggleDetailButton();
    var icons = document.querySelectorAll(".icons .icon--item[data-id='" + id + "']:not(.collectionIcon)");
    if (!icons.length) return;

    var btn;
    var actions;
    var spanTxt;

    [].forEach.call(icons, function(i) {
        i.classList.toggle('selected');
        btn = i.get('.overlay .add-remove');
        actions = ['add', 'remove'];
        spanTxt = i.get('.tooltip .content span');
        if (i.classList.contains('selected')) actions.reverse();
        btn.classList[actions[0]]('icon--addcollection');
        btn.classList[actions[1]]('icon--removecollection');
        if (actions[0] === 'remove') {
            spanTxt.textContent = 'Remove from collection';
        }
        if (actions[0] === 'add') {
            spanTxt.textContent = 'Add to collection';
        }
    });
};

Icon.rename = function(elem) {
    elem = elem instanceof Element ? elem : elem.currentTarget;
    var name = elem.value.length ? Common.slug(elem.value) : elem.dataset.original;
    if (name == elem.dataset.original) return;
    var id = parseInt(elem.parent('.icon--item').dataset.id, 10);
    var bookmark_id = $_(".collection[data-order='0']").dataset.bookmark;

    Icon.set(
        id,
        {
            name: name,
        },
        bookmark_id,
    );

    elem.dataset.original = name;
    elem.value = name;

    ga('send', 'event', 'Collection', 'Rename Icon');
};

Icon.cleanSvgIcon = function(content) {
    var wrapper = document.createElement('div');
    wrapper.innerHTML = content;

    var nodes = wrapper.getElementsByTagName('*');

    for (var i = -1, l = nodes.length; ++i < l; ) {
        if (typeof nodes[i] != 'undefined') continue;

        if (
            nodes[i].hasAttribute('fill') &&
            nodes[i].getAttribute('fill') == 'none' &&
            (!nodes[i].hasAttribute('stroke') ||
                (nodes[i].hasAttribute('stroke') && nodes[i].getAttribute('stroke') == 'none'))
        ) {
            nodes[i].parentNode.removeChild(nodes[i]);
        }
    }

    return wrapper.innerHTML;
};

Icon.equal_colors = function(array_to_check) {
    if (!Array.isArray(array_to_check) || array_to_check.length == 1 || array_to_check.length == 0) {
        return null;
    }
    for (var i = 0; i < array_to_check.length; i++) {
        if (i > 0 && array_to_check[i] != array_to_check[i - 1]) {
            return array_to_check;
        }
    }
    return null;
};

Icon.checkSvgColor = function(content) {
    var expreg = /#(?:[a-f\d]{3}){1,2}\b|rgb\((?:(?:\s*0*(?:25[0-5]|2[0-4]\d|1?\d?\d)\s*,){2}\s*0*(?:25[0-5]|2[0-4]\d|1?\d?\d)|\s*0*(?:100(?:\.0+)?|\d?\d(?:\.\d+)?)%(?:\s*,\s*0*(?:100(?:\.0+)?|\d?\d(?:\.\d+)?)%){2})\s*\)|hsl\(\s*0*(?:360|3[0-5]\d|[12]?\d?\d)\s*(?:,\s*0*(?:100(?:\.0+)?|\d?\d(?:\.\d+)?)%\s*){2}\)|(?:rgba\((?:(?:\s*0*(?:25[0-5]|2[0-4]\d|1?\d?\d)\s*,){3}|(?:\s*0*(?:100(?:\.0+)?|\d?\d(?:\.\d+)?)%\s*,){3})|hsla\(\s*0*(?:360|3[0-5]\d|[12]?\d?\d)\s*(?:,\s*0*(?:100(?:\.0+)?|\d?\d(?:\.\d+)?)%\s*){2},)\s*0*(?:1|0(?:\.\d+)?)\s*\)/gi;
    var colores = content.match(expreg);
    colores = Icon.equal_colors(colores);
    return colores != null ? 1 : 0;
};

Icon.checkXmlFormat = function(content) {
    var oParser = new DOMParser();
    var result;

    try {
        var oDOM = oParser.parseFromString(content, 'text/xml');
    } catch (exc) {
        return false;
    }

    if (/script/i.test(content)) {
        result = 'ERROR while parsing';
    } else {
        // print the name of the root element or error message
        result = oDOM.documentElement.nodeName == 'parsererror' ? 'error while parsing' : oDOM.documentElement.nodeName;
    }

    return result == 'svg';
};

Icon.checkUploadSvg = function(f) {
    var reader = new FileReader();

    var filename = sanitize_filename(f.name);
    //f.name = filename;
    var file = new File([f], filename, { type: f.type });

    if (f.size > 150000) {
        ga('send', 'event', 'Collection', 'Upload Icon', 'Error: Exceeded size.');
        Common.UserAlerts.danger('ERROR', messages.limit.max_size + ' (Max. 150Kb.)', false);
        return false;
    }

    reader.filename = filename;
    reader.filetype = f.type;
    reader.file = file;

    reader.onloadend = function(e) {
        var msg;
        var checked = false;
        var file_type = '';
        var isColor = 0; //no tiene color

        var DOMURL = window.webkitURL || window.URL || window;

        //Check XML Format
        if (e.target.filetype == 'image/svg+xml') {
            if (!Icon.checkXmlFormat(e.target.result)) {
                ga('send', 'event', 'Collection', 'Upload Icon', 'Error: Malformed SVG');
                Common.UserAlerts.danger('ERROR', messages.collection.svg_incorrect, false);
                return false;
            }

            isColor = Icon.checkSvgColor(e.target.result);
            file_type = e.target.filetype;
            var collection = Collection.getActive();
            var sort = window.db.query(collection.bookmark_id).length + 1;

            Collection.upload_icon(this.file, isColor, sort);
        } else {
            ga('send', 'event', 'Collection', 'Upload Icon', 'Error: File not SVG');
            Common.UserAlerts.danger('ERROR', messages.collection.only_svg, false);
            return;
        }
    };

    // reader.onerror = function(e) {
    //
    // };

    reader.readAsText(f);

    delete window.reader;
};

Icon.moveDragStart = function(event) {
    var dataTransfer = event.originalEvent.dataTransfer;
    dataTransfer.effectAllowed = 'move';
    dataTransfer.dropEffect = 'move';
    Widget.dragged_icon = event.target.nodeName == 'LI' ? event.target : event.target.closest('li.icon--item');
    Widget.dragged_icon.classList.add('dragHover');
    Widget.dragged_position = parseInt(Widget.dragged_icon.dataset.sort, 10);
};

Icon.moveDragEnter = function(event) {
    var icon = event.target.nodeName == 'LI' ? event.target : event.target.parentNode.parentNode;
    if (icon.dataset.id == Widget.dragged_icon.dataset.id) return false;

    var place;
    if (Widget.dragged_icon.dataset.sort == icon.dataset.sort + 1) {
        place = event.currentTarget;
    } else {
        place =
            Widget.dragged_icon.dataset.sort <= icon.dataset.sort
                ? event.currentTarget
                : event.currentTarget.nextElementSibling;
    }

    Widget.moveIcon(Widget.dragged_icon, place);
    Widget.scroll(event.clientX, event.clientY);
};

Icon.moveDragLeave = function(event) {};

Icon.moveDragEnd = function(event) {
    event.preventDefault();
    event.stopPropagation();
    Icon.moveDrop(event);
};

Icon.moveDrop = function(event) {
    event.preventDefault();

    var collection = Collection.getActive();
    var collection_section = document.querySelector('.collection[data-bookmark="' + collection.bookmark_id + '"]');
    Widget.reorderIcons(collection_section, false);

    if (Widget.dragged_icon.classList.contains('dragHover')) {
        Widget.dragged_icon.classList.remove('dragHover');

        var icon_id = parseInt(Widget.dragged_icon.dataset.id, 10);

        var new_order = parseInt(collection_section.querySelector('li[data-id="' + icon_id + '"]').dataset.sort, 10);

        Icon.updateReorder(collection.bookmark_id, icon_id, Widget.dragged_position, new_order);
    }
};

Icon.updateReorder = function(bookmark_id, icon_id, order, new_order) {
    Collection.emit('reorder_item', {
        bookmark_id: bookmark_id,
        icon_id: icon_id,
        old_order: order,
        new_order: new_order,
    });
};

/**
 *
 * ID           (Int) [protected]
 * bookmark_id  (String/Int)
 * name         (String)
 * color        (String)
 * created      (Int)
 * updated      (Int)
 * active       (Int)
 * order        (Int)
 * source       (String)
 */

export function Collection(data, loading, duplicate, setActive = false) {
    var properties = ['bookmark_id', 'name', 'color', 'created', 'updated', 'active', 'order', 'source', 'status'];
    var ts = Common.getTimestamp();
    var restoring = false;

    /**
     * Definimos propiedades de la colección según parámetros
     */

    this.bookmark_id = data.bookmark_id || (Math.random().toString(36) + '00000000000000000').slice(2, 16 + 2);
    this.name = data.name || messages.collection.default_name;
    this.status = data.status != 0 ? 1 : 0;
    this.color = data.color || '000000';
    this.created = data.created;
    this.updated = data.updated;

    this.source = data.source || 'local';

    this.icons = data.icons || [];

    /**
     * Actualizamos el resto de colecciones para que dejen de estar como colecciones activas
     * e incrementamos su ordenación
     */
    this.active = parseInt(data.active, 10) || 1;
    this.order = parseInt(data.order, 10) || 0;

    window.db.update(
        'list',
        function() {
            return true;
        },
        function(collection) {
            collection.active = 0;
            collection.order++;
            return collection;
        },
    );
    window.db.commit();

    var self = this;
    data = { duplicate: false };

    properties.forEach(function(k) {
        if (typeof self[k] != 'undefined') {
            data[k] = self[k];
        }
    });

    var list = window.db.queryAll('list');
    var current = list.filter(function(collection) {
        return collection.bookmark_id == data.bookmark_id;
    });

    if (current.length) {
        data.active = current[0].active;
        data.order = current[0].order;
    }

    window.db.insertOrUpdate('list', { bookmark_id: this.bookmark_id }, data);

    if (window.db.tableExists(this.bookmark_id)) {
        window.db.dropTable(this.bookmark_id);
    }

    if (this.icons.length) {
        this.icons.forEach(function(icon) {
            icon.bookmark_id = self.bookmark_id;
        });
        window.db.createTableWithData(this.bookmark_id, this.icons);
    } else {
        window.db.createTable(this.bookmark_id, Icon.properties);
    }

    window.db.commit();

    if (duplicate) {
        data.duplicate = true;
        data.icons = this.icons;
    }

    if (!loading && data.bookmark_id != 'tour_demo_collection') {
        if (setActive) {
            Collection.emit('create', data, Collection.setActive);
        } else {
            Collection.emit('create', data);
        }
    }

    if (!loading) {
        Widget.update(loading);
    }

    var collectionHolder = document.querySelector('#collections-holder');
    collectionHolder ? collectionHolder.classList.add('overflow') : '';
    collectionHolder ? collectionHolder.classList.remove('scrollable') : '';

    document.querySelector('button.flaticon-share')
        ? (document.querySelector('button.flaticon-share').disabled = !window.UserSession.userInfo.logged)
        : '';
}

Collection.fetch = function(check) {
    if (check === true) {
        var error = false;
        var btn_fetch = document.getElementById('btn-fetch-collection');

        if (!btn_fetch) return false;

        if (Array.isArray(collection_icons)) {
            if (
                collection_icons.every(function(i) {
                    return i.premium != 0;
                })
            ) {
                //document.querySelector(".fetch-collection-wrapper .fetch-special-icons").classList.remove("hidden");
                error = true;
            }

            if (Collection.limit.collections && window.db.rowCount('list') >= Collection.limit.collections) {
                //document.querySelector(".fetch-collection-wrapper .fetch-collection-limits").classList.remove("hidden");
                error = true;
            }
        }

        btn_fetch.disabled = error;
        return;
    }

    if (typeof collection_icons == 'undefined') return false;
    if (
        !Array.isArray(collection_icons) ||
        !window.UserSession.userInfo.logged ||
        (Collection.limit.collections && window.db.rowCount('list') >= Collection.limit.collections)
    ) {
        console.warn(
            'No se puede realizar fetch',
            !Array.isArray(collection_icons),
            !window.UserSession.userInfo.logged,
            Collection.limit.collections && window.db.rowCount('list') >= Collection.limit.collections,
        );
        return false;
    }

    var errors = { personal: false, premium: false };
    var data = {};

    data.color = '000000';
    data.name = document.getElementsByTagName('h1')[0].textContent;
    data.source = 'fetch';
    data.icons = collection_icons.filter(function(icon) {
        if (icon.premium == 4 && icon.author != window.UserSession.userInfo.session.id) {
            errors.personal = true;
            return false;
        }

        if (icon.premium == 1 && !window.UserSession.userInfo.session.premium) {
            errors.premium = true;
            return false;
        }
        return true;
    });

    if (errors.premium) Common.UserAlerts.warning('Ooops!', messages.collection.fetch_premium);
    if (errors.personal) Common.UserAlerts.warning('Ooops!', messages.collection.fetch_personal);

    new Collection(data, false, true);
};

Collection.init = function() {
    if (window.db) return;

    Collection.limit = {};

    var properties = {
        icons: {
            value: 10,
        },
        collections: {
            value: 1,
        },
    };

    if (window.UserSession.userInfo.logged) {
        var dropdown = $_('.dropdown-upgrade.register');
        if (document.querySelector('.collections-holder')) {
            document.querySelector('.collections-holder').classList.add('logged');

            properties = {
                icons: {
                    value: 256,
                },
                collections: {
                    value: 3,
                },
            };
        }
    } else {
        var dropdown = $_('.dropdown-upgrade.upgrade');
    }

    if (dropdown) {
        dropdown.parentNode.removeChild(dropdown);
    }

    if (window.UserSession.userInfo.session.premium) {
        properties.collections.value = 0;
    }

    Object.defineProperties(Collection.limit, properties);
    Object.freeze(Collection);

    var db_name = window.UserSession.userInfo.session.id || 0;

    window.db = new localStorageDB(db_name);
    var firstWindowDb = false;

    if (!window.db.tableExists('list')) {
        window.db.createTable('list', [
            'name',
            'bookmark_id',
            'created',
            'updated',
            'active',
            'source',
            'order',
            'color',
            'status',
        ]);
        firstWindowDb = true;
        //ga('send', 'event', 'Collection', 'Create First');
    } else {
        if (!window.db.columnExists('list', 'status')) {
            window.db.alterTable('list', 'status', 1);
        }
    }

    window.db.queryAll('list').forEach(function(collection) {
        if (!window.db.columnExists(collection.bookmark_id, 'premium')) {
            window.db.alterTable(collection.bookmark_id, 'premium', 0);
        }
        if (!window.db.columnExists(collection.bookmark_id, 'sort')) {
            window.db.alterTable(collection.bookmark_id, 'sort', 0);
        }
    });

    if (window.db.tableExists('tour_demo_collection')) {
        window.db.dropTable('tour_demo_collection');
        window.db.deleteRows('list', {
            bookmark_id: 'tour_demo_collection',
        });
    }

    window.db.commit();

    var set_id = localStorage.getItem('widget_state_user');
    let userId = window.UserSession.userInfo.session.id;
    if (set_id != userId) {
        $_('#collections-holder') !== null ? ($_('#collections-holder').innerHTML = '') : '';
    }

    if (
        document.getElementById('collections-holder') !== null &&
        document.getElementById('collections-holder').childElementCount
    ) {
        Widget.update(true);
        var updated = true;
    }

    Collection.push();

    Collection.fetch(true);
};

Collection.pull = function() {
    Collection.emit('pull');
};

Collection.openEditMode = function() {
    Collection._toggleEditMode('add');
};

Collection.closeEditMode = function() {
    Collection._toggleEditMode('remove');
};

Collection._toggleEditMode = function(method) {
    var collection = $_('.collection[data-order="0"]');
    collection.get('.collection-content').classList[method]('edit-mode');
    collection.get('.header-sort').classList[method]('hidden');
    collection.get('.header-edit').classList[method]('visible');
    collection.get('.download-wrapper').classList[method]('hidden');

    closeCollectionsMenu();
};

Collection.openSortMode = function() {
    ga('send', 'event', 'Collection', 'Sort');

    Collection._toggleSortMode('add');
    Collection.FirstIconOrder('add');
    document.querySelectorAll('li.icon-upload').forEach(function(li) {
        li.dataset.tooltip = messages.collection.upload_disabled;
    });
};

Collection.closeSortMode = function() {
    Collection._toggleSortMode('remove');
    Collection.FirstIconOrder('remove');
    document.querySelectorAll('li.icon-upload').forEach(function(li) {
        li.dataset.tooltip = messages.collection.upload_enabled;
    });
};

Collection._toggleSortMode = function(method) {
    var collection = $_('.collection[data-order="0"]');
    collection.get('.collection-content').classList[method]('sort-mode');
    collection.get('.header-sort').classList[method]('visible');

    closeCollectionsMenu();
};

Collection.toggleShareLayer = function(method) {
    var collection = $_('.collection[data-order="0"]');
    collection.get('.collection-share').classList.toggle('visible');

    closeCollectionsMenu();
};

Collection.toggleBackupLayer = function(method) {
    var collection = $_('.collection[data-order="0"]');
    collection.get('.collection-backup').classList.toggle('visible');

    closeCollectionsMenu();
};

Collection.merge = function() {
    var _db = new localStorageDB(0);

    const isPremiumUser = window.UserSession.userInfo.session.premium;

    if (!isPremiumUser) {
        _db.drop();
        return;
    }

    var library = _db.queryAll('list');

    library.forEach(function(collection) {
        collection.icons = _db.queryAll(collection.bookmark_id);
        if (collection.icons.length) {
            var ts = Common.getTimestamp();
            if (!collection.created) collection.created = ts;
            if (!collection.updated) collection.updated = ts;

            new Collection(collection, false, true, true);
        }
    });

    _db.drop();
};

Collection.set_id = function(params) {
    window.db.renameTable(params.old_id, params.bookmark_id);
    window.db.update(
        'list',
        {
            bookmark_id: params.old_id,
        },
        function(collection) {
            collection.bookmark_id = params.bookmark_id;
            collection.source = 'local';
            return collection;
        },
    );
    window.db.commit();

    var stored_icons = window.db.rowCount(params.bookmark_id);

    if (stored_icons) {
        window.db.update(
            params.bookmark_id,
            function(i) {
                return true;
            },
            function(icon) {
                icon.bookmark_id = params.bookmark_id;
                return icon;
            },
        );
        window.db.commit();
    }

    Widget.limitsCollection(params.bookmark_id);

    //Widget.setLimits();
    var collectionElem = $_(".collection[data-bookmark='" + params.old_id + "']");
    if (collectionElem) {
        collectionElem.dataset.bookmark = params.bookmark_id;
    }

    var deleteButton = collectionElem.querySelector('.header-options button.flaticon-bin');
    if (deleteButton) {
        deleteButton.dataset.bookmark = params.bookmark_id;
    }
    var shareInput = collectionElem.querySelector('.collection-share input');
    if (shareInput) {
        var value = shareInput.value.split('/');
        value.pop();
        value.push(params.bookmark_id);
        shareInput.value = value.join('/');
    }

    var boxElem = $_(".box[data-bookmark='" + params.old_id + "']");
    if (boxElem) {
        boxElem.dataset.bookmark = params.bookmark_id;
    }
    //Collection.push(params.bookmark_id);

    var collectionAnonymousElem = $_(".max-collections__item[data-bookmark='" + params.old_id + "']");
    if (collectionAnonymousElem) {
        collectionAnonymousElem.setAttribute('data-bookmark', params.bookmark_id);
    }
};

function closeCollectionsMenu() {
    var popoverButton = document.querySelector('.popover-button.active');
    var popoverContent = document.querySelector('.popover-content.show-menu');

    if (popoverButton) {
        popoverButton.classList.remove('active');
    }

    if (popoverContent) {
        popoverContent.classList.remove('show-menu');
    }
}

export function callback_handler(data) {
    if (data.namespace && data.namespace != 'Auth.error') {
        var q = data.namespace.split('.');
        if (typeof window[q[0]][q[1]] === 'function') {
            delete data.namespace;
            window[q[0]][q[1]](data);
        }
    }
    window.loading = false;
}

var secureImgPath;

Collection.emit = function(event, data, callback = null) {
    data = data instanceof Object ? data : {};

    if (data.bookmark_id == 'tour_demo_collection') return;

    if (event === 'put_icon') {
        var url = BASE_URL + 'collections/' + event;
        var kfc = new Date().getTime();

        data.o = ACCOUNTS_API_KEY;
        data.kfc = kfc;

        if (typeof url === 'undefined') return gr.log('[ERROR] REQUEST URL no definida');

        if (url.substr(0, 1) != '/' && url.substr(0, 4) != 'http') url = ACCOUNTS_URL + 'request/' + url;

        url += '?o=' + ACCOUNTS_API_KEY;
        url += '&kfc=' + kfc;
        data = JSON.stringify(data);

        getXHR(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        }).then(response => {
            secureImgPath = response.data;
            if (callback) {
                callback();
            }
        });
    } else if (event === 'push') {
        var url = BASE_URL + 'collections/' + event;
        var kfc = new Date().getTime();

        data.o = ACCOUNTS_API_KEY;
        data.kfc = kfc;

        if (typeof url === 'undefined') return gr.log('[ERROR] REQUEST URL no definida');

        if (url.substr(0, 1) != '/' && url.substr(0, 4) != 'http') url = ACCOUNTS_URL + 'request/' + url;

        url += '?o=' + ACCOUNTS_API_KEY;
        url += '&kfc=' + kfc;
        data = JSON.stringify(data);
        getXHR(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        }).then(response => {
            if (callback) {
                callback(response.data.bookmarks);
            }

            callback_handler(response.data);

            Widget.update();
        });
    } else {
        var url = BASE_URL + 'collections/' + event;
        var kfc = new Date().getTime();

        data.o = ACCOUNTS_API_KEY;
        data.kfc = kfc;

        if (typeof url === 'undefined') return gr.log('[ERROR] REQUEST URL no definida');

        if (url.substr(0, 1) != '/' && url.substr(0, 4) != 'http') url = ACCOUNTS_URL + 'request/' + url;

        url += '?o=' + ACCOUNTS_API_KEY;
        url += '&kfc=' + kfc;
        data = JSON.stringify(data);

        getXHR(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        }).then(response => {
            response.data ? callback_handler(response.data) : '';
            Widget.update();
        });
        if (callback && event == 'create') {
            callback(JSON.parse(data).bookmark_id);
        }
    }
};

Collection.limitReached = function() {
    var collectionLimitBox = $_('.max-collections__holder'),
        tpl = Handlebars.tag('collection-limit-box'),
        library = window.db.queryAll('list', { query: { status: 1 } }),
        counter = Collection.limit.collections ? library.length - Collection.limit.collections : 0;

    if (counter < 1) return;

    $_('#max-collections').classList.add('visible');
    collectionLimitBox.innerHTML = '';
    library.forEach(function(collection) {
        collection.icons = window.db.queryAll(collection.bookmark_id, {
            limit: 10,
            sort: [['created', 'DESC']],
        });
        collection.count = window.db.rowCount(collection.bookmark_id);
        collectionLimitBox.insertAdjacentHTML('beforeend', tpl(collection));
    });
    document.querySelector('.max-collections__second-step p strong').innerHTML =
        '<strong>' + library.length + '</strong>';
};

Collection.set = function(bookmark_id, new_values) {
    var collectionElem = $_(".collection[data-bookmark='" + bookmark_id + "']");

    var force = new_values.force;
    var old_color = new_values.old_color;

    for (var i in new_values) {
        if (['name', 'bookmark_id', 'source', 'color'].indexOf(i) == -1) delete new_values[i];
    }

    var keys = Object.keys(new_values);

    if (!keys.length) return;

    new_values.updated = Common.getTimestamp();

    window.db.update(
        'list',
        {
            bookmark_id: bookmark_id,
        },
        function(collection) {
            if ('name' in new_values) {
                collectionElem.get('.collection-name').innerHTML = new_values.name;
            }

            if ('bookmark_id' in new_values) {
                collectionElem.dataset.bookmark = new_values.bookmark_id;
            }

            var data = {
                bookmark_id: bookmark_id,
                force: force,
                old_color: old_color,
            };

            for (var i in new_values) {
                collection[i] = new_values[i];
                data[i] = new_values[i];
            }

            Collection.emit('set', data);

            return collection;
        },
    );
    window.db.commit();
};

Collection.editName = function() {
    var inputTxt = $_(".collections [data-order='0'] .collection-header .edit-name");
    inputTxt.classList.add('active');
    inputTxt.get('input').select();
};

Collection.list = function() {
    var collectionHolder = $_('#collections-holder');

    [].forEach.call(document.find('#collections .collection'), function(item) {
        if (item.getAttribute('data-order') == 0 && item.classList.contains('expanded')) {
            item.classList.remove('expanded');
            ga('send', 'event', 'Collection', 'List', 'Hide');
        } else {
            if (item.getAttribute('data-order') == 0) item.classList.add('expanded');
            ga('send', 'event', 'Collection', 'List', 'Show');
        }
    });

    collectionHolder.classList.toggle('overflow');
    collectionHolder.classList.toggle('scrollable');

    if (collectionHolder.matches('.scrollable')) {
        for (const item of collectionHolder.children) {
            if (item.matches('.locked')) {
                const lockedWarning = document.createElement('div');
                lockedWarning.innerHTML = `
                    <div class="collection-locked">
                        <div class="collection-locked-box">
                            <i class="icon icon--exclamation"></i>
                        </div>
                        <div class="collection-locked-box">
                            <p class="collection-locked-box--title mg-none">${messages.collection.locked_title}</p>
                            <p class="collection-locked-box--text mg-none">${messages.collection.locked_text}</p>
                            <a href="${messages.collection.locked_url}" class="bj-button bj-button--sm bj-button--yellow track" data-track-arguments="all, event, collection, go-to-premium, collection-locked">${messages.collection.locked_button}</a>
                        </div>
                    </div>
                `;
                lockedWarning.classList.add('collection-locked-outside');
                collectionHolder.insertAdjacentElement('afterbegin', lockedWarning);

                break;
            }
        }
    }

    if (collectionHolder.matches('.overflow') && document.querySelector('.collection-locked-outside')) {
        document.querySelector('.collection-locked-outside').remove();
    }
};

Collection.expandCollapse = function() {
    var collections = $_('#collections');
    var viewport = $_('#viewport');

    collections.classList.toggle('expanded');
    viewport.classList.toggle('collections-expanded');
};

Collection.setActive = function(bookmark_id, lock_collection_holder) {
    if (!bookmark_id) {
        bookmark_id = Collection.getActive().bookmark_id;
    }

    var list = window.db.queryAll('list', {
        sort: [['order', 'ASC']],
    });
    var order = [bookmark_id];

    if (!lock_collection_holder) {
        var holder = $_('#collections-holder');
        if (holder) {
            holder.scrollTop = 0;
            holder.classList.add('overflow');
            holder.classList.remove('scrollable');
        }

        if (holder && holder.matches('.overflow') && document.querySelector('.collection-locked-outside')) {
            document.querySelector('.collection-locked-outside').remove();
        }
    }

    var collectionHeight = list.length > 1 ? (list.length > 2 ? 'collection-height-2' : 'collection-height-1') : '';

    list.forEach(function(collection) {
        if (collection.bookmark_id != bookmark_id) order.push(collection.bookmark_id);

        var elem = $_('.collection[data-bookmark="' + collection.bookmark_id + '"]');

        if (elem) {
            if (elem.length > 1) {
                for (var n = 1, l = elem.length; n < l.length; n++) {
                    elem[n].parentNode.removeChild(n);
                }
            }

            collection.order = order.indexOf(collection.bookmark_id);
            elem.dataset.order = collection.order; //IE

            if (collection.bookmark_id == bookmark_id) {
                collection.active = 1;
                elem.classList.remove('collection-height-1', 'collection-height-2');
                if (!lock_collection_holder) {
                    elem.classList.add('expanded');
                }
                if (collectionHeight.length) {
                    elem.classList.add(collectionHeight);
                }
            }

            window.db.update(
                'list',
                {
                    bookmark_id: collection.bookmark_id,
                },
                function(row) {
                    row.order = collection.order;
                    row.active = collection.active;
                    return row;
                },
            );
            window.db.commit();
        }
    });

    Widget.limitsCollection(bookmark_id);

    Widget.update(null, lock_collection_holder);
};

Widget.limitsCollection = function(bookmark_id) {
    if (!bookmark_id) return false;

    var toggle = $_('#collections-toggle');
    var count = window.db.rowCount(bookmark_id);
    var elem = $_(".collection[data-bookmark='" + bookmark_id + "']");
    if (elem) {
        var max_icons = elem.querySelector('.max-icons-collection');

        if (count >= Collection.limit.icons) {
            if (window.UserSession.userInfo.session.id) {
                if (max_icons) {
                    max_icons.style.display = 'block';

                    Common.UserAlerts.danger('Ooop!', messages.limit.collection);
                }
            } else {
                Common.UserAlerts.danger('Ooop!', messages.limit.guest);
            }
            toggle.classList.add('limit-reached');
            elem.classList.add('limit-reached');
        } else {
            if (max_icons) {
                max_icons.style.display = 'none';
            }
            var collections_no_register = elem.querySelector('.icons-collection-noregister');

            if (collections_no_register) {
                elem.classList.remove('nologged-limit-reached');
                elem.querySelector('.collection-content').classList.remove('nologged-limit-reached');
                collections_no_register.style.display = 'none';
            }
            toggle.classList.remove('limit-reached');
            elem.classList.remove('limit-reached');
        }
    }
};

Collection.getActive = function() {
    if (!window.db) return;

    if (!window.db.rowCount('list')) throw 'No collections stored';

    var collection = window.db.queryAll('list', {
        query: {
            active: 1,
        },
    });

    if (collection.length) collection = collection[0];
    else collection = window.db.queryAll('list')[0];

    return collection;
};

Collection.clear = function(bookmark_id) {
    window.db.truncate(bookmark_id);

    Widget.clear();
    Collection.emit('clear', {
        bookmark_id: bookmark_id,
    });

    var element = document.get(".collection[data-order='0'] .collection-header .edit-name input");
    if (element) {
        element.value = messages.collection.default_name;
        element.classList.remove('limit-reached');
        Collection.rename();
    }

    Widget.updateToggle();

    Widget.markSelectedIcons();

    ga('send', 'event', 'Collection', 'Clear');
};

Collection.duplicate = event => {
    ga('send', 'event', 'Collection', 'Duplicate');

    const originalBookmarlId = event.target.closest('section[data-bookmark]').dataset.bookmark;
    const original = Collection.getCollectionData(originalBookmarlId);

    var duplicate = {};
    var ts = Common.getTimestamp();
    var duplicate_files = false;

    duplicate.name = original.name + ' 2';
    duplicate.status = 1;
    duplicate.color = original.color;
    duplicate.created = ts;
    duplicate.updated = ts;
    duplicate.source = 'local';
    duplicate.icons = window.db.queryAll(original.bookmark_id);

    if (duplicate.icons.length) {
        duplicate.icons.forEach(function(icon) {
            if (icon.premium === 4) {
                duplicate_files = true;
            }
        });
    }

    if (duplicate_files) {
        Collection.duplicate_files(duplicate);
    } else {
        new Collection(duplicate, false, true);
    }
};

Collection.duplicate_files = function(collection) {
    var data = new FormData();
    data.append('collection', JSON.stringify(collection));
    $.ajax({
        url: APP_URL + 'Collections/duplicate',
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        success: function(response) {
            new Collection(response.data.data, false, true, true);
        },
    });
};

Collection.del = function(id, prevent) {
    if (id instanceof Object) {
        (prevent = true), (id = id.id);
    }

    if (!id) {
        id = $_(".collection[data-order='0']").dataset.bookmark;
    }

    var collection = window.db.queryAll('list', {
        query: {
            bookmark_id: id,
        },
    });

    if (!collection.length) {
        if (window.db.tableExists(id)) {
            window.db.dropTable(id);
        }
        window.db.commit();
        return false;
    }

    collection = collection[0];

    if (window.db.rowCount('list') == 1 && collection.bookmark_id != 'tour_demo_collection') {
        Collection.clear(collection.bookmark_id);
        return;
    }

    window.db.deleteRows('list', {
        bookmark_id: collection.bookmark_id,
    });

    window.db.commit();
    if (window.db.tableExists(collection.bookmark_id)) {
        window.db.dropTable(collection.bookmark_id);
    }

    window.db.update(
        'list',
        function(col) {
            return col.order > collection.order;
        },
        function(col) {
            col.order = parseInt(col.order, 10) - 1;
        },
    );
    window.db.commit();

    var col_elem = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");

    if (!col_elem) {
        return false;
    }

    col_elem.parentElement.removeChild(col_elem);

    let disabledCollectionBtn =
        Collection.limit.collections && window.db.rowCount('list') >= Collection.limit.collections;
    const addCollectionBtn = document.querySelector('#new_collection_btn');
    addCollectionBtn.disabled = disabledCollectionBtn;

    // Si hemos borrado la ultima coleccion, creamos una vacia
    if (!window.db.rowCount('list')) {
        Collection.new();
    }

    if (!prevent) {
        Collection.emit('del', {
            bookmark_id: collection.bookmark_id,
        });
        ga('send', 'event', 'Collection', 'Delete');
    }

    Collection.setActive(null, window.db.rowCount('list') > 1);
};

Collection.new = function(options) {
    if (Collection.limit.collections && window.db.rowCount('list') >= Collection.limit.collections) {
        return;
    }

    if (typeof options == 'undefined' || !(options instanceof Object)) options = {};

    options.name =
        typeof options.name == 'undefined' || !options.name.length ? messages.collection.default_name : options.name;

    new Collection(options);

    if (Collection.getActive()) {
        var active_collection = document.querySelector(
            'section.collection[data-bookmark="' + Collection.getActive().bookmark_id + '"]',
        );
        active_collection.querySelector('div.edit-name').classList.add('active');
        active_collection.querySelector('div.edit-name input.collection-name-input').select();
    }
};

Collection.push = function(id) {
    var query =
        id && typeof id == 'number'
            ? {
                  query: {
                      bookmark_id: id,
                  },
              }
            : {
                  sort: [['order', 'DESC']],
              };

    var collections = window.db.queryAll('list', query);

    collections.forEach(function(collection) {
        ['ID'].forEach(function(index) {
            delete collection[index];
        });

        collection.icons = window.db.queryAll(collection.bookmark_id, {
            sort: [['sort', 'DESC']],
        });
    });

    var updateImgDataFunction = function updateImgData(data) {
        let bookmarkActive = null;
        if (typeof data != 'undefined') {
            if (window.db.showTables().length > 0) {
                window.db.showTables().forEach(element => {
                    window.db.dropTable(element);
                });
                window.db.createTable('list', [
                    'name',
                    'bookmark_id',
                    'created',
                    'updated',
                    'active',
                    'source',
                    'order',
                    'color',
                    'status',
                ]);
                window.db.commit();
            }

            data.forEach(function(collec) {
                let collecArray = collec.data.icons ? collec.data.icons : null;

                if (collecArray && collecArray.length > 0) {
                    if (!window.db.tableExists(collec.data.bookmark_id)) {
                        window.db.createTableWithData(collec.data.bookmark_id, collecArray);
                        // if (bookmarkActive === null && window.db.rowCount('list')) {
                        //     bookmarkActive = Collection.getActive().bookmark_id;
                        // }
                        window.db.insertOrUpdate('list', { bookmark_id: collec.data.bookmark_id }, collec.data);
                        window.db.commit();
                    }
                } else {
                    if (!window.db.tableExists(collec.data.bookmark_id)) {
                        if (bookmarkActive === null && window.db.rowCount('list')) {
                            bookmarkActive = Collection.getActive().bookmark_id;
                        }
                        window.db.createTable(collec.data.bookmark_id, Icon.properties);
                    }

                    window.db.insertOrUpdate('list', { bookmark_id: collec.data.bookmark_id }, collec.data);

                    if (!window.db.columnExists(collec.data.bookmark_id, 'imageUrl')) {
                        window.db.alterTable(collec.data.bookmark_id, 'imageUrl', '');
                    }
                    window.db.commit();
                }
            });
        }

        if (bookmarkActive) {
            Collection.setActive(bookmarkActive);
        }
    };

    Collection.emit(
        'push',
        {
            data: collections,
        },
        updateImgDataFunction,
    );
};

Collection.enqueue = function(queue) {
    var saveState = false;
    var keys = Object.keys(queue);
    if (keys.length > 1) {
        saveState = true;
        var i = 0;
        for (var ev in queue) {
            ++i;
            if (typeof window[queue[ev].namespace][queue[ev].callback] == 'function') {
                if (queue[ev].data) {
                    try {
                        queue[ev].data.prevent = i != keys.length;
                    } catch (e) {
                        queue[ev].prevent = i != keys.length;
                    }
                }
                window[queue[ev].namespace][queue[ev].callback](queue[ev].data);
            }
        }
    }

    const existGuestCollection = localStorage.getItem('fi_0');
    if (existGuestCollection) {
        saveState = true;
        Collection.merge();
    }
};

Collection.load = function(collections) {
    if (!Array.isArray(collections) && typeof collections == 'object') {
        var collections = Object.keys(collections).map(function(k) {
            return collections[k];
        });
    }

    collections.forEach(function(collection) {
        if ($_(".collection[data-bookmark='" + collection.bookmark_id + "']")) {
            return false;
        }
        collection.source = 'remote';

        if (window.db.tableExists(collection.bookmark_id)) {
            window.db.dropTable(collection.bookmark_id);
        }

        if (collection.icons.length) {
            window.db.createTableWithData(collection.bookmark_id, collection.icons);
        } else {
            window.db.createTable(collection.bookmark_id, Icon.properties);
        }

        window.db.insertOrUpdate('list', { bookmark_id: collection.bookmark_id }, collection);
    });

    window.firstColorChange = true;
};

Collection.rename = function(elem) {
    //closeCollectionsMenu();
    if (!elem) elem = $_(".collection[data-order='0'] .collection-header .edit-name input");
    var parent = elem.parent('.collection'),
        inputTxt = parent.get('.collection-header .edit-name'),
        name = inputTxt.get('input').value,
        name = name.length ? name : messages.collection.default_name;
    inputTxt.classList.remove('active');
    if (elem.dataset.original == name) return;
    parent.get('.collection-name').innerHTML = name;
    elem.dataset.original = name;

    Collection.set(parent.dataset.bookmark, {
        name: name,
    });
    ga('send', 'event', 'Collection', 'Rename');
};

Collection.getCollectionData = function(bookmark_id) {
    var collection = window.db.queryAll('list', {
        query: {
            bookmark_id: bookmark_id,
        },
    });

    if (!collection.length) {
        return;
    }

    collection = collection[0];

    collection.icons = window.db.queryAll(bookmark_id).map(function(i) {
        return {
            id: i.id,
            team: i.author,
            name: i.name,
            color: '#' + i.color,
            premium: i.premium,
            sort: i.sort,
        };
    });

    if (!collection.icons.length) {
        return;
    }

    return collection;
};

Collection.download = function(bookmark_id, format, size) {
    var collection_section = document.querySelector('.collection[data-bookmark="' + bookmark_id + '"]');
    Widget.reorderIcons(collection_section, false);

    var collection = Collection.getCollectionData(bookmark_id);

    if (format == 'iconfont') {
        if (window.db.queryAll(bookmark_id, { query: { color: 'XXXXXX' } }).length) {
            Common.UserAlerts.warning(messages.collection.aware, messages.collection.iconfont_colors);
        }
    }

    if (window.db.queryAll(bookmark_id, { query: { premium: 4 } }).length && format == 'iconfont') {
        Common.UserAlerts.warning(messages.collection.aware, messages.collection.download_personal);
    }

    size = size || 512;

    let downloadCollection = `${FLATICON_DOWNLOAD_URL}download-collection`;
    $('[name="download-collection-form"].active-form').attr('action', downloadCollection);

    document.querySelector('.active-form [name="downC_format"]').value = format || 'font';
    document.querySelector('.active-form [name="downC_size"]').value = size || 512;
    document.querySelector('.active-form [name="downC_user_id"]').value = window.UserSession.userInfo.session.id || 0;
    document.querySelector('.active-form [name="downC_bookmark_id"]').value = collection.bookmark_id;
    document.querySelector('.active-form [name="downC_bookmark_name"]').value = collection.name;
    document.querySelector('.active-form [name="downC_icons"]').value = JSON.stringify(collection.icons);
    document.querySelector('.active-form [name="downC_backup"]').value = JSON.stringify(collection);

    $_('.modal-collection .collection_name').innerHTML = collection.name;

    User.updateUserStats(3000);

    Widget.downloading();

    if (window.UserSession.userInfo.session.premium) {
        if (ww.Essentials && ww.Essentials.prechurnInfo.unpaid) {
            document.querySelector('#collections').classList.remove('loading');
            ww.Essentials.handleDownloadClick();
        } else {
            $('[name="download-collection-form"].active-form').trigger('submit');
        }
    } else {
        FIdownload.check_modal_download('collection', null);
    }

    EventTracker.send('all', 'event', 'download', 'collection', format.toUpperCase(), {
        metric2: collection.icons.length,
    });
};

Collection.downloadBackup = function(e) {
    e.preventDefault();

    var collection = Collection.getCollectionData(Collection.getActive().bookmark_id);

    var col = {
        ID: collection.ID,
        active: collection.active,
        bookmark_id: collection.bookmark_id,
        color: collection.color,
        created: collection.created,
        name: collection.name,
        order: collection.order,
        source: collection.source,
        status: collection.status,
        updated: collection.updated,
    };

    var colId = collection.ID;
    var colBookmarkId = collection.bookmark_id;

    var icons = collection.icons;

    const isCollectionWithCustomIcons = icons.filter(icon => icon.id > 999999999).length > 0;
    if (isCollectionWithCustomIcons) {
        icons = icons.filter(icon => icon.id < 999999999);
        Common.UserAlerts.warning(messages.collection.friendly_reminder, messages.collection.no_custom_icons_in_backup);
    }

    var backup = {
        colId: col,
        colBookmarkId: icons,
    };

    var content = encodeURIComponent(btoa(JSON.stringify(backup)));
    var file_name = collection.name.split(' ').join('_') + '_backup.txt';

    if (window.navigator.msSaveOrOpenBlob && window.Blob) {
        var blob = new Blob([content], { type: 'text/plain' });
        navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
        var element = document.createElement('a');
        element.setAttribute('download', file_name);
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + content);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        document.querySelector('div.collection-backup.visible').classList.remove('visible');
    }
};

Collection.ninja = function(data) {
    Widget.delIcon(data.icon);
    Common.UserAlerts.danger('Ninja!', data.message);
};

Collection.share = function(type, url) {
    share.popup({ network: type, url: url });
};

Collection.upload_icon = function(file, isColor, sort, id, color, name, update) {
    if (!file) return false;

    var iconId = file.id;
    if (file.id == null) {
        iconId = Date.now().toString();
    }

    var data = new FormData();
    data.append('iconCollection', file);
    data.append('isColor', isColor);
    data.append('sort', sort);
    data.append('bookmark_id', Collection.getActive().bookmark_id);
    data.append('iconId', iconId);

    data.append('update', update === true);

    if (typeof name != 'undefined') data.append('name', name);
    if (typeof color != 'undefined') data.append('color', color);
    if (typeof id != 'undefined') data.append('id', id);

    // Display the values

    $.ajax({
        //url: APP_URL + 'Collections/upload_icon',
        url: FLATICON_UPLOAD_ICONS_URL + 'Collections/upload_icon',
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        xhrFields: {
            withCredentials: true,
        },
        success: function(response) {
            ga('send', 'event', 'Collection', 'Upload Icon', 'No Error');
            callback_handler(response.data);
        },
    });
};

Collection.delete_files = function(icons) {
    if (!window.UserSession.userInfo.session.id) return false;

    var data = new FormData();
    data.append('icons', JSON.stringify(icons));

    $.ajax({
        url: APP_URL + 'Collections/delete_files',
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        success: function(response) {
            //response = JSON.parse(response);
            //callback_handler(response.data)
        },
    });
};

/**
 * Drag & drop  File
 */

Collection.dragOver = function(event) {
    event.preventDefault();
    if (Common.detectBrowser() == 'IE') return false;
    // event.dataTransfer.dropEffect = 'copy';

    if (document.querySelectorAll('.collection .sort-mode, .collections .edit-mode').length) return false;

    //event.preventDefault();
    var collection = Collection.getActive();
    document.querySelector('.collection[data-bookmark="' + collection.bookmark_id + '"] .overlay-drop').style.display =
        'block';
    return;
};

Collection.dragEnd = function(event) {
    event.preventDefault();
    if (Common.detectBrowser() == 'IE') return false;

    if (document.querySelectorAll('.collection .sort-mode, .collections .edit-mode').length) return false;

    var collection = Collection.getActive();
    document.querySelector('.collection[data-bookmark="' + collection.bookmark_id + '"] .overlay-drop').style.display =
        'none';
    return;
};

Collection.drop = function(event) {
    event.stopPropagation();
    event.preventDefault();

    if (Common.detectBrowser() == 'IE') return false;
    if (document.querySelectorAll('.collection .sort-mode, .collections .edit-mode').length) return false;

    var collection = Collection.getActive();
    document.querySelector('.collection[data-bookmark="' + collection.bookmark_id + '"] .overlay-drop').style.display =
        'none';
    document.querySelector('.overlay-drop').classList.remove('show');

    //if (!event.target.classList.contains('overlay-drop')) return false;

    if (!this.checkUploadLimits(event)) return false;

    event.dataTransfer.dropEffect = 'copy';

    var files = event.dataTransfer.files;
    if (files.length) {
        for (var i = 0; i < files.length; i++) {
            files[i].id = Date.now().toString() + i;
            Icon.checkUploadSvg(files[i]);
        }
        document.getElementById('fileCollection-' + collection.bookmark_id).value = '';
    }

    return false;
};
// END: Drag & drop  File ----------------------

Collection.checkUploadLimits = function(e) {
    var limit_personal_icons = window.UserSession.userInfo.session.premium ? 256 : 3;
    var collection = Collection.getActive();
    var n_personal = window.db.queryAll(collection.bookmark_id, { query: { premium: 4 } }).length;
    var total_files = typeof e.dataTransfer != 'undefined' ? e.dataTransfer.files.length : e.target.files.length;
    var limit_files_uploads = limit_personal_icons - (total_files + n_personal);
    var collection_div = document.querySelector('.collection[data-bookmark="' + collection.bookmark_id + '"]');

    if (!window.UserSession.userInfo.session.id) {
        collection_div.querySelector('.overlay-drop').style.display = 'block';
        collection_div.querySelector('.overlay-drop').classList.add('register');
        return false;
    }

    if (limit_files_uploads < 0) {
        collection_div.querySelector('.overlay-drop').style.display = 'block';
        collection_div.querySelector('.overlay-drop').classList.add('upgrade');
        return false;
    }

    return true;
};

Collection.updateReorder = function(bookmark_id, icons) {
    Collection.emit('reorder', { bookmark_id: bookmark_id, icons: icons });
};

Collection.FirstIconOrder = function(type) {
    var collection = Collection.getActive();
    if (type == 'add') {
        document
            .querySelector(
                '.collection[data-bookmark="' + collection.bookmark_id + '"] ul.collection_icons_list .icon-fill',
            )
            .insertAdjacentHTML('beforebegin', '<li class="collectionIcon first-icon" data-id="0" data-sort="0"></li>');
    } else {
        // var icons = document.querySelectorAll('ul.collection_icons_list .collectionIcon');
        var ico = document.querySelector(
            '.collection[data-bookmark="' + collection.bookmark_id + '"] ul.collection_icons_list .first-icon',
        );
        if (ico) ico.parentNode.removeChild(ico);
    }
};

// Collection.test = function(data) {
//
// };

Collection.restore_backup = function(backup_content) {
    Widget.restoring = true;

    var count_inserts = 0;
    var count_skiped = 0;
    var icons_backup = [];
    var comparation_array = [];
    var collection_items = window.db.query(Collection.getActive().bookmark_id);

    for (var j = collection_items.length - 1; j >= 0; j--) {
        comparation_array.push(collection_items[j].id);
    }

    for (var i in backup_content) {
        if (Array.isArray(backup_content[i])) {
            icons_backup = backup_content[i];
        }
    }
    if (window.db.rowCount(Collection.getActive().bookmark_id) >= Collection.limit.icons) {
        Common.UserAlerts.danger('Ooops!', messages.limit.collection);
    } else {
        if (window.db.rowCount(Collection.getActive().bookmark_id) + icons_backup.length > Collection.limit.icons) {
            var max_inserts = Collection.limit.icons - window.db.rowCount(Collection.getActive().bookmark_id);
            var merge_backup = true;

            loop1: for (var i = icons_backup.length - 1; i >= 0; i--) {
                if (count_inserts <= max_inserts) {
                    if (comparation_array.indexOf(icons_backup[i].id) === -1 && count_inserts < max_inserts) {
                        count_inserts++;
                    } else {
                        merge_backup = false;
                        break loop1;
                    }
                } else {
                    merge_backup = false;
                    break loop1;
                }
            }
            if (merge_backup) {
                icons_backup.forEach(function(icon) {
                    if (icon.id < 999999999) {
                        Widget.addIcon(
                            icon.id,
                            Common.slug(icon.name),
                            null,
                            icon.color.replace('#', ''),
                            icon.premium,
                            icon.color.replace('#', ''),
                        );
                    } else {
                        Common.UserAlerts.warning('Ooops!', messages.collection.fetch_personal);
                    }
                });
            } else {
                Common.UserAlerts.warning('Ooops!', messages.limit.backup_max_size);
            }
        } else {
            icons_backup.forEach(function(icon) {
                if (icon.id < 999999999) {
                    if (comparation_array.indexOf(icon.id) === -1) {
                        count_inserts++;
                        Widget.addIcon(
                            icon.id,
                            Common.slug(icon.name),
                            null,
                            icon.color.replace('#', ''),
                            icon.premium,
                            icon.color.replace('#', ''),
                        );
                    } else {
                        count_skiped++;
                    }
                } else {
                    Common.UserAlerts.warning('Ooops!', messages.collection.fetch_personal);
                }
            });
        }
        if (count_skiped != icons_backup.length) {
            var message = messages.collection.success_restore
                .replace('{0}', count_inserts)
                .replace('{1}', icons_backup.length);
            Common.UserAlerts.success('Great!', message);
        }
        if (count_skiped > 0) {
            var message = messages.collection.icons_skiped
                .replace('{0}', count_skiped)
                .replace('{1}', icons_backup.length);
            Common.UserAlerts.success('Ooops!', message);
        }
    }
    Widget.restoring = false;
    Widget.updateButtons();
    document.querySelector('div.collection-backup.visible').classList.remove('visible');
};

export function Widget() {
    // this.ordered_icons = [];
}

Widget._toggleEditPanel = function(download) {
    if (download === true) {
        //$('.edit-icon-content .right div:not(.download-settings)').css({ opacity: 0 });
        $('.edit-icon-content .right .active').removeClass('active');
        $('.edit-icon-content .right .download-settings').addClass('active');
        $('.edit-icon-content')
            .removeClass('edit-color')
            .removeClass('edit-settings')
            .addClass('download-settings');
        return;
    }

    if ($('.edit-icon-content').hasClass('download-settings')) {
        $('.edit-icon-content').removeClass('download-settings');
        $('.edit-icon-content .right .download-settings').removeClass('active');
        this.classList.add('active');
        $('.edit-icon-content .right .' + this.dataset.panel).addClass('active');
        $('.edit-icon-content').addClass(this.dataset.panel);
        return;
    }

    if (this.classList.contains('active')) {
        $('.edit-icon-content .right .download-settings').removeClass('active');
        return;
    }

    $('.edit-icon-content .right .customize').removeClass('active');
    $('.edit-icon-content')
        .toggleClass('edit-color')
        .toggleClass('edit-settings');
    $('.edit-icon-content .right header button').toggleClass('active');
    $('.edit-icon-content .right div:not(.download-settings)').toggleClass('active');
    $('.edit-icon-content .right .download-settings').removeClass('active');
};

Widget._openEditModal = function(open) {
    if (open) {
        if (document.querySelector('.only_registered .alert_no_user')) {
            document.querySelector('.only_registered .alert_no_user').classList.remove('zoomOut');
            if (window.UserSession.userInfo.logged) {
                document.querySelector('.only_registered').classList.add('hidden');
            } else {
                document.querySelector('.only_registered').classList.remove('hidden');
                document.querySelector('.only_registered .alert_no_user').style = '';
            }
        }
    } else {
        Widget.reset_widget();
        document.body.classList.remove('overflow');
        document
            .querySelectorAll('.icon--item.collectionIcon .edit input[type=radio]:checked')
            .forEach(function(input) {
                input.checked = false;
            });
        window.Editor.close();
    }
};

Widget.restoring = false;

Widget.changeIconColor = function(color, elem, preventSetting, preventEmiting, isLight, fromCollectionSet) {
    var ico = $(elem).parents('.icon--item')[0];
    var collection = $(elem).parents('.collection')[0];
    if (ico) {
        [].forEach.call(ico.querySelectorAll('path, circle, ellipse, polygon, line, rect, polyline'), function(p) {
            p.removeAttribute('style');
            if (
                p.hasAttribute('fill') &&
                p.getAttribute('fill') == 'none' &&
                (!p.hasAttribute('stroke') || (p.hasAttribute('stroke') && p.getAttribute('stroke') == 'none'))
            ) {
                p.parentNode.removeChild(p);
                return true;
            }
            if (p.hasAttribute('stroke') && p.getAttribute('stroke') != 'none') {
                p.setAttribute('stroke', '#' + color);
            } else if (!p.hasAttribute('fill') || color.indexOf('#') != -1) {
                p.setAttribute('fill', '#' + color.replace('#', ''));
                //p.style.fill = '#' + color;
            } else if (
                p.hasAttribute('fill') &&
                ico.dataset.color &&
                ico.dataset.color.length == 6 &&
                ico.dataset.color != 'XXXXXX'
            ) {
                p.setAttribute('fill', '#' + color.replace('#', ''));
            }
        });

        if (isLight) {
            ico.classList.add('whitebg');
        } else {
            ico.classList.remove('whitebg');
        }

        Widget.markSelectedColor(color, ico);

        if (ico.dataset.color != color && ico.dataset.color != 'XXXXXX' && ico.dataset.color != '2') {
            ico.dataset.color = color;
            if (!preventSetting) {
                var key = ['icoChangeColor', ico.dataset.id, collection.dataset.bookmark].join('_');
                if (collection.dataset.bookmark == 'tour_demo_collection') return;
                clearTimeout(window[key]);
                window[key] = setTimeout(function() {
                    Icon.set(
                        ico.dataset.id,
                        {
                            color: color,
                        },
                        collection.dataset.bookmark,
                        preventEmiting,
                    );
                    ga('send', 'event', 'Collection', 'Change Icon Color', color, {
                        dimension11: color,
                    });
                    delete window[key];
                }, 500);
            }
        }
    }
};

Widget.isLight = function(color) {
    var r = parseInt(color.substr(0, 2), 16);
    var g = parseInt(color.substr(2, 2), 16);
    var b = parseInt(color.substr(4, 2), 16);

    return r * 299 + g * 587 + b * 114 >= 225000;
};

Widget.changeCollectionColor = function(color, elem) {
    var collection = $_(".collection[data-order='0']"),
        update = collection.dataset.color != color,
        current_color = collection.dataset.color,
        icons;

    if (!update) return;

    collection.dataset.color = color;

    Widget.markSelectedColor(color, collection); // Marca el color predeterminado del color picket

    icons = collection.querySelectorAll('.icon--item:not([data-color="XXXXXX"]) svg');

    if (icons) {
        var isLight = Widget.isLight(color);

        [].forEach.call(icons, function(svg) {
            Widget.changeIconColor(color, svg, false, true, isLight, true);
        });
    }

    if (collection.dataset.bookmark == 'tour_demo_collection') return;

    clearTimeout(window.changeCollectionColorInterval);
    window.changeCollectionColorInterval = setTimeout(function() {
        if (!window.firstColorChange) {
            Collection.set(collection.dataset.bookmark, {
                color: color,
                force: true,
                old_color: current_color,
            });
            ga('send', 'event', 'Collection', 'Change Color', color, {
                dimension11: color,
            });
        }
        if (window.firstColorChange) {
            delete window.firstColorChange;
        }
    }, 500);
};

Widget.updateButtons = function() {
    var count = window.db.queryAll('list', { query: { status: 1 } }).length;
    if (window.db.tableExists('tour_demo_collection')) {
        count--;
    }
    if (count > Collection.limit.collections) {
        Collection.limitReached();
    }
    var collection = Collection.getActive();
    var countIcons = window.db.rowCount(collection.bookmark_id);
    var elem = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");

    var options = elem.querySelector('.menu-options ul.collection-options');
    if (options) {
        if (countIcons > 0) {
            options.querySelector('.flaticon-collection_sort').removeAttribute('disabled');
            options.querySelector('.flaticon-edit_collection_icons').removeAttribute('disabled');
            // options.querySelector('.flaticon-collection_backup').removeAttribute('disabled');
            elem.querySelector('.collection-backup-download').classList.remove('disabled');
        } else {
            options.querySelector('.flaticon-collection_sort').setAttribute('disabled', 'disabled');
            options.querySelector('.flaticon-edit_collection_icons').setAttribute('disabled', 'disabled');
            elem.querySelector('.collection-backup-download').classList.add('disabled');
            // options.querySelector('.flaticon-collection_backup').setAttribute('disabled','disabled');
        }
    }

    let duplicate_collections_buttons = document.querySelectorAll('.collection-options .flaticon-duplicate');
    if (!window.UserSession.userInfo.session.premium) {
        var disable_duplicate_btn = count >= Collection.limit.collections;
        if (duplicate_collections_buttons) {
            [].forEach.call(duplicate_collections_buttons, function(duplicate_btn) {
                duplicate_btn.disabled = disable_duplicate_btn;
                /*
                if (disable_duplicate_btn)
                    duplicate_btn.parentNode.dataset.tooltip = messages.collection.duplicated_disabled;
                else
                    duplicate_btn.parentNode.dataset.tooltip = messages.collection.duplicate;
                */
            });
        }
    }
    if (!window.UserSession.userInfo.logged) {
        [].forEach.call(document.querySelectorAll('.collection .flaticon-share'), function(share_btn) {
            share_btn.parentNode.dataset.tooltip = messages.collection.share_disabled;
            share_btn.disabled = true;
        });
    }
    duplicate_collections_buttons.forEach(duplicateCollectionButton =>
        duplicateCollectionButton.addEventListener('click', Collection.duplicate),
    );

    if (count > 1) {
        $_('#list_collections_btn').removeAttribute('disabled');
    } else {
        $_('#list_collections_btn').setAttribute('disabled', 'disabled');
    }

    let disabledCollectionBtn =
        Collection.limit.collections && window.db.queryAll('list').length >= Collection.limit.collections;
    const addCollectionBtn = document.querySelector('#new_collection_btn');
    addCollectionBtn.disabled = disabledCollectionBtn;

    Collection.fetch(true);
};

Widget.updateToggle = function() {
    var toggle = $_('#collections-toggle');
    var collection = Collection.getActive();
    if (!collection) return;

    var count = window.db.rowCount(collection.bookmark_id);

    var elem = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");
    if (elem) {
        /* Widget.limitsCollection(collection.bookmark_id); */
        if (!window.UserSession.userInfo.logged) {
            if (count == 0) {
                elem.querySelector('.collection-content .collection-options').classList.add('no-icons');
            } else {
                elem.querySelector('.collection-content .collection-options').classList.remove('no-icons');
            }
        }

        elem.querySelector('.badge.badge-gray').innerHTML = count;
        var onlyColor = window.db.queryAll(collection.bookmark_id, { query: { color: 'XXXXXX' } }).length == count;
        var onlyPersonalIcons = window.db.queryAll(collection.bookmark_id, { query: { premium: 4 } }).length == count;

        if (collection.status) {
            elem.querySelector('.collection-content .download-holder button').disabled = !count;
            var holder = elem.querySelector('.collection-options .color_btn_holder');
            if (holder) {
                holder.dataset.tooltip =
                    !count || onlyColor ? messages.collection.color_disabled : messages.filters.color;
            }
            var iconfont_button = elem.querySelector(
                ".collection-download .download-files .download button[data-format='iconfont']",
            );
            iconfont_button.disabled = onlyColor;
            iconfont_button.parentNode.dataset.tooltip = onlyColor
                ? messages.collection.iconfont_disabled
                : messages.collection.iconfont_tooltip;

            var psd_button = elem.querySelector(
                ".collection-download .download-files .download button[data-format='psd']",
            );
            var eps_button = elem.querySelector(
                ".collection-download .download-files .download button[data-format='eps']",
            );
            var png_button = elem.querySelector('.collection-download .download-files .download a[data-format="png"]');
            var iconfont_button = elem.querySelector(
                '.collection-download .download-files .download button[data-format="iconfont"]',
            );

            psd_button.disabled = onlyPersonalIcons;
            // eps_button.disabled = onlyPersonalIcons;

            if (onlyPersonalIcons) {
                // png_button.classList.add('disabled');
                iconfont_button.classList.add('disabled');
            } else {
                // png_button.classList.remove('disabled');
                iconfont_button.classList.remove('disabled');
            }
        }

        if (null !== toggle) {
            var badge = toggle.querySelector('.badge');
            if (count) {
                badge.classList.remove('hidden');
                document.getElementById('collections-toggle').classList.remove('hide');
                badge.innerHTML = count;
            } else {
                if (
                    window.view.section == 'home' &&
                    document.querySelector('#collections-toggle span.badge').classList.contains('hidden') &&
                    document.querySelector('section#hero') &&
                    !document.querySelector('#header').classList.contains('fixed')
                ) {
                    document.getElementById('collections-toggle').classList.add('hide');
                } else if (
                    window.view.section == 'home' &&
                    document.querySelector('#collections-toggle span.badge').classList.contains('hidden') &&
                    !document.querySelector('section#hero')
                ) {
                    document.getElementById('collections-toggle').classList.remove('hide');
                }

                badge.innerHTML = 0;
            }
            badge.classList.add('animate');

            var badgeTimeout = setTimeout(function() {
                clearTimeout(badgeTimeout);
                badge.classList.remove('animate');

                Widget.updateIconsColorSvg();
                // Lo añado para evitar q se vea el cambio de color
                toggle.classList.remove('loading');
                $_('#collections').classList.remove('loading');
            }, 500);
        }

        var collectionGtm = document.querySelector('.empty-collection-gtm');

        if (null !== collectionGtm) collectionGtm.remove();

        if (!window.db.rowCount(collection.bookmark_id)) Widget.clear();
    }

    Widget.updateColoringCollectionButton(onlyColor, collection.bookmark_id);
};

Widget.updateColoringCollectionButton = function(disable_button, active_collection) {
    if (!document.querySelector('section[data-bookmark="' + active_collection + '"] .toggle-paint')) {
        return false;
    }
    if (disable_button) {
        document
            .querySelector('section[data-bookmark="' + active_collection + '"] .toggle-paint')
            .setAttribute('disabled', true);
    } else {
        document
            .querySelector('section[data-bookmark="' + active_collection + '"] .toggle-paint')
            .removeAttribute('disabled');
    }
};

Widget.renderIcons = function(icons, collection) {
    if (!window.loading_svg) {
        window.loading_svg = {};
        window.loading_svg[collection.dataset.bookmark] = true;
    } else {
        if (window.loading_svg.hasOwnProperty(collection.dataset.bookmark)) {
            return false;
        }
    }

    if ($_('#collections-toggle')) {
        $_('#collections-toggle').classList.add('loading');
    }

    var list = collection.get('.icons');

    if (list.childElementCount > 1) return;

    var tpl = false;

    var load = false;
    var orders = [];
    var reorder = false;

    icons.forEach(function(icon) {
        var ico = collection.get('.icons .collectionIcon[data-id="' + icon.id + '"]');

        // Check order
        if (
            icon.sort <= icons.length &&
            icon.sort != 0 &&
            icon.sort != null &&
            orders.indexOf(parseInt(icon.sort, 10)) == -1
        )
            orders.push(parseInt(icon.sort, 10));
        else reorder = true;

        if (!ico) {
            if (!tpl) {
                tpl = Handlebars.tag('collection-icon');
                icon.bookmark_id = collection.dataset.bookmark;
            }
            load = true;
            if (icon.premium != 4) {
                icon.not_personal = true;
            }
            icon.imageUrl = icon.imageUrl ? icon.imageUrl : icon.png ? icon.png : null;

            list.querySelector('.icon-upload').insertAdjacentHTML('afterend', tpl(icon));

            let uploadIcon = document.querySelector('.icons .collectionIcon[data-id="' + icon.id + '"]');

            uploadIcon.dataset.src = icon.imageUrl;
            if (uploadIcon.querySelector('img')) {
                uploadIcon.querySelector('img').src = icon.imageUrl;
            }
        }
    });

    if (reorder) {
        Widget.reorderIcons(collection, true);
    }

    if (icons.length) {
        var li_alignment = '';
        var j = 30;
        while (--j) {
            li_alignment += '<li class="icon-fill icon--item"></li>';
        }
        list.insertAdjacentHTML('beforeend', li_alignment);
    }

    if (load) {
        [].forEach.call($_('.collectionIconImage'), function(i) {
            Widget.renderSVG(i);
        });
    }

    var icons_length = icons.length;

    if (collection.status) {
        collection.get('button.collection_color_button').disabled = !icons_length;
        collection.get('button.flaticon-edit_collection_icons').disabled = !icons_length;
        collection.get('.badge.badge-gray').innerHTML = icons_length;

        collection.classList.toggle('limit-reached', icons_length >= Collection.limit.icons);
    }
    Widget.markSelectedColor(collection.color, collection);
};

Widget.renderCollection = function(data) {
    var count = window.db.rowCount('list'),
        collection = $_(".collection[data-bookmark='" + data.bookmark_id + "']");

    if (!collection) {
        var tpl = Handlebars.tag('collection'),
            elem = document.getElementById('collections-holder');
        elem.insertAdjacentHTML('beforeend', tpl(data));
        collection = $_(".collection[data-bookmark='" + data.bookmark_id + "']");
    }

    if (!data.order) {
        var collectionHeight = count > 1 ? (count.length > 2 ? 'collection-height-2' : 'collection-height-1') : '';
        if (collectionHeight.length) {
            collection.classList.add(collectionHeight);
        }
        collection.classList.add('expanded');
        if (data.icons.length) {
            Widget.renderIcons(data.icons, collection);
        }
    } else {
        collection.classList.remove('expanded', 'collection-height-2', 'collection-height-1');
    }
    collection.get('.badge.badge-gray').innerHTML = data.icons.length;
};

Widget.updateIconsColorSvg = function() {
    qq('.icon--item.collectionIcon:not([data-color="XXXXXX"])').forEach(function(icon) {
        var isLight = Widget.isLight(icon.dataset.color);
        var svg = icon.getElementsByTagName('svg');
        Widget.changeIconColor(icon.dataset.color, svg, false, true, isLight, true);
    });
};

Widget.update = function(loading, lock_collection_holder) {
    if (null !== document.querySelector('.collection-section-header .collections-number')) {
        var count = window.db.rowCount('list');

        if (!count) {
            Collection.new();
            return;
        }

        $_('.collection-section-header .collections-number').innerHTML = count;

        var cssProps = [
            '-webkit-box-ordinal-group',
            '-moz-box-ordinal-group',
            'box-ordinal-group',
            '-webkit-order',
            '-moz-order',
            'order',
            '-ms-flex-order',
            '',
        ];

        window.db
            .queryAll('list', {
                sort: [['order', 'ASC']],
            })
            .forEach(function(collection, order, list) {
                if (!loading && (collection.order != order || (order && collection.active))) {
                    window.db.update(
                        'list',
                        {
                            bookmark_id: collection.bookmark_id,
                        },
                        function(row) {
                            row.order = order;
                            row.active = !order ? 1 : 0;
                            return row;
                        },
                    );
                    window.db.commit();
                    collection.order = order;
                }

                var elem = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");
                collection.icons = window.db.queryAll(collection.bookmark_id, { sort: [['sort', 'ASC']] });

                if (!elem) {
                    window.firstColorChange = true;
                    Widget.renderCollection(collection);

                    elem = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");
                }
                elem = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");

                elem.style.cssText = cssProps.join(':' + order + ';');

                elem.dataset.order = order;

                if (order) {
                    elem.classList.remove('expanded');
                } else {
                    var collectionHeight =
                        list.length > 1
                            ? list.length > 2
                                ? 'collection-height-2'
                                : 'collection-height-1'
                            : 'collection-height';
                    if (!lock_collection_holder) {
                        elem.classList.add('expanded');
                    }
                    if (collectionHeight.length && elem.classList.contains(collectionHeight)) {
                        elem.classList.add(collectionHeight);
                    }
                    if (collection.icons.length) {
                        Widget.renderIcons(collection.icons, elem);
                    }
                    //
                    //Widget.setLimits(collection);
                    //
                }
            });

        window.launching = null;

        Widget.markSelectedIcons();
        Widget.updateToggle();
        Widget.updateButtons();
    }
};

Widget.setLimits = function(collection) {
    if (!collection) return false;

    var elem = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");

    elem.get('.max-icons-collection').style.display = 'none';
    elem.get('.icons-collection-noregister').style.display = 'none';

    if (collection.icons.length) {
        elem.querySelector('.flaticon-edit_collection_icons').removeAttribute('disabled');
    }

    if (collection.icons.length >= Collection.limit.icons) {
        if (window.UserSession.userInfo.session.id) {
            if (!window.UserSession.userInfo.session.premium) {
                elem.get('.max-icons-collection').style.display = 'block';
                Common.UserAlerts.danger('Ooop!', messages.limit.registered);
            } else {
                elem.classList.add('limit-reached');
            }
        } else {
            elem.get('.icons-collection-noregister').style.display = 'block';
            Common.UserAlerts.danger('Ooop!', messages.limit.guest);
        }
    } else {
        elem.classList.remove('limit-reached');
    }

    /*
    if (collection.icons.length >= Collection.limit.icons) {
        elem.classList.add('limit-reached');
    } else {
        elem.classList.remove('limit-reached');
    }
    */
};

Widget.markSelectedColor = function(color, elem) {
    var selector = elem.classList.contains('icon')
        ? '.color-selector button'
        : '.collection-options .color-selector button';
    var elems = elem.find(selector);
    if (elems) {
        [].forEach.call(elems, function(btn) {
            if (btn.dataset.color == color) {
                btn.classList.add('selected');
            } else {
                btn.classList.remove('selected');
            }
        });
    }
};

Widget.show = function() {
    $_('#collections').classList.add('visible');
    $_('#viewport').classList.add('collections-visible');

    localStorage.widget_active = 1;
};

Widget.toggle = function() {
    let widget = $_('#collections'),
        active = Number(widget.classList.toggle('visible')),
        method = active ? 'add' : 'remove';
    $_('#viewport').classList[method]('collections-visible');
    Functions.checkHybriDevices();
    widget.classList[method]('visible');

    if (active) {
        window.cookie.setItem('co', '1');
    } else {
        window.cookie.setItem('co', '0');
    }

    localStorage.widget_active = active;

    let event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    document.dispatchEvent(event);
    if (active) {
        callHotjarTrigger('collections');
    }
    ga('send', 'event', 'Collection', 'Toggle', active ? 'Show' : 'Hide');
};

Widget.createIconsCPicker = function(id, bookmark) {
    if (id instanceof Element) {
        var cpicker = id.parent('.collectionIcon').get('.icon-cpicker');
        var items = $(cpicker);
    } else {
        var selector =
            typeof id != 'undefined'
                ? ".collection[data-bookmark='" + bookmark + "'] .icon[data-id='" + id + "'] .icon-cpicker"
                : '.collection .icon--item .icon-cpicker';
        var items = $(selector);
    }

    items.each(function() {
        var icon = this.parent('.icon--item'),
            color = icon.dataset.color.toUpperCase();
        var picker = $(this);
        if (!picker.data().hasOwnProperty('colpickId')) {
            this.innerHTML = '';

            picker.colpick({
                flat: true,
                layout: 'hex',
                submit: 0,
                color: color,
                onChange: function(a, hex, b, elem) {
                    Widget.changeIconColor(hex.toUpperCase(), elem, false, false, Widget.isLight(hex));
                },
            });
        }
        Widget.changeIconColor(color, this, true, true, Widget.isLight(color));
    });
};

Widget.createCollectionCPicker = function() {
    var color;
    $(".collection[data-order='0'] .col-cpicker").each(function() {
        color = this.parent('.collection').dataset.color.toUpperCase();
        var picker = $(this);

        if (!picker.data().hasOwnProperty('colpickId')) {
            this.innerHTML = '';

            window.firstColorChange = true;
            var timeout = undefined;
            picker.colpick({
                flat: true,
                layout: 'hex',
                submit: 0,
                color: color,
                onChange: function(a, hex, c, elem) {
                    Widget.changeCollectionColor(hex.toUpperCase(), elem);
                },
            });
            picker.colpickSetColor(color);
            delete window.firstColorChange;
        }
    });
};

Widget.markSelectedIcons = function(bookmark_id) {
    if (!bookmark_id) {
        var active = Collection.getActive();
        if (!active) return;
        bookmark_id = active.bookmark_id;
    }

    var icons = $_('.viewport .icon--item:not(.collectionIcon):not(.icon-upload):not(.icon-fill)');

    if (icons) {
        [].slice.call(icons).forEach(function(ico) {
            ico.classList.remove('selected');
            var btn = ico.get('.overlay .add-remove');
            if (null !== btn) {
                btn.classList.add('icon--addcollection');
                btn.classList.remove('icon--removecollection');
            }
        });
    }

    window.db.queryAll(bookmark_id).forEach(function(icon) {
        Icon.toggle(icon.id);
    });
};

// FIXME Eliminar parámetro premium
Widget.addIcon = function(icon, name, author, color, premium, custom_color, icon_img) {
    if (typeof icon == 'undefined') return false;

    if (!parseInt(icon) && !parseInt(icon.id)) {
        return false;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var isSearch = urlParams.get('word') !== null || urlParams.get('term') !== null;

    if (isSearch) {
        if (icon instanceof Object) {
            Downloads.trackDownload(icon.id, null, 'add_collection');
        } else {
            Downloads.trackDownload(icon, null, 'add_collection');
        }
    }

    premium = icon.premium == 1 ? 1 : parseInt(premium, 10);

    if (premium == 1 && !window.UserSession.userInfo.session.premium) {
        let gridImg = icon_img;
        let detailImg = document.querySelector('.detail__icon__holder img');

        if (detailImg) {
            detailImg = detailImg.getAttribute('src');
        }

        LazyModal.callFunnelPremium(icon);
        let modalFunnelPremium = document.querySelector('#modal-funnel-premium-add-icon');
        let modalIconImg = new Image();
        if (modalFunnelPremium.querySelector('.row .img img')) {
            modalIconImg = modalFunnelPremium.querySelector('.row .img img');
        }
        let modalFunnelPremiumImgSpace = modalFunnelPremium.querySelector('.row .img');
        modalIconImg.src = gridImg || detailImg;
        modalFunnelPremiumImgSpace.insertAdjacentElement('beforeend', modalIconImg);

        modal.show(modalFunnelPremium.id);
        modalFunnelPremium.querySelector('.modal__container').classList.add('slide-in');
        let viewport = document.querySelector('#viewport');
        viewport.style.filter = 'none';
        let body = document.querySelector('body');

        if (!body.classList.contains('unscrollable')) {
            body.style.overflow = 'visible';
        }

        // Common.UserAlerts.warning('Ooops!', messages.collection.add_premium);
        return false;
    }
    var collection = Collection.getActive();

    if (!collection.status) {
        let lockedInside = document.querySelectorAll('.collection-locked-inside');

        for (const item of lockedInside) {
            let parent = item.closest('.collection');

            if (collection.bookmark_id == parent.dataset.bookmark) {
                item.style.display = 'block';
                break;
            }
        }

        return false;
    }

    var elem = $_(".collection[data-bookmark='" + collection.bookmark_id + "']");

    elem.classList.remove('empty');

    var premium_advise = elem.get('.collection-content .premium-advise');
    if (premium_advise) premium_advise.style.display = 'none';

    if (!window.launching && window.db.rowCount(collection.bookmark_id) >= Collection.limit.icons) {
        if (window.UserSession.userInfo.session.id) {
            elem.get('.max-icons-collection').style.display = 'block';

            if (!window.UserSession.userInfo.session.premium) {
                Common.UserAlerts.danger('Ooop!', messages.limit.registered);
            } else {
                Common.UserAlerts.danger('Ooop!', messages.limit.collection);
            }
        } else {
            elem.classList.add('nologged-limit-reached');
            elem.querySelector('.collection-content').classList.add('nologged-limit-reached');
            elem.get('.icons-collection-noregister').style.display = 'block';
            Common.UserAlerts.danger('Ooop!', messages.limit.guest);
        }

        var badge = $_('#collections-toggle .badge');
        badge.classList.add('animate');
        var badgeTimeout = setTimeout(function() {
            clearTimeout(badgeTimeout);
            badge.classList.remove('animate');
        }, 300);
        return;
    }

    var sort = 1;

    if (arguments.length > 1) {
        var collection = Collection.getActive();
        sort = parseInt(window.db.queryAll(collection.bookmark_id).length, 10) + 1;

        icon = {
            id: parseInt(icon, 10),
            name: Common.slug(name),
            color:
                color == '2'
                    ? 'XXXXXX'
                    : custom_color
                    ? custom_color
                    : collection.color != null
                    ? collection.color
                    : '000000',
            premium: premium || 0,
            sort: sort,
        };

        if ($_('section #detail .customize.active') && color == '1') {
            if (typeof localStorage.color != 'undefined') {
                icon.color = localStorage.color.replace('#', '');
            } else {
                var input = $_('section #detail #detail_picker .colpick_hex_field input');
                icon.color = input ? input.value : collection.color;
            }
        }
    }

    // solo en el detalle
    // if ($_('#detail')) {
    //     saveColor(localStorage.color);
    // }

    if (!elem.classList.contains('expanded') && !window.launching) {
        elem.classList.add('expanded');
    }

    // Mensaje de icono externo
    if (window.UserSession.userInfo.session.premium) {
        var warning_tit = messages.external_icon.title;
        var warning_txt = messages.external_icon.message;

        var located_icon = false;
        if (typeof document.getElementById('detail') != 'undefined' && document.getElementById('detail') != null) {
            if ($_('#download-form #icon_id').value == icon.id) {
                located_icon = true;
                if ($_('#download-form #selection').value == 0) Common.UserAlerts.warning(warning_tit, warning_txt, 0);
            }
        }
        var icon_element = document.querySelector('ul.icons .icon--item[data-id="' + icon.id + '"]');
        if (!located_icon && icon_element !== null) {
            if (icon_element.dataset.premium == 0 && icon_element.dataset.selection == 0) {
                if (!Widget.restoring) Common.UserAlerts.warning(warning_tit, warning_txt, 0);
            }
        }
    }

    if (
        !window.db.queryAll(collection.bookmark_id, {
            query: {
                id: icon.id,
            },
        }).length
    ) {
        var date = Common.getTimestamp();

        var author = icon.author || 0;

        new Icon(icon.id, Common.slug(icon.name), author, icon.color, collection.bookmark_id, icon.premium, icon.sort);

        Widget.updateToggle();
        Widget.updateButtons();

        Icon.toggle(icon.id);

        var icon_data = {};

        Icon.properties.forEach(function(field) {
            icon_data[field] = icon[field];
        });

        icon_data.bookmark_id = collection.bookmark_id;

        window.db.update(
            'list',
            {
                bookmark_id: collection.bookmark_id,
            },
            function(collection) {
                collection.updated = date;
                return collection;
            },
        );

        window.db.commit();

        icon_data.updated = date;
        icon_data.created = date;

        // HISTORY COLLECTION
        var icon_param = icon;
        if (typeof icon_param == 'number') icon_param = { id: icon };
        var params = {
            icon: icon_param,
            name: name,
            author: author,
            color: color,
            premium: premium,
            custom_color: custom_color,
        };

        var getUrlFunciton = function getUrl() {
            icon.imageUrl = secureImgPath.imageUrl;
            icon_data.imageUrl = secureImgPath.imageUrl;
            icon_data.color = isValidColor(icon_data.color) ? icon_data.color : 'XXXXXX';
            icon.src = secureImgPath.imageUrl;
            window.db.insertOrUpdate(
                icon_data.bookmark_id,
                {
                    id: icon_data.id,
                },
                icon_data,
            );
            window.db.commit();
            EventTracker.send('all', 'event', 'collection', 'add-icon', icon.id);

            var element_svg;
            if (icon.premium == 4 || typeof src != 'undefined') {
                var img = document.createElement('img');

                if (typeof src != 'undefined') {
                    img.id = icon.id;
                    img.dataset.premium = icon.premium;
                    img.dataset.src = icon.imageUrl;
                } else {
                    img.id = icon.icon;
                    img.dataset.premium = 4;
                    img.dataset.src = icon.imageUrl;
                }
                element_svg = img;
                $_(".collection[data-order='0'] .collection_icons_list.icons .icon-upload").insertAdjacentHTML(
                    'afterend',
                    Handlebars.tag('collection-icon')(icon),
                );
            } else {
                icon.not_personal = true;

                if (!elem.get(".icon--item.collectionIcon[data-id='" + icon.id + "']")) {
                    if (!$_(".collection[data-order='0'] .collection_icons_list.icons .icon-upload")) {
                        Collection.setActive();
                    }
                    $_(".collection[data-order='0'] .collection_icons_list.icons .icon-upload").insertAdjacentHTML(
                        'afterend',
                        Handlebars.tag('collection-icon')(icon),
                    );
                    element_svg = $_(
                        ".collection[data-order='0'] .icons .collectionIcon[data-id='" + icon.id + "'] img",
                    );
                }
            }

            if (element_svg) {
                Widget.renderSVG(element_svg);
            }
        };

        Collection.emit('put_icon', icon_data, getUrlFunciton);
    }
};

Widget.delIcon = function(id) {
    var date = Common.getTimestamp();

    var widgetIcon = $_(".collection[data-order='0'] .collectionIcon.selected[data-id='" + id + "']");

    if (widgetIcon) {
        var col_elem = widgetIcon.parent('.collection'),
            bookmark_id = col_elem.dataset.bookmark,
            icoList = widgetIcon.parentElement,
            premium = widgetIcon.dataset.premium,
            name = widgetIcon.dataset.name,
            custom_color = widgetIcon.dataset.color,
            src = widgetIcon.dataset.src;

        var color = (custom_color = 'XXXXXX') ? 2 : 1;

        icoList.removeChild(widgetIcon);

        if (!icoList.children.length) {
            icoList.innerHTML = '';
        }

        Collection.emit('del_icon', {
            bookmark_id: bookmark_id,
            id: id,
            updated: date,
            premium: premium,
        });
    }

    if (!bookmark_id || !window.db.tableExists(bookmark_id)) {
        return false;
    }

    // HISTORY COLLECTION
    var params = {
        icon: { id: id },
        id: id,
        name: name,
        author: 0,
        color: color,
        premium: premium,
        custom_color: custom_color,
        src: src,
    };

    window.db.deleteRows(bookmark_id, {
        id: id,
    });
    window.db.update(
        'list',
        {
            bookmark_id: bookmark_id,
        },
        function(collection) {
            collection.updated = date;
            return collection;
        },
    );
    window.db.commit();

    Icon.toggle(id);
    Widget.updateToggle();
    Widget.limitsCollection(bookmark_id);
    ga('send', 'event', 'Collection', 'Remove Icon', id);
    Widget.updateButtons();
};

Widget.replaceSvg = function(data) {
    var index = window.requestedSVG.indexOf(data.id);
    if (index > -1) {
        window.requestedSVG.splice(index, 1);
    }

    var img;
    var icon = $_(".collection[data-bookmark='" + data.bookmark + "'] .icon--item[data-id='" + data.id + "']");

    if (!icon) {
        img = $_(".collection .icon--item[data-id='" + data.id + "'] img");
        if (img) {
            icon = $_(".collection .icon--item[data-id='" + data.id + "']");
        } else {
            return;
        }
    }

    if (!img) {
        img = icon.get('img');
    }

    if (!img) {
        return;
    }

    if (!data.svg) {
        return;
    }

    var svg = data.svg.getElementsByTagName('svg')[0];

    svg.setAttribute('width', 48);
    svg.setAttribute('height', 48);

    svg = typeof ie !== 'undefined' ? cloneToDoc(svg) : svg;

    if (!svg || !img) {
        return;
    }

    img.parentElement.replaceChild(svg, img);

    Widget.createIconsCPicker(data.id, data.bookmark);

    //Private method only IE9
    function cloneToDoc(node, doc) {
        if (!doc) doc = document;
        var clone = doc.createElementNS(node.namespaceURI, node.nodeName);
        for (var i = 0, len = node.attributes.length; i < len; ++i) {
            var a = node.attributes[i];
            if (/^xmlns\b/.test(a.nodeName)) continue; // IE can't create these
            clone.setAttributeNS(a.namespaceURI, a.nodeName, a.nodeValue);
        }
        for (var i = 0, len = node.childNodes.length; i < len; ++i) {
            var c = node.childNodes[i];
            clone.insertBefore(c.nodeType == 1 ? cloneToDoc(c, doc) : doc.createTextNode(c.nodeValue), null);
        }
        return clone;
    }
};

Widget.renderSVG = function(elem) {
    if (null === elem) {
        return false;
    }

    elem.removeAttribute('onload');

    var collection = elem.parent('.collection');

    if (undefined === collection) {
        return false;
    }
    var dbCollection = Collection.getActive();

    if (!dbCollection.status) {
        elem.src = elem.dataset.src;
        //Common.UserAlerts.warning('Ooops!', messages.collection.locked);
        return false;
    }

    let collection_icons = collection.querySelectorAll('.collection_icons_list.icons li.icon--item');
    if (collection_icons.length > 0 && collection_icons.length <= 2) {
        var li_alignment = '';
        var j = 30;
        while (j--) {
            li_alignment += '<li class="icon-fill icon--item"></li>';
        }
        collection.querySelector('.collection_icons_list.icons').insertAdjacentHTML('beforeend', li_alignment);
    }

    var icon = elem.parent('.icon--item');

    var img = elem.dataset.src;

    elem.classList.remove('collectionIconImage');
    elem.src = img;
    elem.removeAttribute('data-src');

    icon.dataset.src = img;
};

Widget.getSVGCode = function(data) {
    data.id = parseInt(data.id, 10) || 0;

    if (!window.requestedSVG) {
        window.requestedSVG = [];
    }

    if (window.requestedSVG.indexOf(data.id) === -1) {
        window.requestedSVG.push(data.id);
    } else {
        return;
    }

    var req = new XMLHttpRequest();

    if (!data.bookmark || !data.id || !data.src) return;

    req.onreadystatechange = function() {
        if (req.readyState == 4 && req.status == 200) {
            Widget.replaceSvg({
                svg: req.responseXML,
                id: data.id,
                bookmark: data.bookmark,
            });
        }
        if (req.status == 404) {
            return;
        }
    };

    var parser = document.createElement('a');
    parser.href = data.src.replace('/static', '');
    if (data.premium == 1) {
        req.open('GET', data.src, true);
    } else req.open('GET', data.src, true);
    req.send();
};

Widget.clear = function() {
    if (document.querySelectorAll(".collection[data-order='0']").length) {
        document.querySelectorAll(".collection[data-order='0'] ul.icons li.icon--item").forEach(function(icon) {
            if (!icon.classList.contains('icon-upload')) {
                icon.parentElement.removeChild(icon);
            }
        });

        $_(".collection[data-order='0']").classList.add('empty');
    }
};

Widget.scroll = function(x, y) {
    var css_selector = 'ul.collection_icons_list';

    var element = $(css_selector);

    var container_top = $(css_selector).offset().top;
    var scroll_top = element.scrollTop();
    var container_height = element.outerHeight() || element.height();
    //var cursor_y = e.clientY;
    var range = 80;

    if (y > container_height - range + container_top) {
        setTimeout(function() {
            element.scrollTop(scroll_top + range);
        }, 100);
        return;
    }

    if (scroll_top > 0 && y < range + container_top) {
        setTimeout(function() {
            //self.scrollTop -= range;
            element.scrollTop(scroll_top - range);
        }, 100);
        return;
    }
};

Widget.reorderIcons = function(collection, send_data) {
    var icons = [];
    var li = document.querySelectorAll(
        '.collection[data-bookmark="' +
            collection.dataset.bookmark +
            '"] ul.collection_icons_list li.collectionIcon.icon--item',
    );
    var len = li.length;

    li.forEach(function(li) {
        icons.push({ id: li.dataset.id, sort: len });
        li.dataset.sort = len;

        Icon.set(li.dataset.id, { sort: len }, collection.dataset.bookmark, true);

        len--;
    });

    if (send_data) Collection.updateReorder(collection.dataset.bookmark, icons);
};

Widget.removeEmptyIcons_old = function() {
    document.querySelectorAll('ul.collection_icons_list .empty-icon').forEach(function(ico) {
        ico.parentNode.removeChild(ico);
    });
    document.querySelectorAll('ul.collection_icons_list .dragHover').forEach(function(ico) {
        ico.classList.remove('dragHover');
    });
};

Widget.addEmptyIcon_old = function(cloned, move) {
    if (cloned.dataset.id == move.dataset.id) return false;

    Widget.removeEmptyIcons();
    cloned.classList.add('empty-icon');

    if (
        cloned.nodeName == 'LI' &&
        document.querySelectorAll('.collection_icons_list .collectionIcon[data-id="' + move.dataset.id + '"]').length
    ) {
        // document.querySelector('.collection.expanded ul.collection_icons_list').insertBefore(cloned,move);
        var collection = Collection.getActive();
        document
            .querySelector('.collection[data-bookmark="' + collection.bookmark_id + '"] ul.collection_icons_list')
            .insertBefore(cloned, move);
    }
};

Widget.moveIcon = function(icon, move) {
    if (icon.dataset.id == move.dataset.id) return false;
    if (
        icon.nodeName == 'LI' &&
        document.querySelectorAll('.collection_icons_list .collectionIcon[data-id="' + move.dataset.id + '"]').length
    ) {
        // document.querySelector('.collection.expanded ul.collection_icons_list').insertBefore(cloned,move);
        var collection = Collection.getActive();
        document
            .querySelector('.collection[data-bookmark="' + collection.bookmark_id + '"] ul.collection_icons_list')
            .insertBefore(icon, move);
        icon.dataset.sort = parseInt(move.dataset.sort, 10) + 1;
    }
};

Widget.downloading = function() {
    var date = new Date();
    date.setTime(date.getTime() + 30 * 1000); // 30 seg
    var expires = '; expires=' + date.toGMTString();
    document.cookie = 'download_collection=1' + expires + '; path=/';
    //css muestro capa
    document.getElementById('collections').classList.add('loading');
    document.querySelector('.collection.expanded .download-holder button').classList.add('disabled');
    set_download_counter();
};

Widget.reset_widget = function(force) {
    document.querySelector('.detail__editor__body .free_user_warning').classList.add('hidden');
    document.querySelector('.detail__editor__canvas .collection_limit').classList.add('hidden');
    document.querySelector('.edit-icons-user-actions #download_button').classList.remove('hidden');
    document.querySelector('.detail__editor__canvas .collection_locked').classList.add('hidden');
};

var var_set_download_counter;
function set_download_counter() {
    var_set_download_counter = setInterval(checkDownloading, 500);
}

function checkDownloading() {
    if (cookie.getItem('download_collection')) {
        return;
    }

    document.querySelector('.collection.expanded .download-holder button').classList.remove('disabled');
    document.getElementById('collections').classList.remove('loading');
    clearTimeout(var_set_download_counter);
}

/** Handlebars.js */

Handlebars.registerHelper('maxCollectionIcons', function() {
    return Collection.limit.icons;
});

Handlebars.registerHelper('iconLocked', function() {
    // (this.premium==4) ? ' personal' : ''
    return this.premium == 1 && !window.UserSession.userInfo.session.premium
        ? ' locked'
        : this.premium == 4
        ? ' personal-icon'
        : '';
});

Handlebars.registerHelper('collectionLimitIcons', function() {
    var html = '';
    var url;
    this.icons.forEach(function(ico) {
        url = ico.imageUrl;
        html += '<li><img src="' + url + '" name="' + ico.name + '"></li>';
    });
    return html;
});

Handlebars.registerHelper('loadImg', function(a, b) {
    if ('src' in this) return this.src;
    if (this.premium == 4) {
        return secureImgPath.imageUrl;
    } else {
        return secureImgPath.imageUrl;
    }
});

Handlebars.registerHelper('dataSearch', function() {
    var word = $_('#words');
    if (!word) return;
    word = word.value;
    return ['?term=', word, '&page=', page, '&position=', this.position].join('');
});

Handlebars.registerHelper('shareUrl', function() {
    return btoa(this.bookmark_id);
});

Handlebars.registerHelper('setHeight', function() {
    if (window.db) {
        return window.db.rowCount('list') > 1
            ? window.db.rowCount('list') > 2
                ? 'collection-height-2'
                : 'collection-height-1'
            : '';
    }
});

export function toggleDetailButton() {
    if (document.body.dataset.section !== 'uicons-detail-page' && document.body.dataset.section !== 'uicons-landing') {
        if (ModalDetail.id) {
            toggleAxDetailButton();
        } else {
            if (typeof toggleSliderDetailButton == 'function') toggleSliderDetailButton();
        }
    }
}

export function toggleAxDetailButton() {
    if (window.toggleDetailBtnInterval) clearInterval(window.toggleDetailBtnInterval);
    var icon_id = document.querySelector('#download-form #icon_id').value,
        //icon_name = document.querySelector('.tags li a').innerHTML,
        icon_name = document.querySelector('#download-form #keyword').value,
        icon_team = document.querySelector('#download-form #team').value,
        icon_color = document.querySelector('#download-form #colored').value;

    if (window.db instanceof Object) {
        var method = 'addIcon';
        var collection = Collection.getActive();
        if (
            window.db.queryAll(collection.bookmark_id, {
                query: {
                    id: icon_id,
                },
            }).length
        ) {
            method = 'delIcon';
        }

        var btn = document.getElementById('detail-icon-button');

        if (btn) {
            var txt_btn;

            if (method === 'delIcon') {
                btn.classList.add('btn-danger');
                txt_btn = messages.collection.delete_icon;
            } else {
                btn.classList.remove('btn-danger');
                txt_btn = messages.collection.add_icon;
            }

            if (typeof btn.querySelector('span') != 'undefined') btn.querySelector('span').innerHTML = txt_btn;

            btn.setAttribute(
                'onclick',
                'Widget.' +
                    method +
                    '(' +
                    icon_id +
                    ",'" +
                    addSlashes(icon_name) +
                    "'," +
                    icon_team +
                    ', ' +
                    icon_color +
                    ')',
            );

            btn.get('i').classList.toggle('icon--removecollection', method == 'delIcon');
        }
    } else {
        window.toggleDetailBtnInterval = setInterval(toggleDetailButton, 1000);
    }
}

if (window.btoa == undefined) {
    window.btoa = function(input) {
        var str = String(input);
        var map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        for (
            var block, charCode, idx = 0, output = '';
            str.charAt(idx | 0) || ((map = '='), idx % 1);
            output += map.charAt(63 & (block >> (8 - (idx % 1) * 8)))
        ) {
            charCode = str.charCodeAt((idx += 3 / 4));
            if (charCode > 0xff) {
                throw new InvalidCharacterError(
                    "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.",
                );
            }
            block = (block << 8) | charCode;
        }
        return output;
    };
}

var spacing = '24';

document.querySelectorAll('.collection_icons_list .popover-button').forEach(function(button) {
    var list = button.closest('.collection_icons_list');
    var popover = button.nextElementSibling;

    button.addEventListener('click', function(e) {
        if (popover && list) {
            setTimeout(function() {
                var listBound = list.getBoundingClientRect();
                var popoverBound = popover.getBoundingClientRect();

                if (popoverBound.left <= listBound.left) {
                    var offsetX = listBound.left - popoverBound.left + spacing;

                    popover.style.transform = 'translateX(' + offsetX + 'px)';
                }
            }, 200);
        }
    });
});

function qq(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector));
}

// COLLECTIONS TOUR TOGGLE
const optionsCollectionsTour = [...document.querySelectorAll("input[name='collections__tour__option']")];
const toggleCheck = e => {
    optionsCollectionsTour.map(el => {
        if (el != e.target && el.checked) {
            el.checked = false;
        }
    });
};
optionsCollectionsTour.map(el => el.addEventListener('click', toggleCheck));
