import { ww } from 'bobjoll/ts/library/dom';
import { ViewTypes } from 'CommonApp/view';
import { indexOf } from 'lodash';
import { handleSearchOnBar, sendAutocompleteInfo } from 'Partials/search/search.helpers';
import { continue_exec_search } from 'ProjectOld/js/functions';

import * as Common from './common';

if (!Element.prototype.hasOwnProperty('parent')) {
    Element.prototype.parent = function(s) {
        var list = this.parents(s);
        if (typeof s == 'undefined') list.reverse();
        return list && list.length ? list[0] : null;
    };
}

let originalTermSearch;

$(function() {
    //Autocomplete
    window.awesompletes = [];
    var autocompleteTimeout = null;

    [].forEach.call(document.querySelectorAll('.autocomplete'), function(e) {
        var home_search = e.classList.contains('home_search_input');

        var awesomplete = new Awesomplete(e, {
            minChars: 2,
            item: function(text, input) {
                var tag = {
                    icons: 0,
                    items: [],
                    tag_id: 0,
                    tag: '',
                };

                for (var i in window.autocompleteTags) {
                    if (window.autocompleteTags[i].tag == text) {
                        tag = window.autocompleteTags[i];
                        break;
                    }
                }

                if (tag.hasOwnProperty('packs')) {
                    tag.icons = tag.packs;
                }

                var html = '<div class="text-container">\
				<div class="text">$$</div></div>';
                html += '</ul>';
                html = html.replace(
                    '$$',
                    text
                        .toLowerCase()
                        .replace(new RegExp(input.toLowerCase(), 'g'), '<mark>' + input.toLowerCase() + '</mark>'),
                );

                return Awesomplete.$.create('li', {
                    innerHTML: html,
                    'aria-selected': 'false',
                    'data-position': tag.position,
                });
            },
            sort: function() {},
        });

        window.awesompletes.push(awesomplete);

        let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS) {
            let homeSearch = document.querySelector('.home_search_input');
            if (homeSearch) {
                homeSearch.classList.add('ios');

                let homeAwesomplete = document.querySelector('.home_search_input .awesomplete');
                if (homeAwesomplete) {
                    homeAwesomplete.addEventListener('click', () => {
                        let homeAutocomplete = document.querySelector('.home_search_input .autocomplete');
                        homeAutocomplete.focus();
                    });
                }
            }
        }

        $(e)
            .on('awesomplete-selectcomplete', function(ev) {
                e.value = e.value
                    .trim()
                    .split('(')[0]
                    .trim();
                const form = e.parent('form');
                const termsAwesomplete = Array.from(form.querySelectorAll('.awesomplete ul li')).map(
                    awesompleteTerm => {
                        return awesompleteTerm;
                    },
                );
                const termSelected = termsAwesomplete.find(
                    term => term.textContent.replace(/[^a-zA-ZÀ-ÿ ]/g, '') === e.value,
                );

                sendAutocompleteInfo(termSelected.dataset.position, e.value, originalTermSearch);

                if (typeof exec_search != 'undefined') {
                    exec_search();
                } else {
                    form.classList.contains('author_search') ? form.submit() : handleSearchOnBar();
                }
            })
            .on('blur', function(e) {
                e.value = '';
            });
    });

    $('.autocomplete').on('keyup', function(e) {
        const homeSearch = this.classList.contains('home_search_input');

        if ([27, 13, 37, 38, 39, 40, 106, 191].indexOf(e.keyCode) == -1) {
            const text = encodeURIComponent(this.value).replace(/[']/g, escape);
            const inputTypeSearch = document.querySelector('.search-holder .type-selector input[name=type]:checked');
            const searchRadio = document.querySelector(
                '.search-holder .type-selector input[id^="switch_search_by_packs"][name=search-group]:checked',
            );
            let packs = false;
            if (searchRadio && !homeSearch) {
                packs = searchRadio.getAttribute('data-type') == 'packs';
            }
            let url = packs ? APP_URL + 'ajax/autocomplete_packs?term=' + text : APP_URL + 'ajax/autocomplete/' + text;
            if (inputTypeSearch) {
                const separator = packs ? '&' : '?';
                url += separator + 'type=' + inputTypeSearch.value;
            }

            if (text && text.length > 1) {
                if (autocompleteTimeout) {
                    clearTimeout(autocompleteTimeout);
                }
                getTerms(url);
            }
        }
    });

    function getTerms(url) {
        autocompleteTimeout = setTimeout(() => {
            $.ajax({
                url: encodeURI(url),
                type: 'GET',
                success: function(response) {
                    parserAutocomplete(response);
                },
            });
        }, 400);
    }

    const parserAutocomplete = data => {
        window.autocomplete = [];
        window.autocompleteTags = data;

        for (var tag in data) {
            window.autocomplete.push(data[tag].tag);
        }

        window.awesompletes.forEach(function(element) {
            element.list = window.autocomplete;
        });

        const currentAwesomplete =
            ww.view.section === ViewTypes.home && !ww.UserSession.userInfo.logged
                ? document.querySelector('.home_search_input .awesomplete input[type="search"]')
                : document.querySelector('.main_search_bar .awesomplete input[type="search"]');

        originalTermSearch = currentAwesomplete.value;
    };
});
