import { isInternetExplorer } from 'Library/helpers/browser';

function placeCaretAtEnd(input: HTMLInputElement): void {
    const inputValueLength = input.value.length;

    if (isInternetExplorer) {
        return;
    }

    input.selectionStart = inputValueLength;
    input.selectionEnd = inputValueLength;
}

export { placeCaretAtEnd };
