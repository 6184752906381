import { Shutterstock, ShutterstockVM } from '@fc/sponsor_front';
import { cookie } from 'Project/ts/library/cookie';

export const getShutterStockPopup = async () => {
    const searchTerm = document.querySelector<HTMLInputElement>('.main_search_bar input[name="word"]')?.value || 'icon';
    const sponsorResponse = (await Shutterstock({
        placement: 'popup',
        country: USER_COUNTRY_CODE || 'us',
        projectId: 'www.flaticon.com',
        userId: cookie.getItem('_ga') || '',
        components: 'popup',
        redirectAndBase64: '//www.flaticon.com/edge/redirect?url=',
        categoryType: 'vector' || '',
        params: {
            query: searchTerm,
            language: LANGUAGE_SHORT,
            image_type: 'vector',
        },
        locale: USER_COUNTRY_CODE || 'us',
    })) as ShutterstockVM.ShutterstockResponsePopup;

    return sponsorResponse;
};

export const printShutterStockCustomPixel = (params: string) => {
    new Image().src = `${BASE_URL}_ga?${params}&r=${new Date().getTime()}`;
};
