function load_data_share() {
    var elem_share_url = document.querySelector("meta[property='og:url']");
    var elem_share_img = document.querySelector("meta[property='og:image']");
    var elem_share_tit = document.querySelector("meta[name='twitter:title']");
    var elem_share_des = document.querySelector("meta[property='og:description']");
    var url_qs = (document.location.search) ? document.location.search : '';
    var url_full = document.location.protocol + '//' + document.location.host + document.location.pathname + url_qs;
    var url = url_full;
    var image = (elem_share_img) ? elem_share_img.content : '';
    var title = (elem_share_tit) ? elem_share_tit.content : document.title;
    var desc = (elem_share_des) ? elem_share_des.content : document.title;
    var pathname = window.location.pathname;
    return {
        url: url,
        image: image,
        title: title,
        desc: desc,
        pathname: pathname
    };
}

export function fbpopup(data) {

    if (typeof data == 'undefined') { data = load_data_share(); }

    var openUrl = 'http://www.facebook.com/sharer.php?u=' + data.url;

    window.open(openUrl, "fbshare", "height=380,width=660,resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0");
    ga('send', 'event', 'Social', 'Facebook', data.pathname);
}

export function pinpopup(data) {

    if (typeof data == 'undefined') { data = load_data_share(); }

    var openUrl = 'http://pinterest.com/pin/create/button/?url=' + data.url + '&description=' + data.title + '&media=' + data.image;
    window.open(openUrl, "pinshare", "height=270,width=630,resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0");
    ga('send', 'event', 'Social', 'Pinterest', data.pathname);
}

export function glpopup(data) {

    if (typeof data == 'undefined') { data = load_data_share(); }

    var openUrl = 'https://plus.google.com/share?url=' + data.url;
    window.open(openUrl, "glshare", "height=270,width=630,resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0");
    ga('send', 'event', 'Social', 'Google+', data.pathname);
}

export function tweet(e, data) {
    if (e.hasOwnProperty("preventDefault"))
    {
        e.preventDefault();
    }

    if (typeof data == 'undefined') { data = load_data_share(); }

    var openUrl = 'http://twitter.com/share?url=' + data.url + '&text=' + data.title + '&';
    window.open(openUrl, 'Flaticon Twitter Share', 'height=450,width=550,top=' + ($(window).height() / 2 - 225) + ',left=' + $(window).width() / 2 + ',toolbar=0,location=0,menubar=0,directories=0,scrollbars=0');
    ga('send', 'event', 'Social', 'Twitter', data.pathname);
}

window.fbpopup = fbpopup;
window.pinpopup = pinpopup;
window.tweet = tweet;