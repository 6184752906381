import { ww } from 'bobjoll/ts/library/dom';
import { isMobile } from 'Library/helpers/mobile';
import { handleSearchOnBar } from 'Partials/search/search.helpers';
import Scroll from 'Project/ts/library/scroll';
import getXHR from 'Project/ts/partials/xhr';

import * as Common from './common';
import { $_ } from './common';

var touch;

window.addEventListener('userSessionLoaded', () => {
    window.isMobile = isMobile();

    $(document).on('mouseleave', '.icon--item', function(e) {
        var popover = this.querySelector('.popover');
        if (popover && popover.querySelector('button.popover-button.active')) {
            popover.classList.remove('popover-left');
            popover.classList.remove('popover-right');
            popover.querySelector('button.popover-button.active').classList.remove('active');
            popover.querySelector('div.popover-content.show-menu').classList.remove('show-menu');
        }
    });

    $(document).on('click', '#share-btn button', function() {
        this.parentNode.querySelector('input.input-share-url').value = document.location.href;
    });

    $('.grid_selector input[name=grid]').on('change', function() {
        var wrapper = document.querySelector('.search-result .icons');
        if (wrapper) {
            localStorage.setItem('grid_size', this.value);
            wrapper.classList.toggle('big', this.value == 'big');
            $(window).trigger('resize');
            var size = this.value == 'big' ? 128 : 64;
            [].forEach.call(wrapper.querySelectorAll('.icon--item img'), function(icon) {
                icon.width = size;
                icon.height = size;
            });
        }
    });

    /**
     * IF IE
     */
    if (Common.detectBrowser() == 'IE') {
        $('body').addClass('isIE');
    }

    /**
     * Touch devices
     */
    touch = 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    if (touch) {
        $(window).on('touchstart mousemove', onMouseMove);
        // $(document).on('click', '.icons .icon', iconHover);
    } else {
        document.body.classList.add('notouch');
    }

    /**
     * Show or Hide license/attribution message
     */
    checkLicense();

    // Fix Android input focus
    if (/Android [4-6]\.[0-9]/.test(navigator.appVersion)) {
        window.addEventListener('resize', function() {
            if (document.activeElement.tagName == 'INPUT') {
                document.activeElement.scrollIntoViewIfNeeded();
            }
        });
    }

    /**
     * Modal
     */
    $(document).on('click', '.modal-trigger', function(e) {
        document.getElementById(this.dataset.modal).checked = true;
        e.preventDefault();
    });

    $(document).on('click', '.modal-close', function(e) {
        if (typeof $(this) !== 'undefined' && typeof $(this).parents('div.modal')[0] !== 'undefined') {
            $(this)
                .parents('div.modal')[0]
                .get('.modal-state').checked = false;
            document.querySelector('body').classList.remove('modal-open');
            e.preventDefault();
        }
    });

    $('.modal-inner').on('click', function(e) {
        e.stopPropagation();
    });

    $('.modal-fade-screen').on('click', function() {
        [].forEach.call(document.find('.modal-state'), function(checkbox) {
            Common.closeModal(checkbox);
            ww.Downloads.cleanFormatValue();
        });
    });

    /**
     * Icon contextmenu
     */
    $(document).on('contextmenu', '.icon--item:not(.icon-upload)', function(ev) {
        const popover = this.querySelector('div.popover');
        if (!popover) {
            return;
        }
        if (popover.querySelector('button.popover-button')) {
            popover.querySelector('button.popover-button').classList.add('active');
        }

        if (popover.querySelector('div.popover-content')) {
            popover.querySelector('div.popover-content').classList.add('show-menu');
        }

        var menuW = 230;
        renderPopoverWithHorizonalPossitionContext(popover, menuW);
        ev.preventDefault();
    });

    dispatchEventTooltip();

    /**
     * Alert messages
     */
    $(document).on('click', '.alert .close-alert', function(e) {
        $(this)
            .parent()
            .removeClass('zoomIn')
            .addClass('zoomOut')
            .slideUp(300);
        e.preventDefault();
    });

    /**
     * Mouseup close
     */
    $(document).on('mouseup', function(e) {
        var c = e.target;
        if (!c.parentNode) {
            return;
        }

        if (e.button === 2) {
            return;
        }

        if (c.classList.length === 0 || c.classList.contains('active') || c.parentNode.classList.contains('active')) {
            return;
        }
        if (c.classList.contains('popover-external-link')) {
            window.open(c.href, c.target);
        }

        if (
            c.classList.contains('popover-container') ||
            [].some.call(c.parents(), function(p) {
                return p.classList.contains('popover-container');
            })
        ) {
            return;
        }

        [].forEach.call(document.find('.popover-content'), function(e) {
            if (!e.parentNode.classList.contains('popover-not-close')) {
                e.classList.remove('show-menu');
            }
        });

        [].forEach.call(document.find('.popover-button'), function(btn) {
            if (!btn.parentNode.classList.contains('popover-not-close')) {
                btn.classList.remove('active');
            }
        });
    });

    /**
     * Popover
     */
    $(document).on('click', '.popover-button', function(e) {
        popover_render(e, this);
    });

    /**
     * Fullscreen blocks
     */
    fullscreen();

    /**
     * Scroll fixed bars
     */
    Scroll.add(function() {
        var filter = document.querySelector('.list-options');
        var searchIconsPage = document.querySelector('.search-data');
        if (null == filter || null != searchIconsPage) {
            return;
        }
    });

    /**
     * Form evaluation
     */
    try {
        var time_valuation = parseInt(localStorage.time_valuation);

        if (!time_valuation || isNaN(time_valuation)) {
            time_valuation = 0;
        }

        var form_valuation = $_('#form_valuation');

        if (form_valuation) {
            if (parseInt(localStorage.time_valuation, 10) % 70 == 0) {
                form_valuation.classList.add('active');
            } else {
                form_valuation.classList.remove('active');
            }
        }

        localStorage.time_valuation = ++time_valuation;
    } catch (e) {}

    const handleTrustpilotEvents = () => {
        document.getElementById('open_panel').addEventListener('click', function() {
            const formValuation = document.getElementById('form_valuation');
            formValuation.classList.toggle('active');
            const buttonPanel = document.getElementById('button_panel');

            if (formValuation.classList.contains('active')) {
                buttonPanel.innerHTML = '<i class="icon icon--caret-left text-inverted"></i>';
            } else {
                buttonPanel.innerHTML = '<i class="icon icon--caret-right text-inverted"></i>';
            }
        });

        document.getElementById('form_valuation').addEventListener('submit', e => {
            e.preventDefault();
            const id = document.getElementById('id_last_valuation');
            const comment = document.getElementById('valuation_text');

            if (comment.value.trim().length) {
                const url = 'ajax/valuations/send-user-comment';
                const data = {
                    id: id.value,
                    comment: comment.value,
                };

                getXHR(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(data),
                });
            }

            document.getElementById('valuation_comment_panel').style.display = 'none';
            document.getElementById('valuation_congrat').style.display = 'block';

            setTimeout(function() {
                close_valuation();
            }, 3000);
        });

        document.querySelectorAll('.valuation_response').forEach(valuationElement => {
            valuationElement.addEventListener('click', function() {
                let UA = navigator.userAgent,
                    temp,
                    browser = [],
                    browserVersion = UA.match(
                        /(opera|chrome|safari|firefox|msie|maxthon|lunascape)\/?\s*(\.?\d+(\.\d+)*)/i,
                    );

                if (browserVersion && (temp = UA.match(/version\/([\.\d]+)/i)) !== null) {
                    browserVersion[2] = temp[1];
                }

                browser['name'] = browserVersion[1];
                browser['version'] = browserVersion[2];

                if (this.dataset.value === '9' || this.dataset.value === '10') {
                    open_trustpilot();
                } else {
                    $_('#valuation_panel').style.display = 'none';
                    $_('#valuation_comment_panel').style.display = 'block';
                }

                const url = `${BASE_URL}ajax/valuations/send-valuation`;
                const data = {
                    value: this.dataset.value,
                    question: $_('#valuation_question_id').value,
                    browser: browser['name'],
                    browser_version: browser['version'],
                    language: LANGUAGE_SHORT,
                };

                getXHR(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(data),
                }).then(response => {
                    document.getElementById('id_last_valuation').value = response.data.id;
                });
            });
        });

        document.getElementById('no_thanks').addEventListener('click', close_valuation);
        document.getElementById('no_thanks_trustpilot').addEventListener('click', close_valuation);
    };

    window.addEventListener('formTrustpilot', () => {
        handleTrustpilotEvents();
    });

    function close_valuation() {
        document.getElementById('valuation_text').value = '';
        document.getElementById('id_last_valuation').value = '';
        document.getElementById('valuation_comment_panel').style.display = 'none';
        document.getElementById('form_valuation').classList.remove('active');
        document.getElementById('valuation_congrat').style.display = 'none';
        document.getElementById('valuation_panel').style.display = 'block';
        document.getElementById('trustpilotNPS').style.display = 'none';
    }

    const open_trustpilot = () => {
        document.getElementById('valuation_panel').style.display = 'none';
        document.getElementById('valuation_comment_panel').style.display = 'none';
        document.getElementById('trustpilotNPS').style.display = 'block';
    };

    /**
     * Keyup
     */
    $(document).on('keyup', function(e) {
        e.preventDefault();
        if (e.which == 27) {
            [].forEach.call(document.find('.popover-content'), function(e) {
                e.classList.remove('show-menu');
            });
        }
    });

    /**
     * Input events
     */
    $('.reset-input').off();

    $(document).on('click', '.reset-input', function() {
        if (
            $(this)
                .siblings('input')
                .val() !== ''
        ) {
            $(this)
                .siblings('input')
                .val('')
                .focus();
        } else if (
            $(this)
                .siblings('input')
                .attr('id') != 'username' &&
            $(this)
                .siblings('input')
                .attr('id') != 'email'
        ) {
            $(this)
                .siblings('input')
                .attr('placeholder', '');
        }

        $(this)
            .siblings('input')
            .removeClass('error')
            .parent()
            .next('span.error')
            .remove();

        return false;
    });

    $('.input-group input, .textarea-group textarea').off();
    $('.input-group input, .textarea-group textarea').on('blur', function() {
        if (!$(this).val()) {
            $(this).removeClass('hascontent');
            if ($(this).attr('placeholder')) {
                this.addClass('hascontent');
            }
        } else {
            $(this).addClass('hascontent');
        }
    });

    $('.input-group input, .textarea-group textarea').on('keyup', function() {
        $(this)
            .removeClass('error')
            .parent()
            .next('span.error')
            .remove();

        if ($(this).attr('id') == 'password' || $(this).attr('id') == 'repeat_password') {
            $('#password')
                .removeClass('error')
                .parent()
                .next('span.error')
                .remove();
            $('#repeat_password')
                .removeClass('error')
                .parent()
                .next('span.error')
                .remove();
        }
    });

    $('.input-group input, .textarea-group textarea').each(function() {
        if (!$(this).val()) {
            $(this).removeClass('hascontent');
            if ($(this).attr('placeholder')) {
                this.addClass('hascontent');
            }
        } else {
            $(this).addClass('hascontent');
        }
    });

    // if ($_('.tabs')) {
    //     tabs();
    // }

    /**
     *
     */
    $(document).on('click', '.selectInput', function() {
        this.select();
    });

    1;
    store_url();

    // log events with event delegation
    document.addEventListener(
        'click',
        function(event) {
            var target = event.target;
            while (target) {
                if (undefined != target.dataset && target.dataset[Logs.dataKey]) {
                    Logs.pushLog(target.dataset[Logs.dataKey]);
                    break;
                }
                target = target.parentNode;
            }
        },
        false,
    );

    function getNavigator() {
        let ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }

        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null)
                return tem
                    .slice(1)
                    .join(' ')
                    .replace('OPR', 'Opera');
        }

        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    }

    function hideFreepikEditor() {
        let freepikEditor = document.querySelector('.link-editor');
        let navigator = getNavigator().split(' ')[0];

        if (navigator == 'Edge' || navigator == 'Opera' || navigator == 'IE') {
            freepikEditor.classList.add('hide');
        }
    }

    hideFreepikEditor();
    emptyAuthorBox();
});

/**
 * Hybrid devices
 */
function onMouseMove(e) {
    if (e.type == 'touchstart' && this.touchstart && !document.body.classList.contains('notouch')) {
        return;
    }

    if (e.type == 'touchstart') {
        this.touchstart = true;
    }

    if (e.type == 'mousemove' && !this.touchstart && document.body.classList.contains('notouch')) {
        return;
    }

    if (this.touchstart) {
        document.body.classList.remove('notouch');
    } else {
        document.body.classList.add('notouch');
    }

    if (e.type == 'mousemove') {
        this.touchstart = false;
    }
}

/**
 * Fullscreen block
 */
function fullscreen() {
    var element = $('.fullscreen'),
        windowW = window.innerWidth,
        windowH = window.innerHeight;

    if (element.length === 0) {
        return;
    }
    if (windowW <= 768) {
        return;
    }

    function onResize(e) {
        var condition = e.type === 'resize' ? windowW !== window.innerWidth && windowH !== window.innerHeight : true,
            minWidth = element.data('fullscreen'),
            minWidth = minWidth ? minWidth : 8640,
            minHeight = element.find('> *').outerHeight(true);

        if (window.innerWidth <= minWidth && window.innerHeight > minHeight) {
            if (condition || !_touch) {
                element.css('height', window.innerHeight - 56);
            }
        } else {
            element.removeAttr('style');
        }

        (windowW = window.innerWidth), (windowH = window.innerHeight);
    }

    $(document).ready(onresize());
    $(window).on('resize', onResize);
}

function exec_search(value, form) {
    if (typeof gr.sponsor != 'undefined' && gr.sponsor != null) {
        if (
            !window.FeatureFlag.isEnabled('adobe_maintenance') &&
            !touch &&
            typeof gr.sponsor.popup.launch == 'function' &&
            !~~gr.auth.me.premium
        ) {
            gr.sponsor.popup.launch();
        }
    }
    var isHangul = Common.isHangulWord(value) && language == 'korean';
    var isCyrillic = Common.isCyrillicWord(value) && language == 'russian';
    var filteredWord = Common.filter_text(value);
    var inputTypeSearch = document.querySelector('.search-holder .type-selector input[name=type]:checked').value;
    if (value.length && (filteredWord !== '' || isHangul || isCyrillic)) {
        $.ajax({
            cache: false,
            type: 'post',
            data: {
                word: value,
                type: inputTypeSearch,
            },
            url: APP_URL + 'stats/search',
            success: function() {
                handleSearchOnBar();
            },
            error: function() {
                handleSearchOnBar();
            },
        });
    } else {
        handleSearchOnBar();
    }
}

export const continue_exec_search = () => {
    let params = Array.from(document.querySelectorAll('.search-view-filters input:checked'));

    const inputWordSearch = document.querySelector('.search-holder form input[name=word]');
    const inputWordSearchHome = document.querySelector('.home_search_input.fake-search input[name=word]');
    const authorSearch = document.querySelector('[data-section=main_search_author]');
    const inputTypeSearch = document.querySelector('.search-holder .type-selector input[name=type]:checked');
    let stickerSearchInput = params.find(element => element.value == 'sticker');
    let stickerSearchActive = stickerSearchInput ? true : false;
    let wordSearchValue =
        inputWordSearch && inputWordSearch.value.length > 0
            ? `${inputWordSearch.name}=${inputWordSearch.value}`
            : `${inputWordSearchHome.name}=${inputWordSearchHome.value}`;
    var qs = [];
    var querystring = '';

    qs.push(wordSearchValue);
    authorSearch ? qs.push(`author_id=${authorSearch.dataset.value}`) : '';

    if (typeof params != 'undefined' && !stickerSearchActive) {
        params.forEach(function(p) {
            if (
                typeof p.name != 'undefined' &&
                typeof p.value != 'undefined' &&
                p.name != '' &&
                p.dataset.value != '' &&
                p.name.indexOf('-') == -1 &&
                p.name == 'license'
            ) {
                if (
                    p.name == 'style' &&
                    (p.dataset.value == 'gradient' ||
                        p.dataset.value == 'lineal-color' ||
                        p.dataset.value == 'flat' ||
                        p.dataset.value == 'hand-drawn')
                ) {
                    p.name = 'filter_style';
                }

                if (
                    p.name == 'style' &&
                    (p.dataset.value == 'linear' || p.dataset.value == 'filled' || p.dataset.value == 'multicolor')
                ) {
                    let colorParam = p.dataset.style.replace(',', '&');
                    colorParam = colorParam.split('|');
                    colorParam.shift();
                    colorParam[0] = colorParam[0].replace(',', '&');
                    colorParam = colorParam.join('=');

                    p.name = 'color';
                    p.dataset.value = colorParam;
                }

                let pValue = p.dataset.value ? p.dataset.value : p.value;

                if (p.name == 'order_by') {
                    pValue = p.value;
                }

                qs.push(p.name + '=' + pValue);
            }
        });
    }

    if (qs.length) {
        querystring = '?' + qs.join('&');
    }

    if (
        document.querySelector('form #main-search-current_section') &&
        document.querySelector('form #main-search-current_section').value
    ) {
        window.location.href = document.querySelector('.main_search_bar').action + querystring;
        return;
    }

    var checked;
    let checkedPack;
    const interfaceIcons = 'interface-icons';
    const interfaceIconsFilter = '&ec=all';
    checked = document.querySelector('.search-holder .type-selector input[name=type]:checked');
    checkedPack = document.querySelector('.search-holder .type-selector input[name=search-group]:checked');

    if (qs.length == 1 && checked.dataset.type == interfaceIcons) {
        querystring = querystring.concat(interfaceIconsFilter);
    }

    if (checked.value === interfaceIcons) {
        checked.value = 'icon';
    }
    window.location.href = checkedPack
        ? checkedPack.getAttribute('data-url') + querystring + `&type=${checked.value}`
        : checked.getAttribute('data-url') + querystring + `&type=${checked.value}`;
};

ww.valuation_request_comment = function(data) {
    $_('#id_last_valuation').value = data.id;
};

// ------------ END OF DOCUMENT READY ------------ //

function checkLicense() {
    var html_license = document.querySelector('.detail-footer .license');

    if (html_license) {
        if (gr.auth.me.premium && document.querySelector('#download-form #selection').value == 1) {
            html_license.classList.add('hidden');
        } else {
            html_license.classList.remove('hidden');
        }
    }
}

export function urlUserIcon(icon_id, user_id) {
    user_id = !user_id ? gr.auth.me.id : user_id;

    if (user_id && icon_id) {
        var folder = Math.floor(user_id / 1000);
        return STATIC_URL + 'user_icons/' + folder + '/' + user_id + '/' + icon_id + '.svg';
    }
    return;
}

function popover_render(ev, element) {
    //this.blur();
    var menu = element.parentElement.parentElement.get('.popover-content');
    var popoverContainer = menu.parentElement.parentElement;

    //Private method
    function removeAll() {
        [].forEach.call($_('.popover-content'), function(popover) {
            if (popover == menu) {
                return true;
            }
            popover.classList.remove('show-menu');
        });

        [].forEach.call($_('.popover-button'), function(btn) {
            btn.classList.remove('active');
        });

        [].forEach.call($_('.popover'), function(popover) {
            if (popover == menu) {
                return true;
            }
            if (popover.classList.contains('hToggleRender')) {
                popover.classList.remove('popover-right');
            }
            if (popover.classList.contains('vToggleRender')) {
                popover.classList.remove('popover-top');
                popover.classList.remove('popover-button');
            }
        });
    }

    if (!element.classList.contains('active')) {
        removeAll();

        menu.classList.add('show-menu');
        element.classList.add('active');
    } else {
        menu.classList.remove('show-menu');
        element.classList.remove('active');
    }

    if (popoverContainer.classList.contains('hToggleRender')) {
        renderPopoverWithHorizonalPossitionContext(popoverContainer, 230);
        renderTooltipWithHorizonalPossitionContext(tooltip, 230);
    }

    if (popoverContainer.classList.contains('vToggleRender')) {
        renderPopoverWithVerticalPossitionContext(popoverContainer, 100);
    }

    ev.stopPropagation();
    // e.preventDefault();
}

function renderPopoverWithHorizonalPossitionContext(popover, menuW) {
    if (document.querySelector('.detail--v2 .icons')) {
        var diff =
            popover.querySelector('button.popover-button').getBoundingClientRect().left -
            document.querySelector('.detail--v2 .icons').getBoundingClientRect().left;
    } else {
        var diff = popover.querySelector('button.popover-button').getBoundingClientRect().left;
    }

    if (popover.classList.contains('collectionIcon')) {
        menuW = 180 + document.getElementById('collections-holder').getBoundingClientRect().left;
    }
    if (diff < menuW) {
        popover.classList.add('popover-right');
    } else {
        popover.classList.add('popover-left');
    }
}

function renderTooltipWithHorizonalPossitionContext(tooltip, menuW) {
    if (document.querySelector('.detail--v2 .icons')) {
        var diff =
            tooltip.querySelector('.tooltip__content').getBoundingClientRect().left -
            document.querySelector('.detail--v2 .icons').getBoundingClientRect().left;
    } else {
        var diff = tooltip.querySelector('.tooltip__content').getBoundingClientRect().left;
    }

    if (tooltip.classList.contains('collectionIcon')) {
        menuW = 180 + document.getElementById('collections-holder').getBoundingClientRect().left;
    }

    if (!tooltip.classList.contains('icon--item__premium-badge')) {
        if (diff <= menuW) {
            tooltip.classList.remove('tooltip--left');
            tooltip.classList.add('tooltip--right');
        } else {
            tooltip.classList.add('tooltip--left');
            tooltip.classList.remove('tooltip--right');
        }
    }
}

function renderPopoverWithVerticalPossitionContext(popover, menuH) {
    if (popover.querySelector('button.popover-button').getBoundingClientRect().bottom > window.innerHeight - menuH) {
        popover.classList.add('popover-top');
    } else {
        popover.classList.add('popover-bottom');
    }
}

export function dispatchEventTooltip() {
    document.querySelectorAll('.icon--item .tooltip').forEach(tooltip => {
        tooltip.addEventListener('mouseenter', e => {
            var tooltip = e.target;
            var menuW = 230;
            renderTooltipWithHorizonalPossitionContext(tooltip, menuW);
            e.preventDefault();
        });
    });
}

export function store_url(url) {
    var not = ['downloads-abuse', 'downloads-limit', 'limite-descargas', 'abuso-descargas', 'user/follow/author/list'];
    if (!url) {
        url = window.location.href;
    }
    var ok = true;

    not.forEach(function(url_not) {
        if (url.indexOf(url_not) != -1) {
            ok = false;
        }
    });

    if (ok) {
        var date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000); // 1h
        var expires = '; expires=' + date.toGMTString();

        var cbUrl = null;
        try {
            var urlObj = new URL(url);
            var urlParams = urlObj.searchParams;
            var now = Date.now().toString();
            if (!urlParams.has('k')) {
                urlParams.append('k', now);
            } else {
                urlParams.set('k', now);
            }
            cbUrl = urlObj.origin + urlObj.pathname + '?' + urlParams.toString();
        } catch (e) {
            cbUrl = url;
        }

        document.cookie = `CB_URL=${cbUrl}${expires};path=/;`;
    }
}

export function checkHybriDevices() {
    touch = 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

    if (touch) {
        document.body.classList.add('notouch');
    }
}

export function closePopup(el) {
    while ((el = el.parentElement) && !el.classList.contains('popover-container'));

    el.querySelector('.popover-button').classList.remove('active');
    el.querySelector('.popover-content').classList.remove('show-menu');
}

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function emptyAuthorBox() {
    const authorBox = document.querySelectorAll('.box--author .box--author-pt2');

    if (authorBox) {
        for (const author of authorBox) {
            if (author.children.length == 1) {
                let numberTimes = 0;
                while (numberTimes < 2) {
                    const authorSprite = document.createElement('div');
                    authorSprite.innerHTML = '<div class="img-wrapper"></div>';
                    authorSprite.classList.add('sprite');
                    authorSprite.classList.add('empty');
                    author.insertAdjacentElement('beforeend', authorSprite);
                    numberTimes++;
                }
            } else if (author.children.length == 2) {
                const authorSprite = document.createElement('div');
                authorSprite.innerHTML = '<div class="img-wrapper"></div>';
                authorSprite.classList.add('sprite');
                authorSprite.classList.add('empty');
                author.insertAdjacentElement('beforeend', authorSprite);
            }
        }
    }
}
