/**
 * GLOBAL VARS
 */

var ls = localStorage;
var maxCustomColor = 5;

$(document).ready(function() {
    /*initCustomColors();
    if (typeof colpick !== 'undefined') {
        $('#detail_picker').colpick({
            layout: 'hex',
            submit: 0,
            flat: true,
            onChange: function(hsb, hex, rgb, el, bySetColor) {
                $(el).css('border-color', '#' + hex);
                // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
                if (!bySetColor) $(el).val(hex);

                ls.color = '#' + hex;
                changeSVGcolor();
            },
        });
    }

    //Color select in collection
    $(document).on('click', '#detail .color-selector li button', function() {
        var color = $(this).data('color');
        if (!color) return;

        $(this)
            .parents('ul')
            .find('li button')
            .removeClass('selected');
        $(this).addClass('selected');
        $('#detail_picker').colpickSetColor(color);

        ls.color = color;

        changeSVGcolor();
    });

    //Download
    $(document).on('click', '#detail ul.size button', function() {
        var size = $(this).attr('data-size');

        $('#download-form #size').val(size);
        if ($('#colored').length > 0 && $('#colored').is(':checked')) {
            $('#download-form #svg_content').val($('svg#img-main-icon').html());
        }
    });

    $(document).on('click', '.detail-footer .download li a', function(e) {
        // Private variables
        var all = $('.detail-footer .download li a'),
            curr = $(this),
            currAct = curr.hasClass('active'),
            format = curr.data('format'),
            formats = ['png', 'svg', 'base64'],
            icon_colored = parseInt(document.getElementById('colored').value, 10),
            editable = ($.inArray(format, formats) >= 0 && icon_colored == 1) || (icon_colored == 2 && format == 'png'),
            svgVersion = $('#detail .svg-version'),
            customize = $('#detail .customize'),
            customizeAct = customize.hasClass('active'),
            customizeTimer = customizeAct ? 300 : 0;

        //Remove active state
        all.removeClass('active');
        svgVersion.removeClass('active');
        customize.removeClass('active');

        if (editable) {
            if (!currAct) {
                curr.addClass('active');
                if (icon_colored == 1) {
                    svgVersion.addClass('active');
                } else {
                    document.querySelector('section.customize ul.color-selector').previousElementSibling.classList.add('hidden');
                    document.querySelector('section.customize ul.color-selector').classList.add('hidden');
                }

                setTimeout(function() {
                    customize.addClass('active');
                }, customizeTimer);
            }
            if (!customizeAct && icon_colored == 1) {
                svgInline();
            }
        }

        // Update form values
        $('#id').val(icon_id);
        $('#format').val(format);

        //Only download button
        if (format == 'svg') {
            document.querySelector('section.customize ul.size .btn-big').classList.remove('hidden');
            document.querySelector('section.customize ul.size').previousElementSibling.classList.add('hidden');

            [].forEach.call(document.querySelectorAll('section.customize ul.size .btn-small'), function(li) {
                li.classList.add('hidden');
            });
        }

        // Show sizes, not download
        if (format == 'png') {
            document.querySelector('section.customize ul.size .btn-big').classList.add('hidden');
            document.querySelector('section.customize ul.size').previousElementSibling.classList.remove('hidden');
            [].forEach.call(document.querySelectorAll('section.customize ul.size .btn-small'), function(li) {
                li.classList.remove('hidden');
            });
        }

        return false;
    });
	*/
});

//--------------------------------------------------------------

/**
 * Reemplaza el svg por su contenido inline
 */
function svgInline() {
    //Private variables
    var svgVersion = $('#detail .svg-version div'),
        imgVersion = $('#detail .img-version img'),
        svgArray = [];

    for (var i = 0; i < imgVersion.length; i++) {
        svgArray.push($.get(imgVersion[i].getAttribute('src')));
    }

    $.when(svgArray).done(function() {
        svgArray.forEach(function(response, index) {
            response.success(function(data) {
                if (typeof data == 'object') {
                    var svg = $(data).find('svg');
                    svg.removeAttr('xmlns:a');

                    svgVersion.eq(index).html(svg);
                } else {
                    svgVersion.eq(index).html(imgVersion[index]);
                }
            });
        });
    });
}

//--------------------------------------------------------------

/**
 * Cambia el color al SVG
 */
function changeSVGcolor() {
    var color = ls.color;
    if (!color) return;

    $('#download-form #color').val(color);

    var detail_icons = document.querySelectorAll('#detail .svg-version svg');

    [].forEach.call(detail_icons, function(img) {
        [].forEach.call(img.querySelectorAll('path, circle, ellipse, polygon, line, rect, polyline'), function(p) {
            p.style.fill = color;
        });
    });

    var section = $('#detail .svg-version');
    var colorNum = color.substring(1);

    if (isLightColor(colorNum)) section.addClass('light-icons');
    else section.removeClass('light-icons');
}

//--------------------------------------------------------------

export function saveColor(color) {
    if (typeof color == 'undefined') {
        return;
    }

    var colors = [];
    var n = 0;

    if (color.charAt(0) != '#') color = '#' + color;

    if ($('#detail .color-selector button[data-color="' + color + '"]').length) return;

    if (typeof ls.customColors !== 'undefined' && ls.customColors != '') {
        colors = JSON.parse(ls.customColors);
        n = colors.length;
    }

    if (colors.indexOf(color) == -1) {
        if (n >= maxCustomColor) {
            var key = Object.keys(colors);
            var index = key[0];
            var c = colors[index];

            colors.splice(index, 1);

            $('#detail ul.color-selector .custom-color[data-color="' + c + '"]')
                .parent()
                .remove();
        }

        colors.push(color);

        paintCustomColor(color);

        ls.customColors = JSON.stringify(colors);
    }
}

//--------------------------------------------------------------

function initCustomColors() {
    if (typeof ls.customColors !== 'undefined' && ls.customColors != '') {
        let colors = JSON.parse(ls.customColors);
        let n = colors.length;

        for (let i = 0; i < n; i++) {
            paintCustomColor(colors[i]);
        }
    }
}

//--------------------------------------------------------------

function paintCustomColor(color) {
    var gridCustomFav = $('#detail ul.color-selector');

    var colorFavLi = document.createElement('li');
    var btn = document.createElement('button');
    btn.dataset.color = color;
    btn.className = 'custom-color';
    btn.style.backgroundColor = color;
    btn.addEventListener('click', changeSVGcolor);

    colorFavLi.appendChild(btn);
    gridCustomFav.append(colorFavLi);
}

//--------------------------------------------------------------

function isLightColor(color) {
    var r = parseInt(color.substr(0, 2), 16);
    var g = parseInt(color.substr(2, 2), 16);
    var b = parseInt(color.substr(4, 2), 16);

    return r * 299 + g * 587 + b * 114 >= 225000;
}
