import { Premium } from 'Project/ts/partials/Premium';

import { toggleAxDetailButton } from './collections';

$(document).on('mousedown', 'section.related-styles *:not(ul.icons):not(header.related-header)', function(e) {
    e.stopPropagation();
    if (e.wich !== 3) {
        EventTracker.send('all', 'event', 'detail-view', 'click', 'related-styles');
    }
});

$(document).on('mousedown', 'section.related-packs *:not(ul.icons):not(header.related-header)', function(e) {
    e.stopPropagation();
    if (e.wich !== 3) {
        EventTracker.send('all', 'event', 'detail-view', 'click', 'related-packs');
    }
});

$(document).on('mousedown', 'section.related-tags *:not(ul.icons):not(header.related-header)', function(e) {
    e.stopPropagation();
    if (e.wich !== 3) {
        EventTracker.send('all', 'event', 'detail-view', 'click', 'related-tags');
    }
});

// Related icons
//
$(document).on('click', '.detail_equivalent_icons .link-icon-detail', function(e) {
    if (document.querySelector('section[id=detail]').parentNode.id == 'viewport') {
        window.location.href = `${this.href}${this.dataset.src}`;

        return false;
    }

    ga('send', 'event', 'Detail-modal', 'Toggle-icon-style');

    e.preventDefault();

    // Obtención de datos del icono
    const id = ~~this.dataset.id;
    const folder = Math.floor(id / 1000);
    const selection = ~~this.dataset.selection;
    const color = ~~this.dataset.color;

    // Toggle de icono seleccionado activo;
    const active = document.querySelector('.detail_equivalent_icons .link-icon-detail.active');

    if (active) {
        active.classList.remove('active');
    }
    this.classList.add('active');

    // Añadimos las imágenes SVG o PNG segÃºn corresponda
    const images = document.querySelectorAll('.gallery .main-icon');

    [].forEach.call(images, function(i) {
        const path = STATIC_URL;
        if (color == 1) {
            var url = '{0}icons/svg/{1}/{2}.svg'.format(path, folder, id);
            i.innerHTML = '<img src="{0}">'.format(url);

            getSVG($(i), url);
            document.querySelector('.svg-version').classList.remove('active');
        } else {
            i.innerHTML = '<img src="{0}icons/png/512/{1}/{2}.png">'.format(path, folder, id);
        }
    });

    if (document.querySelector('.customize.active') && this.dataset.color == 1) {
        document.querySelector('.gallery .svg-version').classList.add('active');
    }

    // Mostramos u ocultamos según si el icono es customizable en relación al color o no

    if (document.querySelector('section.customize ul.color-selector')) {
        document.querySelector('section.customize ul.color-selector').classList.toggle('hidden', color == 2);
        if (document.querySelector('section.customize ul.color-selector').previousElementSibling) {
            document
                .querySelector('section.customize ul.color-selector')
                .previousElementSibling.classList.toggle('hidden', color == 2);
        }
    }

    // Actualización del formulario de descarga
    const form = document.getElementById('download-form');
    form.querySelector('#icon_id').value = id;
    form.querySelector('#colored').value = color;
    form.querySelector('#selection').value = selection;

    // Actualización Botón de añadir a colección
    toggleAxDetailButton();

    // Actualización de H1 y Tooltip de icono Premium / Selection
    const h1 = document.querySelector('h1');
    const i = h1.querySelector('span:not(.title)');
    if (i) {
        i.parentNode.removeChild(i);
    }

    Premium.check();

    // Actualización de los enlaces a los packs relacionados
    const link = APP_URL + this.dataset.pack_slug;
    [].forEach.call(
        document.querySelectorAll(
            '.related-packs .related-header a:not(:first-child), #detail-header-more .group-pack a',
        ),
        function(anchor) {
            anchor.href = link;
        },
    );
});
