import { ww } from 'bobjoll/ts/library/dom';

import { logged_in_callback } from './Auth';
import { callback_handler, Collection, Widget } from './collections';
import { Notifier, sanitize_filename } from './common';
import * as Downloads from './fi_download';
import * as Functions from './functions';
import { INFINITY } from './infinity';
import { localStorageDB } from './localstoragedb';
import * as SocialShare from './social_share';

ww.localStorageDB = localStorageDB;
ww.SocialShare = SocialShare;
ww.Downloads = Downloads;
ww.sanitize_filename = sanitize_filename;
ww.Notifier = Notifier;
ww.INFINITY = INFINITY;
ww.gbjs = true;

window.addEventListener('userSessionLoaded', () => {
    ww.Collection = Collection;
    ww.Functions = Functions;
    ww.Widget = Widget;
    ww.logged_in_callback = logged_in_callback;
    ww.callback_handler = callback_handler;
});

window.dispatchEvent(new CustomEvent('globalLoaded'));

Object.defineProperty(Object.prototype, 'getPathValue', {
    value: function(path) {
        return path.split('.').reduce(function(obj, key) {
            if (obj) {
                return obj[key];
            }

            return false;
        }, this);
    },
    writable: true,
    configurable: true,
    enumerable: false,
});
