import { ww } from 'bobjoll/ts/library/dom';
import { ViewTypes } from 'CommonApp/view';
import { urlWithoutPag } from 'CommonFI/urls.helper';
import { Follow } from 'Library/follow';
import { isMobile } from 'Library/helpers/mobile';
import { copyToClipboardHandleListeners } from 'Pods/copyPaste/copyToClipboard';
import { numberFormat } from 'Project/ts/library/helpers/numberFormat';
import { renderlistResourceComponents } from 'Project/ts/pages/icon-detail/componentsServices/listComponentsService';
import { Premium } from 'Project/ts/partials/Premium';
import { LazyModal } from 'Project/ts/pods/modals/lazyModal';

import { $_ } from './common';
import * as Common from './common';
import * as Functions from './functions';

$(document).ready(function() {
    INFINITY.init();

    if (document.getElementById('pagination-form') && INFINITY.pages) {
        INFINITY.currentPage = INFINITY.initPage;
        var totalPages = INFINITY.pages ? INFINITY.pages : Math.ceil(INFINITY.total / INFINITY.count, 10);
        document.getElementById('pagination-total').innerHTML = numberFormat(totalPages);
        document.getElementById('pagination-page').value = INFINITY.currentPage;

        INFINITY.check_pagination_buttons();

        $('#pagination-form').on('submit', function(e) {
            e.preventDefault();
            INFINITY.infinityLoaderBtn(INFINITY.axScript, 'go');
            return false;
        });

        $('a.pagination-next').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            document.getElementById('pagination-spinner').classList.remove('hidden');
            INFINITY.infinityLoaderBtn(INFINITY.axScript, 'next');
            return;
        });

        $('a.pagination-prev').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            document.getElementById('pagination-spinner').classList.remove('hidden');
            INFINITY.infinityLoaderBtn(INFINITY.axScript, 'prev');
            return;
        });

        $('a.pagination-first').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            document.getElementById('pagination-spinner').classList.remove('hidden');
            INFINITY.infinityLoaderBtn(INFINITY.axScript, 'first');
            return;
        });
    }

    if (typeof 'hideBox' == 'function') {
        hideBox();
        if (INFINITY.total_found_packs) {
            $(window).on('resize', function() {
                hideBox();
            });
        }
    }

    $('.pagination a').on('click', function() {
        if (typeof gr.sponsor != 'undefined' && gr.sponsor != null) {
            if (
                typeof gr.sponsor.popup != 'undefined' &&
                !gr.auth.me.premium &&
                !window.FeatureFlag.isEnabled('adobe_maintenance')
            )
                gr.sponsor.popup.launch();
        }
    });

    $('.pagination-buttons a').on('click', function() {
        if (typeof gr.sponsor != 'undefined' && gr.sponsor != null) {
            if (
                typeof gr.sponsor.popup != 'undefined' &&
                !gr.auth.me.premium &&
                !window.FeatureFlag.isEnabled('adobe_maintenance')
            )
                gr.sponsor.popup.launch();
        }
    });

    var empty_search_element = $_('.empty-search');

    if (empty_search_element) {
        if (INFINITY.total == 0) {
            $('.edge-content-iframe').hide();
            empty_search_element.classList.toggle('hidden', INFINITY.total > 0);

            if (document.getElementById('pagination-more')) {
                document.getElementById('pagination-more').classList.add('hidden');
            }
        } else {
            $('.edge-content-iframe').show();
            empty_search_element.classList.toggle('hidden', INFINITY.total > 0);
            if (document.getElementById('pagination-more')) {
                document.getElementById('pagination-more').classList.remove('hidden');
            }
        }

        if (typeof document.querySelector(".main_search_bar input[name='word']") != 'undefined') {
            var word = document.querySelector(".main_search_bar input[name='word']").value;

            if (document.querySelector('.empty-search').className.indexOf('hidden') == -1 && word.length) {
                var type = localStorage['search-type']; // icons, packs
                type = ['icons', 'packs'].indexOf(type) != -1 ? type : 'icons';

                ga('send', 'event', 'search-no-results', language, { dimension9: word });
            }
        }
    }

    if (null == document.getElementById('tags-page')) {
        if (
            document.getElementById('pagination-total') == null ||
            parseInt(document.getElementById('pagination-total').innerHTML.replace(',', ''), 10) <= 1
        ) {
            if (document.getElementById('pagination-more')) {
                document.getElementById('pagination-more').classList.add('hidden');
            }
        }
    }
});

/**************************************************
 *
 * INFINITY class
 *
 **************************************************/
export var INFINITY = {
    infinityLoading: 0,
    activatedInfinity: 0,
    initPage: 1,
    topPage: 1,
    currentPage: 1,
    bottomPage: 1,
    count: 0,
    total: 0,
    total_icons: 0,
    total_premium: 0,
    axScript: '',
    css_selector: '',
    functionParser: '',
    cache_items: [],
    lastPage: self.currentPage,
    preLoad: true,
    setUrl: true,
    infinityCallback: '',
    fi_sponsor: {},
    total_found_packs: 0,
    word: '',
    urlSendLog: ['ajax/search'],
    loadFollows: 0,
    pages: null,
    ads: null,

    /**
     * INIT
     */

    init: function() {
        if (typeof INFINITY_CONFIG != 'undefined') {
            for (var i in INFINITY_CONFIG) {
                INFINITY[i] = INFINITY_CONFIG[i];
            }
        }
    },

    // -------------------------------------------------------------
    /**
     * Funcion principal
     */
    infinity: function(script, force_page) {
        if (typeof force_page !== 'undefined') {
            this.axLoadItems(script, force_page, true);
        } else {
            this.infinityLoading = 1;
            this.bottomPage = this.bottomPage + 1;
            this.axLoadItems(script, this.bottomPage, true);
        }
    },

    // -------------------------------------------------------------
    /**
     * Comprueba si la pagina de datos esta cacheada
     */
    is_in_cache: function(page) {
        if (this.cache_items.length > 10) this.cache_items.shift();

        for (var i = 0; i < this.cache_items.length; i++) {
            if (this.cache_items[i].page == page) return this.cache_items[i];
        }
        return false;
    },

    // -------------------------------------------------------------
    /**
     * Actualiza el paginador con los totales de los items recibidos
     */
    pagination_update: function() {
        var totalPages = this.pages ? this.pages : Math.ceil(this.total / this.count, 10);
        if (document.getElementById('pagination-total')) {
            document.getElementById('pagination-total').innerHTML = numberFormat(totalPages);
            document.getElementById('pagination-page').value = this.currentPage;
        }

        if (document.getElementById('pagination-more')) {
            document.getElementById('pagination-more').classList.toggle('hidden', this.currentPage == totalPages);
        }

        var badge = document.getElementById('total_icon_badge');
        if (badge) {
            badge.innerHTML = this.total.toLocaleString('en-US');
        }

        if (document.querySelector('.total-search-result h1') && RESOURCE_TYPE != 'uicon') {
            const totalSearchResultH1PageNumber = document.querySelector('.total-search-result h1 span');
            if(totalSearchResultH1PageNumber && this.currentPage <= 1) {
                totalSearchResultH1PageNumber.innerHTML = '';
            } else if(!totalSearchResultH1PageNumber && this.currentPage > 1) {
                document.querySelector('.total-search-result h1').innerHTML += `<span> - ${messages.common.pageTitle} ${this.currentPage}</span>`;
            } else {
                totalSearchResultH1PageNumber.innerHTML = `- ${messages.common.pageTitle} ${this.currentPage}`;
            }
        }
        
        var message = messages.common.icons;


        var badge_pack = document.querySelector('.pack-header .list-header-title .badge');
        if (badge_pack) {
            var txt_icons = '';
            if (badge_pack.innerHTML.indexOf('icon') != -1) txt_icons = message;
            badge_pack.innerHTML = this.total.toLocaleString('en-US') + ' ' + txt_icons;
        }

        var empty_search_element = $_('.empty-search');

        var cache = this.is_in_cache(this.currentPage);

        let relatedTagsElement = document.querySelector('section.relateds-bar');
        let paginationBar = document.querySelector('section.pagination-bar');

        if (empty_search_element && cache) {
            empty_search_element.classList.toggle('hidden', cache.items.length > 0);
            if (this.total == 0) {
                $('.edge-content-iframe').hide();
                if (relatedTagsElement !== null && paginationBar !== null) {
                    relatedTagsElement.classList.add('hidden');
                    paginationBar.classList.add('hidden');
                }
            } else {
                if (relatedTagsElement !== null && paginationBar !== null) {
                    relatedTagsElement.classList.remove('hidden');
                    paginationBar.classList.remove('hidden');
                }
            }
        }

        var $show_carrousel = this.currentPage == 1;

        var packs_related = document.querySelector('.search-pack-result:not(.similar-packs)');
        var packs_related_text = document.querySelector('.search-pack-result-text');
        if (packs_related) {
            packs_related.style.display = $show_carrousel ? '' : 'none';
        }
        if (packs_related_text) {
            packs_related_text.style.display = $show_carrousel ? 'none' : '';
        }
        $(window).trigger('resize');
        Functions.emptyAuthorBox();
    },

    // -------------------------------------------------------------
    /**
     * Llamada de Ajax para cargar datos
     */
    axLoadItems: function(script, page, render) {
        var url = script;

        if (page > 1) {
            url = url + '/' + page;
        }

        var pos = window.location.href.lastIndexOf('?');
        if (pos != -1) {
            url = url + '?' + window.location.href.substring(pos + 1, window.location.href.length);
        }

        var cache = this.is_in_cache(page);

        if (cache && render) {
            this.tplLoadItem(cache.items, page, cache.ads);
            this.pagination_update();
        }

        if (!cache) {
            if (typeof script !== 'undefined' && script != '') {
                if (document.getElementById('group_id')) {
                    var group_id = parseInt(document.getElementById('group_id').value, 10) || 0;
                    if (script == '/ajax/packs/search' && group_id) {
                        url = addVarQueryString(url, 'group_id', group_id);
                    }
                }

                var self = this;
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function(json) {
                        var arrItems = json.items || [];
                        var t = parseInt(json.total, 10) || 0;
                        var pages = parseInt(json.pages, 10) || 0;
                        var packId = json.pack_id;
                        this.total = json.total || 0;
                        this.pages = json.pages || 0;

                        if (json.d_gad) {
                            this.ads = json.d_gad;
                        }

                        if (window.view.section === 'icon-search' && !arrItems.length) {
                            window.location.reload();
                        }

                        var btn_patterns = document.querySelector('.btn-pattern');
                        if (btn_patterns) {
                            var href = btn_patterns.href.split('#');
                            href.pop();
                            href.push('pack=' + packId);
                            btn_patterns.href = href.join('#');
                        }

                        self.cache_items.push({ page: page, items: arrItems, total: t, ads: this.ads });

                        if (render) {
                            self.currentPage = page;
                            self.total = t;
                            self.pages = pages;
                            self.tplLoadItem(arrItems, page, this.ads);
                            self.pagination_update();
                            self.check_pagination_buttons();
                        }

                        EventTracker.send('gtm', 'event', 'pagination');
                        LazyModal.callModalPremium();

                        if (render) {
                            window.scrollTo(0, 0);
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        document.getElementById('pagination-spinner').classList.add('hidden');

                        self.infinityLoading = 0;
                    },
                });
            }
        } else {
            window.scrollTo(0, 0);
        }
    },

    // -------------------------------------------------------------
    /**
     * Llamada de Ajax segun los botones que pulsemos
     */
    infinityLoaderBtn: function(script, next_prev) {
        this.lastPage = this.currentPage;
        var totalPages = this.pages ? this.pages : Math.ceil(this.total / this.count, 10);

        if (next_prev == 'go') {
            var p = parseInt(document.getElementById('pagination-page').value, 10);
            this.infinityLoading = 1;
            this.currentPage = !isNaN(p) && p > 0 && p <= totalPages ? p : 1;
            this.bottomPage = this.currentPage;
            this.initPage = this.currentPage;
            this.axLoadItems(script, this.currentPage, true);
            if (this.preLoad) {
                if (this.currentPage < totalPages) this.axLoadItems(script, this.currentPage + 1, false);
            }
        }

        if (next_prev == 'clear') {
            this.infinityLoading = 1;
            this.currentPage = 1;
            this.initPage = this.currentPage;
            this.bottomPage = this.currentPage;
            this.axLoadItems(script, this.currentPage, true);
            if (this.preLoad) {
                if (this.currentPage < totalPages) this.axLoadItems(script, this.currentPage + 1, false);
            }
        }

        if (next_prev == 'next') {
            this.currentPage++;
            if (this.total > (this.currentPage - 1) * this.count) {
                this.infinityLoading = 1;
                this.bottomPage = this.currentPage;
                this.axLoadItems(script, this.currentPage, true);
                if (this.preLoad) {
                    if (this.currentPage < totalPages) this.axLoadItems(script, this.currentPage + 1, false);
                }
            } else {
                this.infinityLoading = 0;
                this.currentPage--;
            }
        }

        if (next_prev == 'prev') {
            this.currentPage--;
            if (this.currentPage >= 1) {
                this.topPage = this.currentPage;
                this.infinityLoading = 1;
                this.axLoadItems(script, this.currentPage, true);
                if (this.preLoad) {
                    if (this.currentPage > 1) this.axLoadItems(script, this.currentPage - 1, false);
                }
            } else {
                this.infinityLoading = 0;
                this.currentPage = 1;
            }
        }

        if (next_prev == 'first') {
            this.currentPage = 1;
            this.topPage = this.currentPage;
            this.infinityLoading = 1;
            this.axLoadItems(script, this.currentPage, true);
            if (this.preLoad) {
                this.axLoadItems(script, 1, false);
            }
        }

        this.check_pagination_buttons();
        // window.scrollTo(0, 0);
    },

    // -------------------------------------------------------------
    /**
     * Chequeamos los botones de paginacion
     */
    check_pagination_buttons: function() {
        var pagination_first = document.getElementById('pagination-first');
        var pagination_more = document.getElementById('pagination-more');
        var pagination_less = document.getElementById('pagination-less');
        var n_pags = 0;
        var self = this;
        if (document.getElementById('pagination-total'))
            n_pags = parseInt(document.getElementById('pagination-total').innerHTML.replace(',', ''), 10);

        if (document.querySelector('.list-options .pagination-simple'))
            document.querySelector('.list-options .pagination-simple').classList.toggle('hidden', n_pags == 1);

        document.querySelectorAll('.pagination-first').forEach(function(link) {
            link.classList.toggle('disabled', self.currentPage < 3);
            if (pagination_first) {
                pagination_first.classList.toggle('hidden', self.currentPage < 3);
            }
        });

        document.querySelectorAll('.pagination-prev').forEach(function(link) {
            link.classList.toggle('disabled', self.currentPage == 1);
            if (pagination_less) {
                pagination_less.classList.toggle('hidden', self.currentPage == 1);
            }
            if (INFINITY.currentPage > 2) {
                link.setAttribute(
                    'href',
                    `${urlWithoutPag(window.location.href)}/${self.currentPage - 1}${window.location.search}`,
                );
            } else {
                link.setAttribute('href', `${urlWithoutPag(window.location.href)}${window.location.search}`);
            }
        });

        document.querySelectorAll('.pagination-next').forEach(function(link) {
            link.classList.toggle('disabled', self.currentPage == n_pags || n_pags == 0);

            if (INFINITY.currentPage < INFINITY.pages) {
                link.setAttribute(
                    'href',
                    `${urlWithoutPag(window.location.href)}/${self.currentPage + 1}${window.location.search}`,
                );
            }
        });

        var exist_pag_next = document.querySelectorAll('.pagination-next').length;
        var exist_pag_prev = document.querySelectorAll('.pagination-prev').length;

        if (document.get('#pagination-form .pagination-next') && document.get('#pagination-form .pagination-prev')) {
            if (
                document.get('#pagination-form .pagination-next').classList.contains('disabled') &&
                document.get('#pagination-form .pagination-prev').classList.contains('disabled') &&
                n_pags > 1
            ) {
                if (document.querySelector('.pagination-next').classList.contains('disabled')) {
                    if (pagination_more) {
                        pagination_more.classList.add('hidden');
                        pagination_less.classList.remove('hidden');
                    }
                } else {
                    if (pagination_more) {
                        pagination_more.classList.remove('hidden');
                        pagination_less.classList.add('hidden');
                    }
                }
            }
        }

        if (exist_pag_next && exist_pag_prev) {
            if (
                document.get('#pagination-form .pagination-next').classList.contains('disabled') &&
                document.get('#pagination-form .pagination-prev').classList.contains('disabled') &&
                n_pags > 1
            ) {
                document.get('#pagination-form').style.display = 'none';
                if (pagination_more) {
                    pagination_more.classList.add('hidden');
                    pagination_less.classList.remove('hidden');
                }
            } else {
                document.get('#pagination-form').style.display = 'block';
            }
        }
    },

    // -------------------------------------------------------------
    /**
     * Parseamos los datos
     */
    tplLoadItem: function(json, page, ads) {
        this.css_selector =
            this.css_selector == '' || typeof this.css_selector === undefined
                ? 'ul.icons:not(.collection_icons_list)'
                : this.css_selector;

        $(this.css_selector).empty();

        var element = document.get(this.css_selector);

        /** PUBLICIDAD EN PAGINACIÓN DE PACKS */
        if (
            this.axScript.indexOf('pack') !== -1 &&
            !~~gr.auth.me.premium &&
            window.matchMedia('(min-width: 740px)').matches
        ) {
            if (
                document.querySelector('#form-download-pack [name="premium"]') &&
                document.querySelector('#form-download-pack [name="premium"]').value == '0'
            ) {
                var li_gtag = document.createElement('li');
                li_gtag.id = 'bn-icon-list';
                li_gtag.className = 'a-wrapper edge-content edge-content-icon-list edge-content-first';
                li_gtag.style.display = 'none';
                li_gtag.setAttribute('data-order', '1');
                li_gtag.setAttribute('data-row', '2');
                li_gtag.setAttribute('data-min', '45');
            }
        }

        //
        // SPONSOR
        //

        if (typeof this.fi_sponsor.freepik != 'undefined' && !~~gr.auth.me.premium && !ads) {
            // Solo de 1 a 15 paginas de sponsor
            var page_sponsor = page % 14;
            if (page >= 14) page_sponsor++;

            this.fi_sponsor.freepik.page = page_sponsor;
            this.fi_sponsor.partner.page = page_sponsor;

            var li_gtag = document.createElement('li');
            if (li_gtag) {
                li_gtag.id = 'bn-icon-list';
                li_gtag.className = 'bn-space a-wrapper edge-content edge-content-icon-list edge-content-first';
                li_gtag.style.display = 'none';
                li_gtag.setAttribute('data-order', '1');
                li_gtag.setAttribute('data-row', '2');
                li_gtag.setAttribute('data-min', '55');
            }

            var li_gtag_2 = document.createElement('li');

            if (li_gtag_2) {
                li_gtag_2.id = 'bn-icon-list-second';
                li_gtag_2.className =
                    'bn-space a-wrapper edge-content edge-content-icon-list edge-content-first edge-content-icon-list-second';
                li_gtag_2.style.display = 'none';
                li_gtag_2.setAttribute('data-order', '2');
                li_gtag_2.setAttribute('data-row', '6');
                li_gtag_2.setAttribute('data-min', '80');
            }

            var freepik = document.querySelector('section.search-result ul.icons');
            if (freepik) {
                freepik.appendChild(li_gtag);
                freepik.appendChild(li_gtag_2);
            }
            // --------------------------------

            var div_sponsor = document.createElement('div');
            if (div_sponsor) {
                div_sponsor.className = 'edge-content edge-content-first show last-edge-content';
                div_sponsor.innerHTML = this.fi_sponsor.partner.url.replace('---PAGE---', this.fi_sponsor.partner.page);
            }

            var sponsor = document.querySelector('.last-edge-content');
            if (sponsor) {
                sponsor.innerHTML = '';
                sponsor.appendChild(div_sponsor);
            }
        }

        if (document.getElementById('pagination-spinner'))
            document.getElementById('pagination-spinner').classList.add('hidden');
        if (document.getElementById('filters-spinner'))
            document.getElementById('filters-spinner').classList.add('hidden');

        if (element && !isNaN(this.topPage) && !isNaN(this.bottomPage) && json.length > 0) {
            var page = this.currentPage;
            var tpl = Handlebars.tag(this.templateTag);

            var total_counter = $('.total-items');
            if (typeof total_counter != 'undefined' && total_counter != null) {
                $('.total-items').html(this.total.toLocaleString('en-US'));
            }

            json.forEach(function(item, index) {
                if (index == 1) {
                    item.page = page;
                }

                if (ww.view.section === ViewTypes.iconSearch) {
                    if (ww.Resource.resourceConfig) {
                        ww.Resource.setResourceConfig(ww.RESOURCE_TYPE);
                    }

                    const resourceTemplate = require('Project/ts/pages/icon-detail/resource.hbs');

                    const resourceListElement = {
                        ...item,
                        pngSmall: item.png,
                        resourceType: item.iconType,
                        authorIconsText: item.authorIconText,
                        categoryId: item.category_id,
                        iconImg: item.png512,
                        isMobile: isMobile(),
                    };

                    const resourceElement = renderlistResourceComponents(
                        ww.Resource.resourceConfig,
                        resourceListElement,
                    );

                    element.insertAdjacentHTML('beforeend', resourceTemplate({ resourceElement }));
                }

                if (ww.view.section !== ViewTypes.iconSearch) {
                    element.insertAdjacentHTML('beforeend', tpl(item));
                }

                if (index == 100) return false;
            });

            if (this.setUrl) {
                var url_end = Common.getUrlNavigation(
                    Common.urlWithoutPag(window.location.href),
                    this.currentPage,
                    window.location.search,
                );

                if (typeof window.history == 'object')
                    window.history.pushState({ page: 'pagination' }, document.title, url_end);
                else History.pushState({ page: 'pagination' }, document.title, url_end);

                Functions.store_url(url_end);

                var urlClean = Common.urlWithoutPag(window.location.href);
                window.url = urlClean;

                var urlCleanPage = Common.getUrlNavigation(urlClean, page);
                var url_path = urlCleanPage.replace(APP_URL, '');
                ga('send', 'pageview', '/' + url_path);

                ga('send', 'pageview', url_path, {
                    metric1: page,
                    dimension9: Common.parseQueryString(decodeURIComponent(window.location.search))['word'],
                });
            }
            this.setUrl = true;

            if (typeof Widget != 'undefined' && Widget != null) Widget.markSelectedIcons();
            window.Resource.handleResourceListener();
            setTimeout(() => window.Lazyload.run(), 50);

            window.dispatchEvent(new Event('resize'));
        }

        if (typeof this.infinityCallback != 'undefined') {
            if (typeof this.infinityCallback == 'string') {
                this.infinityCallback = this.infinityCallback.split('.');
            }

            if (typeof this.infinityCallback != 'undefined') {
                if (this.infinityCallback instanceof Array) {
                    var method;
                    this.infinityCallback.forEach(function(segment) {
                        method = !method ? window[segment] : method[segment];
                        if (typeof method == 'function') {
                            method();
                            method = null;
                        }
                    });
                }
            }

            this.infinityLoading = 0;
        }
        this.addFakeItemsToList(this.css_selector);

        Premium.check();

        const viewType = document.body.dataset.section || '';
        const sectionViewEvent = new CustomEvent(`sectionView`, {
            detail: {
                section: viewType,
                modal: false,
                page: page,
            },
        });
        window.dispatchEvent(sectionViewEvent);

        if (viewType == 'following-styles' || viewType == 'following-authors') {
            setTimeout(function() {
                Follow.init();
                viewType == 'following-authors' ? Follow.FollowsCallbackInit() : Follow.BookmarkCallbackInit();
            }, 500);
        }

        copyToClipboardHandleListeners();
    },

    serialize: function(o) {
        var s = [];
        for (var p in o) {
            if (o.hasOwnProperty(p)) {
                s.push(encodeURIComponent(p) + '=' + encodeURIComponent(o[p]));
            }
        }
        return s.join('&');
    },
    addFakeItemsToList: function(selector = null) {
        if (null === container) {
            var container = document.querySelector('section.search-result ul.icons');
        } else {
            var container = document.querySelector(selector);
        }

        if (container && container.classList.contains('icons')) {
            var fakeIcon = '<li class="icon--item icon-fill"></li>';
            for (var i = 20; i >= 0; i--) {
                container.insertAdjacentHTML('beforeend', fakeIcon);
            }
        }

        var packContainer = document.querySelector('section.search-result section.box-container');
        if (packContainer) {
            var fakePack = '<article class="box emptybox"></article>';
            for (var i = 20; i >= 0; i--) {
                packContainer.insertAdjacentHTML('beforeend', fakePack);
            }
        }

        var followContainer = document.querySelector('.following__list.bookmarks__list.box-container');
        if (followContainer) {
            var fakePack = '<article class="box emptybox"></article>';
            for (var i = 4; i >= 0; i--) {
                followContainer.insertAdjacentHTML('beforeend', fakePack);
            }
        }
    },
};
