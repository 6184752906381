import { Cookie } from 'bobjoll/ts/library/cookie';
import getXHR from 'Partials/xhr';

import * as Functions from './functions';

// Auth by cookie

export function logged_in_callback(data) {
    let loggedInCallbackExecuted = false;
    if (typeof Collection == 'function') Collection.init();

    if (typeof ga_logged_in_callback == 'function') {
        ga_logged_in_callback(data);
    }

    if (data.status) {
        localStorage.fi_user = JSON.stringify(data);
    } else {
        const downs_pack = document.querySelectorAll('.btn-download-pack');
        [].forEach.call(downs_pack, function(down_pack) {
            down_pack.disabled = true;
            down_pack.removeAttribute('data-pack');
        });
    }

    clearTimeout('');

    UserSession.userInfo.session.id = ~~UserSession.userInfo.session.id;
    UserSession.userInfo.session.premium = ~~UserSession.userInfo.session.premium;

    if (UserSession.userInfo.session.premium) {
        if (typeof cc_will_expire == 'function') {
            notify.addCustom({
                id: 'cc_will_expire',
                priority: '5',
                showPremium: true,
                expires: function() {
                    return new Date(new Date().getTime() + 1 * 60 * 60 * 1000);
                },
                closeCallback: function(callback) {
                    const notification = document.getElementById('user-alert-container');
                    if (notification) {
                        const notificationCloseElement = notification.querySelector('button');
                        if (notificationCloseElement) {
                            notificationCloseElement.addEventListener('click', callback);
                        }
                    }
                },
                showCallback: function() {
                    return 1 == data.cc_will_expire;
                },
                callback: function() {
                    cc_will_expire(data.cc_will_expire);
                },
            });
        }
    }

    for (var key in UserSession.userInfo.session) {
        if (UserSession.userInfo.session.hasOwnProperty(key)) {
            if (!(Object.defineProperty instanceof Function)) {
                return false;
            }
            Object.defineProperty(UserSession.userInfo.session, key, {
                value: UserSession.userInfo.session[key],
                writable: false,
                configurable: false,
                enumerable: false,
            });
        }
    }

    if (typeof notify == 'object' && !loggedInCallbackExecuted && URL_CAMPAIGN == '') {
        loggedInCallbackExecuted = true;
        notify.printQueue();
    }
}

function cc_will_expire(expire) {
    if (expire) {
        const msg1 = typeof messages.common.cc_exp_title == 'string' ? messages.common.cc_exp_title : 'Attention!';
        const msg2 =
            typeof messages.common.cc_exp_desc == 'string'
                ? messages.common.cc_exp_desc
                : 'Your credit card is about to expire, please update your credit card and continue with your premium plan';
        if (typeof UserAlerts != 'undefined') {
            UserAlerts.warning(msg1, msg2, true);
        }
    }
}

function ga_logged_in_callback(data) {
    const userType = !data.status ? 'guest' : data.premium ? 'premium' : 'registered';
    ga('set', 'dimension1', userType);
    if (data.id) {
        ga('set', 'userId', data.id);
    }
}

export var Auth = {
    error: function(data) {
        localStorage.removeItem('fi_user');
        if (typeof UserAlerts != 'undefined') {
            UserAlerts.warning(data.title, data.body);
        }
        setTimeout(gr.auth.logout, 3000);
    },
    logout: function() {
        localStorage.removeItem('fi_user');
        const url = APP_URL + 'logout';

        getXHR(url, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json',
            },
            withCredentials: true,
        }).then(() => {
            gr.auth.logout();
            Cookie.removeItem('g_state');
        });
        // 	if (window.location.pathname == '/home') { window.location.href = APP_URL;}
    },
};

window.addEventListener('userSessionLoaded', () => {
    const logoutLink = document.getElementById('logout_link');
    const typesAuthData = {
        function: 'function',
        string: 'string',
    };

    if (logoutLink) {
        logoutLink.addEventListener('click', function(e) {
            e.preventDefault();
        });
    }
});
