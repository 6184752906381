import { cookie } from 'Library/cookie';
import { isAdobePopupBannedCountry } from 'Project/ts/common-app/sponsor/adobe/adobe.helper';
import {
    getAdobePopup,
    printAdobeCustomPixel,
} from 'Project/ts/common-app/sponsor/adobe/adobe.popup.service';
import { isShutterstockPopupBannedCountry } from 'Project/ts/common-app/sponsor/shutter/shutterstock.helper';
import {
    getShutterStockPopup,
    printShutterStockCustomPixel,
} from 'Project/ts/common-app/sponsor/shutter/shutterstock.popup.service';

import { isIstockPopupBannedCountry } from 'Project/ts/common-app/sponsor/istock/istock.helper';
import {
    getIstockPopup,
    printIstockCustomPixel,
} from 'Project/ts/common-app/sponsor/istock/istock.popup.service';

window.addEventListener('userSessionLoaded', () => {
    initSponsor();
});
if (window.gr) {
    initSponsor();
}

function initSponsor() {
    window.pu = function(pu_url) {
        const pu_width = $(window).width() - 200;
        const pu_height = $(window).height() - 50;
        let b = 'toolbar=0,statusbar=1,resizable=1,scrollbars=0,menubar=0,location=1,directories=0';

        if (navigator.userAgent.indexOf('Chrome') != -1) b = 'scrollbar=yes';

        let a = window.open('about:blank', '', b + ',height=' + pu_height + ',width=' + pu_width);

        if (navigator.userAgent.indexOf('rv:2.') != -1) {
            a.puPop = function(c) {
                if (navigator.userAgent.indexOf('rv:2.') != -1) this.window.open('about:blank').close();
                this.document.location.href = c;
            };

            a.puPop(pu_url);
        } else a.document.location.href = pu_url;

        setTimeout(window.focus, 200);
        window.focus();

        if (a) {
            a.moveTo(window.screenX + 2, window.screenY + 2);
            a.blur();
            self.focus();
        } else {
            done_pu = null;
            ifSP2 = false;
            if (typeof pu_window == 'undefined') pu_window = false;

            if (window.SymRealWinOpen) open = SymRealWinOpen;
            if (window.NS_ActualOpen) open = NS_ActualOpen;

            ifSP2 = navigator.userAgent.indexOf('SV1') != -1;

            if (!ifSP2) do_pu();
            else {
                if (window.Event) document.captureEvents(Event.CLICK);

                document.onclick = do_clicked_pu;
            }

            self.focus();
            do_clicked_pu();
        }

        return this;
    };

    gr.sponsor = {
        base_url: SPONSOR_BASE_URL,
        popup: {
            cookie_name: 'launcher_popup',
            url: typeof pu_url != 'undefined' ? pu_url : '',
            launch: function(locationPopup) {
                if (!cookie.getItem(gr.sponsor.popup.cookie_name) && !isMobile && RESOURCE_TYPE == 'standard' && !window.FeatureFlag.isEnabled('disable_affiliate_by_country')) {
                    if (window.FeatureFlag.isEnabled('sponsor_istock') && !isIstockPopupBannedCountry()) {
                        getIstockPopup().then(response => {
                            if (!response) return false;

                            pu(response.url);
                            printIstockCustomPixel(response.trackerClickCustom);

                            let exdate = new Date();
                            exdate.setHours(exdate.getHours() + 12);

                            document.cookie = `${
                                gr.sponsor.popup.cookie_name
                            }=1;expires=${exdate.toUTCString()};domain=${location.hostname};path=/`;
                        });
                    }

                    if (window.FeatureFlag.isEnabled('sponsor_shutterstock') && !isShutterstockPopupBannedCountry()) {
                        getShutterStockPopup().then(response => {
                            if (!response) return false;

                            pu(response.url);
                            printShutterStockCustomPixel(response.trackerClickCustom);

                            let exdate = new Date();
                            exdate.setHours(exdate.getHours() + 12);

                            document.cookie = `${
                                gr.sponsor.popup.cookie_name
                            }=1;expires=${exdate.toUTCString()};domain=${location.hostname};path=/`;
                        });
                    }
                }
            },
        },
        coupon: {
            cookie_name: 'sponsor_coupon',
            coupon_url:
                typeof SPONSOR_BASE_URL != 'undefined' && typeof LANGUAGE != 'undefined'
                    ? SPONSOR_BASE_URL +
                      'coupons/popup?lang=' +
                      LANGUAGE +
                      '&k=' +
                      new Date().toISOString().substr(0, 10)
                    : 'http://ads.freepik.com/coupons/popup?lang=&k=' + new Date().toISOString().substr(0, 10),
            launch: function(fn) {
                if (typeof disallow_coupon != 'undefined') return false;

                $.get(gr.sponsor.coupon.coupon_url, function(data) {
                    $('#footer').after(data);

                    EventTracker.printGA({
                        type: 'imp',
                        section: 'stockbanner',
                    });

                    if ('function' === typeof fn) {
                        fn();
                    }
                });
            },
        },

        get: function(params) {
            if (typeof params == 'undefined') params = {};
            if (typeof params.container == 'undefined') return false;

            gr.request.get(gr.sponsor.base_url + 'banner/ajax_search', params);
        },
    };
}
