import { ModalDetail } from './modal_detail_history';

window.addEventListener('userSessionLoaded', () => {
    try {
        localStorage.n_pageviews =
            typeof localStorage.n_pageviews != 'undefined' ? parseInt(localStorage.n_pageviews, 10) + 1 : 1;
    } catch (e) {
        // No se queja
    }
});

/**
 * Default AJAX ERROR
 */

$(document).ajaxError(function(e, jqxhr, settings) {
    if (!~settings.url.indexOf('coupons/popup')) {
        UserAlerts.danger('Oops!', messages.errors.connectivity);
    }
});

/*
 * Helpers
 */

if (!String.prototype.format) {
    String.prototype.format = function() {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function(match, number) {
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    };
}

export function $_(s) {
    var s = document.querySelectorAll(s);
    return s.length > 1 ? s : s[0] ? s[0] : null;
}

document.get = function(s) {
    return document.querySelector(s);
};

document.find = function(s) {
    return document.querySelectorAll(s);
};

Element.prototype.get = function(s) {
    return this.querySelector(s);
};

Element.prototype.find = function(s) {
    return this.querySelectorAll(s);
};

Element.prototype.parents = function(s) {
    var l = [],
        e = this;
    if (s) {
        var t = s.charAt(0);
    }
    for (var q = 0; e && e !== document && !q; e = e.parentNode) {
        if (s) {
            if (t === '.' && e.classList.contains(s.substr(1))) q = 1;
            if (t === '#' && e.id === s.substr(1)) q = 1;
            if (t === '[' && e.hasAttribute(s.substr(1, s.length - 1))) q = 1;
            if (e.tagName.toLowerCase() === s) q = 1;
        }
        l.push(e);
    }
    return !l.length || (l.reverse()[0].tagName == 'HTML' && s) ? [] : l;
};

Element.prototype.parent = function(s) {
    var list = this.parents(s);
    if (typeof s == 'undefined') list.reverse();
    return list && list.length ? list[0] : null;
};

Element.prototype.outerWidth = function() {
    var width, margin;

    width = this.clientWidth;
    margin = parseInt(getComputedStyle(this).marginLeft, 10) + parseInt(getComputedStyle(this).marginRight, 10);

    return width + margin;
};

Element.prototype.outerHeight = function() {
    var height, margin;

    height = this.clientHeight;
    margin = parseInt(getComputedStyle(this).marginTop, 10) + parseInt(getComputedStyle(this).marginBottom, 10);

    return height + margin;
};

Handlebars.tag = function(tag) {
    return Handlebars.compile(document.getElementById('tpl-' + tag).innerHTML);
};

Handlebars.registerHelper('isColor', function(color, options) {
    return color != 'XXXXXX' ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('specialItem', function(premium, selection, options) {
    return premium == 1 || selection == 1 ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('get_length', function(obj) {
    if ('object' === typeof obj) {
        return Object.keys(obj).length;
    } else if (Array.isArray(obj)) {
        return obj.length;
    }

    return 0;
});

Handlebars.registerHelper('retinaImage', function() {
    if (!(Object.keys(this).length === 0 && this.constructor === Object)) {
        return this.png.replace('png/128', 'png/512');
    }
});

Handlebars.registerHelper('isPersonalIcon', function(premium) {
    return premium == 4;
});

Handlebars.registerHelper('numberFormat', function(num) {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //return num.toLocaleString('en-US');
});
Handlebars.registerHelper('isEqualTo', function(v, value, opts) {
    if (v == value) return opts.fn(this);
    else return opts.inverse(this);
});
Handlebars.registerHelper('compare', function(lvalue, operator, rvalue, options) {
    var operators, result;

    if (arguments.length < 3) {
        throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
    }

    if (options === undefined) {
        options = rvalue;
        rvalue = operator;
        operator = '===';
    }

    operators = {
        '==': function(l, r) {
            return l == r;
        },
        '===': function(l, r) {
            return l === r;
        },
        '!=': function(l, r) {
            return l != r;
        },
        '!==': function(l, r) {
            return l !== r;
        },
        '<': function(l, r) {
            return l < r;
        },
        '>': function(l, r) {
            return l > r;
        },
        '<=': function(l, r) {
            return l <= r;
        },
        '>=': function(l, r) {
            return l >= r;
        },
        typeof: function(l, r) {
            return typeof l == r;
        },
    };

    if (!operators[operator]) {
        throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);
    }

    result = operators[operator](lvalue, rvalue);

    if (result) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

/**
 * Alerts
 */
export function Notifier() {
    this.warning = function(title, body, sticky) {
        _notify(title, body, sticky, 'warning');
    };
    this.danger = function(title, body, sticky) {
        _notify(title, body, sticky, 'danger');
    };
    this.success = function(title, body, sticky) {
        _notify(title, body, sticky, 'success');
    };

    var timeout = 8000;

    function _notify(title, body, sticky, type) {
        _displayCustomNotification(title, body, sticky, type);
    }

    function _displayCustomNotification(title, body, sticky, type) {
        var time = Date.now();

        if (title instanceof Object) {
            body = title.body;
            title = title.title;
        }

        var container = document.getElementById('user-alert-container');
        //if (!container) return false;
        if (!container) {
            container = document.createElement('div');
            container.id = 'user-alert-container';
            container.className = 'user-alert-container';
            document.body.appendChild(container);
        }

        container.innerHTML = Handlebars.tag('notification')({
            title: title,
            body: body,
            sticky: sticky,
            type: type,
            time: time,
        });

        if (!sticky) {
            setTimeout(function() {
                var notification = document.getElementById('notify-' + time);
                if (notification) {
                    notification.parentElement.removeChild(notification);
                }
            }, timeout);
        }
    }
}

Notifier.close = function(element) {
    var notification = document.getElementById('notify-' + element.dataset.time);
    notification.parentElement.removeChild(notification);
};

export var UserAlerts = new Notifier();
window.UserAlerts = UserAlerts;

try {
    sessionStorage.setItem('test', '1');
    sessionStorage.removeItem('test');
} catch (error) {
    UserAlerts.danger(
        'ERROR!',
        "Your browser doesn't support Storage Feature or you are using private browsing. Please use a compatible browser",
        true,
    );
}

// ====================================================================================

export function filter_text(str) {
    if (typeof str != 'undefined') {
        str = str.replace(/^\s+|\s+$|\(\)\'/g, '').toLowerCase();
        var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/,:;';
        var to = 'aaaaaeeeeeiiiiooooouuuunc-----';
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        return str
            .replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
    }
    return str;
}

export function isHangulWord(str) {
    var match = str.match(/[\u3131-\uD79D]/giu);
    return match ? match.length === str.length : false;
}

export function isCyrillicWord(str) {
    return /^[\u0400-\u04FF ]+$/.test(str);
}

export function slug(str) {
    if (typeof str != 'string') return str;

    str = str.replace(/^\s+|\s+$/g, '').toLowerCase();
    var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeeiiiiooooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return str
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
}

export function sanitize_filename(str) {
    var d = new Date();
    var n = d.getTime();

    str = str.split('.').join(n);

    var new_str = slug(str);
    new_str = new_str.split(n).join('.');

    return new_str;
}

export function detectBrowser() {
    if (navigator.userAgent.indexOf('Chrome') != -1) {
        return 'Chrome';
    } else if (navigator.userAgent.indexOf('Opera') != -1) {
        return 'Opera';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
        return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') != -1 || !!document.documentMode == true) {
        //IF IE > 10
        return 'IE';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
        return 'Edge';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
        var browserVersion = navigator.userAgent;
        if (null !== browserVersion) {
            return 'Safari';
        }
        browserVersion = browserVersion.match(/Version\/\d./g);
        browserVersion = browserVersion[0].split('/');
        browserVersion = browserVersion[1];
        if (browserVersion >= 13) {
            return 'UpdatedSafari';
        } else {
            return 'Safari';
        }
    } else {
        return navigator.userAgent;
    }
}

export var Cookie = {
    get: function(cookie_name) {
        var name = cookie_name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return '';
    },

    set: function(name, value, days) {
        var domain = window.location.host.replace(/[^.]+\./, '');
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            var expires = '; expires=' + date.toGMTString();
        } else var expires = '';
        document.cookie = name + '=' + value + expires + '; path=/; domain=.' + domain;
    },

    setH: function(name, value, hours) {
        var domain = window.location.host.replace(/[^.]+\./, '');
        if (hours) {
            var date = new Date();
            date.setTime(date.getTime() + hours * 60 * 60 * 1000);
            var expires = '; expires=' + date.toGMTString();
        } else var expires = '';
        document.cookie = name + '=' + value + expires + '; path=/; domain=.' + domain;
    },

    del: function(name) {
        var domain = window.location.host.replace(/[^.]+\./, '') || window.location.host;
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.' + domain;
    },

    expiry: function(cookie_name) {
        return Cookie.get(cookie_name);
    },
};

//---------------------------------------

export function closeModal(elem) {
    if (elem.parent('.fi-modal')) {
        elem.parent('.fi-modal').get('.modal-state').checked = false;
        document.querySelector('body').classList.remove('modal-open');
    }
}

//---------------------------------------

/** Helpers **/

export function getTimestamp(date) {
    if (typeof date == 'undefined') {
        return Math.floor(Date.now() / 1000);
    }

    if (date instanceof Date) {
        return convertToUTC(date);
    } else {
        var now = new Date(date);
        if (now instanceof Date) {
            return convertToUTC(now);
        } else {
            return Math.floor(Date.now() / 1000);
        }
    }

    function convertToUTC(date) {
        var now_utc = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
        );
        return parseInt(
            Date.parse(now_utc)
                .toString()
                .slice(0, 10),
            10,
        );
    }
}

export function capitalize(word) {
    if (word != '' && typeof word == 'string') {
        return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
        return word;
    }
}

//-------------------------------------------

function detectPagination(url) {
    var pag = 0;
    var init = url.lastIndexOf('/');
    var end = url.length;

    if (url.lastIndexOf('?') != -1) {
        end = url.lastIndexOf('?');
    }

    if (init != -1) {
        pag = url.substring(init + 1, end);
    }

    return isNaN(pag) ? 0 : pag;
}

//-------------------------------------------

export function urlWithoutPag(url) {
    var pag = detectPagination(url);
    var out = null;
    if (pag != 0) {
        out = url.substring(0, url.lastIndexOf('/'));
    } else {
        var qs = window.location.search;
        if (qs != '' && url == window.location.href) {
            out = url.substring(0, url.lastIndexOf('?'));
        } else {
            out = url;
        }
    }
    return out;
}

function addVarQueryString(url, name, value) {
    var out = url;
    if (url.lastIndexOf('?') == -1) {
        out = url + '?' + name + '=' + value;
    } else {
        out = url + '&' + name + '=' + value;
    }
    return out;
}

//-------------------------------------------

export function getUrlNavigation(url, pag, query) {
    // Remove trailing '/' characters
    var nStrip = 0;
    while (url.length > nStrip && url[url.length - (nStrip + 1)] == '/') {
        nStrip += 1;
    }
    if (nStrip > 0) {
        url = url.slice(0, -nStrip);
    }

    if (pag != '' && typeof pag !== 'undefined' && pag > 1) {
        url = url + '/' + pag;
    }

    if (query != '' && typeof query !== 'undefined') {
        // limpiar la query de dobles &&, & finales y valores vacios
        query = cleanQueryString(query);
        url = url.replace('///$/', '/');
        url = url.replace('#', '');
        url = query.lastIndexOf('?') == -1 ? url + '?' + query : url + query;
    }
    return url;
}

export function cleanQueryString(query) {
    if (query != '' && typeof query !== 'undefined') {
        // limpiamos los parametros de style y pack
        // query = query.replace(/pack_id=(\d)*/g, "").replace(/style_id=(\d)*/g, "");
        query = query.replace(/pack_id=(\d)*/g, '');
        // limpiar la query de dobles &&, & finales y valores vacios
        query = query
            .replace('&&', '&')
            .replace(/[^=&]+=(&|$)/g, '')
            .replace(/^&|&$/, '');
    }
    return query;
}

//-------------------------------------------

export function setUrlNavigator(url, pag, query) {
    var url_end = getUrlNavigation(url, pag, query);

    if ('history' in window) {
        if (typeof window.history.pushState == 'function') {
            //  window.history.pushState('', document.title, url_end);

            if (url_end.indexOf('undefined') == -1) ModalDetail.pushState({ page: 'other' }, document.title, url_end);
            else ModalDetail.pushState({ page: 'other' }, document.title, APP_URL);
        }
    }
}

export function parseQueryString(a) {
    a = a.substr(1).split('&');
    if (a == '') return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=', 2);
        if (p.length == 1) b[p[0]] = '';
        else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
    }
    return b;
}

export function filterSpecialChars(text) {
    return text.replace(/[\*\%\?\-\+\/\}\{_\:\,;\.<>\&\#\"@\+\=\~]+/g, '');
}

/**
 * Countdown
 */
/**
 * Countdown
 */
function formatTimer(distance, format) {
    var html = document.createElement('ul');
    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;

    var days = Math.floor(distance / _day);
    var hours = Math.floor((distance % _day) / _hour);
    var minutes = Math.floor((distance % _hour) / _minute);
    var seconds = Math.floor((distance % _minute) / _second);

    if (format) {
        if (days > 0) html.insertAdjacentHTML('beforeend', formatTimerHTML('days', days));

        html.insertAdjacentHTML('beforeend', formatTimerHTML('hours', hours));
        html.insertAdjacentHTML('beforeend', formatTimerHTML('minutes', minutes));
        html.insertAdjacentHTML('beforeend', formatTimerHTML('seconds', seconds));

        return html;
    } else {
        var dateString = normalizeTimer(minutes) + ':' + normalizeTimer(seconds);

        if (hours > 0) {
            dateString = normalizeTimer(hours) + ':' + dateString;
        }

        if (days > 0) {
            dateString = normalizeTimer(days) + ':' + dateString;
        }

        return dateString;
    }
}

function formatTimerTotal(dateStart, dateEnd, timerTotal) {
    var dateCurrent = new Date();
    var timeTotal = dateEnd.getTime() - dateStart.getTime();
    var timeLeft = dateEnd.getTime() - dateCurrent.getTime();
    var total = Math.round(Math.abs((timeLeft / timeTotal) * timerTotal))
        .toString()
        .split('');
    var html = '';

    if (timeLeft <= 0 || !(dateCurrent >= dateStart && dateCurrent <= dateEnd)) {
        return '0000'.toString().split('');
    }

    if (total.length < timerTotal.toString().length) {
        var loop = timerTotal.toString().length - total.length;
        for (var i = 0; i < loop; i++) {
            total.unshift('0');
        }
    }

    total.forEach(function(number) {
        html += '<li>' + number + '</li>';
    });

    return '<ul>' + html + '</ul>';
}

function formatTimerHTML(key, value) {
    var html = '';
    var translations = {
        days: 'days',
        hours: 'hours',
        minutes: 'min',
        seconds: 'sec',
    };

    html += '<li class="countdown__' + key + '">';
    html += '<span class="countdown__value">' + value + '</span>';
    html += '<span class="countdown__description">' + translations[key] || key + '</span>';
    html += '</li>';

    return html;
}

function setCountdown(popup, popupModalInterval, startDate, endDate, format) {
    var currentDate = new Date();

    if (
        checkNotifyDebug() ||
        (currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime())
    ) {
        var popupModalContdown = popup.querySelector('.modal-popup-countdown');

        if (popupModalContdown) {
            var timeleft = endDate - currentDate;

            popupModalContdown.innerHTML = '';
            popupModalContdown.append(formatTimer(timeleft, format));
        }
    } else {
        $(popup).removeClass('show');
    }
}

function normalizeTimer(number) {
    if (number < 10) {
        return '0' + number;
    }

    return number;
}

function checkNotifyDebug() {
    return 'debug' === (Cookie.get('notify') || '') ? true : false;
}

Date.prototype.getWeekNumber = function() {
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;

    d.setUTCDate(d.getUTCDate() + 4 - dayNum);

    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};
