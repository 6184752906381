import { isHashedUrl, isResourcePage, modalIsOpened } from 'CommonFI/reloadAsyncPage.helpers';
import { store_url as storeUrl } from 'ProjectOld/js/functions';

import { toggleDetailButton } from './collections';
import { INFINITY } from './infinity';
import { ModalDetail } from './modal_detail_history';

/****
 *
 *   Gestion de la navegación con la clase History.js.
 *   Tiene en cuenta la paginación de los listados y los detalles
 *   de los iconos en el modal.
 *
 */
$(function() {
    $(window).on('load', function() {
        setTimeout(function() {
            $(window).on('popstate', function(e) {
                if (isHashedUrl()) {
                    return;
                }

                if (modalIsOpened() && !isResourcePage()) {
                    e.stopPropagation();
                    Detail.close();
                    ModalDetail.close();
                }
            });
        }, 0);
    });

    // keypress
    $(document).on('keydown', function(e) {
        var inputs = document.querySelectorAll(
            '.collection .collection-content.edit-mode .icons .collectionIcon .edit .collection-icon-rename, .collection .collection-header .edit-name.active .collection-name-input, .detail__editor input',
        );
        var focused = [].some.call(inputs, function(e) {
            return e == document.activeElement;
        });

        if (!focused) {
            // Cursor navigation modal
            if (document.querySelector('#detail-overlay.loading')) {
                if (e.keyCode == 27) {
                    //ModalDetail.close();
                    Detail.close();
                    ModalDetail.pushState({ page: 'pagination' }, document.title, ModalDetail.prev_url);
                    storeUrl(ModalDetail.prev_url);
                }
                if (e.keyCode == 39) {
                    ModalDetail.getNextElement(ModalDetail.id);
                }
                if (e.keyCode == 37) {
                    ModalDetail.getPrevElement(ModalDetail.id);
                }
                if (e.keyCode == 13) {
                    window.location.href = window.location.href;
                }
            } else {
                // Cursor navigation pagination
                if (document.getElementById('pagination-page') && typeof axScript !== 'undefined') {
                    if (e.keyCode == 39) {
                        //e.preventDefault();
                        INFINITY.infinityLoaderBtn(axScript, 'next');
                    }
                    //Prev
                    if (e.keyCode == 37) {
                        //e.preventDefault();
                        INFINITY.infinityLoaderBtn(axScript, 'prev');
                    }
                }
            }

            // Add and delete from collection
            if (document.getElementById('download-form')) {
                var iconId = parseInt(document.getElementById('icon_id').value, 10);
                var iconName = document.getElementById('keyword').value;
                var iconTeam = parseInt(document.getElementById('team').value, 10);
                var iconColor = parseInt(document.getElementById('colored').value, 10);

                if (!(window.db instanceof Object)) {
                    return;
                }
                var collection = Collection.getActive();
                if (!collection) return;
                var stored = window.db.queryAll(collection.bookmark_id, {
                    query: {
                        id: iconId,
                    },
                }).length;

                // Add Icon
                if (e.keyCode == 107 || e.keyCode == 187 || e.keyCode == 171) {
                    if (stored) return;
                    Widget.addIcon(iconId, iconName, iconTeam, iconColor);
                }

                // Del Icon
                if (e.keyCode == 109 || e.keyCode == 189 || e.keyCode == 173) {
                    if (!stored) return;
                    Widget.delIcon(iconId, iconName, iconTeam);
                }
            }
        }
    });

    if (typeof toggleDetailButton == 'function') toggleDetailButton();
});

var History = window.History;
