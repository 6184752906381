import 'hammerjs/hammer.min.js';

import { q, ww } from 'bobjoll/ts/library/dom';
import { resourceTypes } from 'CommonApp/resource/resourceConfigService';
import { suspendReloadAsyncPage } from 'CommonFI/reloadAsyncPage.helpers';
import { Premium } from 'Project/ts/partials/Premium';

import { toggleDetailButton } from './collections';
import { detectBrowser } from './common';
import { store_url } from './functions';

$(function() {
    if (detectBrowser() != 'IE') {
        $('#detail-overlay').on('click', function(e) {
            if (e.target.parentNode.classList.contains('loading')) {
                suspendReloadAsyncPage();
                history.go(-ModalDetail.viewedIcons);
            }
        });

        // Close modal
        $(window).on('load', function() {
            document.getElementById('detail-close').classList.remove('hide');

            $('#detail-close').on('click', function(e) {
                e.preventDefault();
                ww.view.setView(document.body.dataset.section || '');
                suspendReloadAsyncPage();
                history.go(-ModalDetail.viewedIcons);
                store_url(ModalDetail.prev_url);
            });
        });

        // Close modal mobile
        $(window).on('load', function() {
            $(document).on('click', '#detail-close-mobile, #detail-overlay #detail-content', function(e) {
                if (e.target !== this) return;
                e.stopPropagation();
                e.preventDefault();
                ww.view.setView(document.body.dataset.section || '');
                suspendReloadAsyncPage();
                history.go(-ModalDetail.viewedIcons);
                store_url(ModalDetail.prev_url);
            });
        });

        // Click prev icon
        $(document).on('click', '#detail-overlay #detail-prev', function(e) {
            e.preventDefault();
            ModalDetail.goPrev();
        });

        // Click prev icon mobile
        $(document).on('click', '#detail-prev-mobile', function(e) {
            e.preventDefault();
            ModalDetail.goPrev();
        });

        // Click next icon
        $(document).on('click', '#detail-overlay #detail-next', function(e) {
            e.preventDefault();
            ModalDetail.goNext();
        });

        // Click next icon mobile
        $(document).on('click', '#detail-next-mobile', function(e) {
            e.preventDefault();
            ModalDetail.goNext();
        });

        $(document).on('click', '.collectionIcon .link-icon-detail', function(e) {
            e.preventDefault();
            e.stopPropagation();
            document.location = this.href;
        });

        // Open modal
        $(document).on('click', '.link-icon-detail', function(e) {
            const detaiElement = e.target.closest('li.icon--item');

            if (ww.Resource.resource && ww.Resource.resource.type) {
                ww.Resource.setResourceConfig(ww.Resource.resource.type);
            }

            if (!document.querySelector('#detail.loaded')) {
                e.preventDefault();
                let id = this.dataset.id;
                if (!ModalDetail.id) {
                    ModalDetail.prev_url = document.location.href;
                    ModalDetail.saveOGData();
                }

                let url = this.href || '';
                let completeUrlWithParams = `${url}${this.dataset.src}`;
                let urlParams = new URLSearchParams(new URL(completeUrlWithParams).search);
                let origin = urlParams.get('origin');

                if (!urlParams.get('related_id')) {
                    urlParams.append('related_id', id);
                }

                if (!origin) {
                    if (location.pathname.indexOf(messages.url.search) != -1) {
                        if (location.href.indexOf('style_id') != -1) {
                            urlParams.append('origin', 'style');
                        } else if (location.pathname.indexOf('/style/') != -1) {
                            urlParams.append('origin', 'style-search');
                        } else {
                            urlParams.append('origin', 'search');
                        }
                    }
                    if (location.pathname.indexOf(messages.url.free_icons) != -1) {
                        urlParams.append('origin', 'tag');
                    }
                    if (location.pathname.indexOf(messages.url.packs) != -1) {
                        urlParams.append('origin', 'pack');
                    }
                }

                if (urlParams.get('word')) {
                    urlParams.delete('word');
                }

                url = `${url}${url.indexOf('?') != -1 ? '&' : '?'}${urlParams.toString()}`;

                if (id && url) {
                    let container = document.getElementById('detail-overlay');
                    container.classList.remove('hidden');
                    container.classList.add('loading');
                    const spinner = document.getElementById('detail-spinner');
                    if (spinner) {
                        spinner.classList.remove('hidden');
                    }

                    if (ModalDetail.id) {
                        ModalDetail.hideNavigation();
                    }

                    ModalDetail.id = id;

                    let editDirectly = e.currentTarget.dataset.edit ? true : false;
                    ModalDetail.replaceState(
                        { ...history.state, openModal: true },
                        document.title,
                        document.location.url,
                    );
                    ModalDetail.pushState({ page: 'icon' }, document.title, url);
                    ModalDetail.viewedIcons++;

                    Detail.getModalDetail(detaiElement, editDirectly).then(function() {
                        Premium.check();

                        if (typeof toggleDetailButton == 'function') {
                            toggleDetailButton();
                        }

                        let dataNext = ModalDetail.getNext(ModalDetail.id);
                        ModalDetail.checkNavigation('next', dataNext.url);
                        let dataPrev = ModalDetail.getPrev(ModalDetail.id);
                        ModalDetail.checkNavigation('prev', dataPrev.url);

                        ModalDetail.updateOGData(detaiElement.dataset.premium, detaiElement.dataset.icon_type);

                        $('#detail-wrapper').animate({ scrollTop: 0 }, 'slow');
                        if (!Detail.firstView) {
                            ModalDetail.rendered = true;
                        }
                    });

                    return;
                }
            }
        });

        function listenModalOpen() {
            let observerTarget = q('#detail-overlay');

            let observerOptions = {
                attributes: true,
            };

            let observer = new MutationObserver(mutationCallback);

            function mutationCallback(mutations) {
                if (mutations[0].target.classList.contains('ready')) {
                    setTimeout(() => {
                        let swiperModal = q('.detail__icon__inner');
                        let prevButton = q('#detail-prev-mobile');
                        let nextButton = q('#detail-next-mobile');

                        let hammer = new Hammer(swiperModal);
                        hammer.get('swipe').set({ direction: Hammer.DIRECTION_ALL });

                        hammer.on('swipeleft swiperight', function(ev) {
                            if (ev.type === 'swipeleft' && !nextButton.classList.contains('hidden')) {
                                ModalDetail.goNext();
                            } else if (ev.type === 'swiperight' && !prevButton.classList.contains('hidden')) {
                                ModalDetail.goPrev();
                            }
                        });
                    }, 100);
                }
            }

            observer.observe(observerTarget, observerOptions);
        }

        if (window.innerWidth <= 640) {
            listenModalOpen();
        }
    }
});

export var ModalDetail = {
    id: 0,
    navigation: 1,
    prev_url: document.location.href,
    viewedIcons: 0,
    og_keys: [
        'twitter:title',
        'twitter:description',
        'twitter:image',
        'og:title',
        'og:url',
        'og:description',
        'og:image',
        'pinterest:title',
        'pinterest:description',
        'pinterest:image',
    ],
    og_data: {},
    rendered: false,

    checkNavigation: function(direction, url) {
        if (direction == 'next') {
            document.getElementById('detail-next').classList.toggle('hidden', !url);
            document.querySelector('#detail-next-mobile').classList.toggle('hidden', !url);
        }

        if (direction == 'prev') {
            document.getElementById('detail-prev').classList.toggle('hidden', !url);
            document.querySelector('#detail-prev-mobile').classList.toggle('hidden', !url);
        }
    },

    hideNavigation: function() {
        document.getElementById('detail-next').classList.add('hidden');
        document.getElementById('detail-prev').classList.add('hidden');
        let elementDetailNext = document.querySelector('#detail-next-mobile');
        let elementDetailPrev = document.querySelector('#detail-prev-mobile');

        elementDetailNext ? elementDetailNext.classList.add('hidden') : '';
        elementDetailPrev ? elementDetailPrev.classList.add('hidden') : '';
        this.navigation = 0;
    },

    showNavigation: function() {
        document.getElementById('detail-next').classList.remove('hidden');
        document.getElementById('detail-prev').classList.remove('hidden');
        document.querySelector('#detail-next-mobile').classList.remove('hidden');
        document.querySelector('#detail-prev-mobile').classList.remove('hidden');
        this.navigation = 1;
    },

    getPrev: function(id) {
        if (id) {
            let element = document.querySelector('.search-result li[data-id="' + id + '"]');

            if (!element) {
                return { id: null, url: null };
            }

            let elemPrev = element.previousElementSibling;
            if (elemPrev) {
                if (!elemPrev.classList.contains('icon--item')) {
                    elemPrev = elemPrev.previousElementSibling;
                }

                if (elemPrev) {
                    let idPrev = elemPrev.dataset.id;
                    let prev = elemPrev.querySelector('a');
                    if (prev && id) {
                        return { id: idPrev, url: prev.href };
                    }
                }
            }
        }
        return { id: null, url: null };
    },

    getPrevElement: function(id) {
        if (id) {
            let element = document.querySelector('.search-result li[data-id="' + id + '"]');

            if (!element) {
                return null;
            }

            let elemPrev = element.previousElementSibling;
            if (elemPrev) {
                if (!elemPrev.classList.contains('icon--item')) {
                    elemPrev = elemPrev.previousElementSibling;
                }

                if (elemPrev && elemPrev.dataset.id) {
                    return elemPrev;
                }
            }
        }
        return null;
    },

    getNext: function(id) {
        if (id) {
            let element = document.querySelector('.search-result li[data-id="' + id + '"]');
            if (!element) {
                return { id: null, url: null };
            }

            let elemNext = element.nextElementSibling;
            if (elemNext) {
                if (!elemNext.classList.contains('icon--item')) {
                    elemNext = elemNext.nextElementSibling;
                }

                if (elemNext) {
                    let idNext = elemNext.dataset.id;
                    let next = elemNext.querySelector('a');
                    if (next && id) {
                        return { id: idNext, url: next.href };
                    }
                }
            }
        }
        return { id: null, url: null };
    },

    getNextElement: function(id) {
        if (id) {
            let element = document.querySelector('.search-result li[data-id="' + id + '"]');

            if (!element) {
                return null;
            }

            let elemNext = element.nextElementSibling;
            if (elemNext) {
                if (!elemNext.classList.contains('icon--item')) {
                    elemNext = elemNext.nextElementSibling;
                }

                if (elemNext && elemNext.dataset.id) {
                    return elemNext;
                }
            }
        }
        return null;
    },

    goNext: function() {
        ga('send', 'event', 'Detail View', 'Move', 'Next');
        this.goTo('next');
    },

    goPrev: function() {
        ga('send', 'event', 'Detail View', 'Move', 'Previous');
        this.goTo('prev');
    },

    goTo: function(direction) {
        var element = direction == 'prev' ? this.getPrevElement(this.id) : this.getNextElement(this.id);
        if (ww.Editor) {
            ww.Editor.close();
        }

        let linkElement = element.querySelector('.link-icon-detail');
        ModalDetail.pushState({ page: 'icon' }, document.title, linkElement.href + (linkElement.dataset.src || ''));

        ww.Resource.setResourceFromElement(element);
        if (ww.Resource.resource && ww.Resource.resource.type) {
            ww.Resource.setResourceConfig(ww.Resource.resource.type);
        }

        Detail.getModalDetail(element).then(function() {
            Premium.check();
            ModalDetail.id = element.dataset.id;
            ModalDetail.viewedIcons++;

            var nextElement =
                direction == 'prev'
                    ? ModalDetail.getPrevElement(ModalDetail.id)
                    : ModalDetail.getNextElement(ModalDetail.id);
            if (direction == 'next') {
                document.getElementById(`detail-next`).classList.toggle('hidden', nextElement == null);
                document.getElementById(`detail-prev`).classList.toggle('hidden', !nextElement == null);
                document.querySelector(`#detail-next-mobile`).classList.toggle('hidden', nextElement == null);
                document.querySelector(`#detail-prev-mobile`).classList.toggle('hidden', !nextElement == null);
            } else {
                document.getElementById(`detail-prev`).classList.toggle('hidden', nextElement == null);
                document.getElementById(`detail-next`).classList.toggle('hidden', !nextElement == null);
                document.querySelector(`#detail-prev-mobile`).classList.toggle('hidden', nextElement == null);
                document.querySelector(`#detail-next-mobile`).classList.toggle('hidden', !nextElement == null);
            }

            ModalDetail.updateOGData(element.dataset.premium, element.dataset.icon_type);
        });
    },

    go: function(url) {
        if (url) {
            if (!document.querySelector('#detail.loaded')) {
                var id = this.getId(url);

                if (id) {
                    if (!this.prev_url) {
                        this.prev_url = document.location.href;
                    }

                    this.pushState({ page: 'icon' }, document.title, url);
                    this.id = id;
                    this.getDetail(url);
                    return;
                }
            } else {
                document.location.href = url;
            }
        }
        this.close();
    },

    close: function() {
        document.querySelector('body').classList.remove('unscrollable');
        var container = document.getElementById('detail-overlay');
        container.classList.remove('loading', 'ready');
        container.classList.remove('hidden');
        container.querySelector('.content').innerHTML = '';

        this.restoreOGData();

        this.viewedIcons = 0;
        this.id = 0;
        //new Likes();
    },

    getId: function(url) {
        var a = document.querySelector('a[href="' + url + '"]');
        var id = a ? parseInt(a.parentNode.parentNode.dataset.id, 10) : null;
        return id;
    },

    getElementByUrl: function(url) {
        var a = document.querySelector('.icon--holder a[href="' + url + '"]');
        var element = a ? a.closest('li.icon--item') : null;
        return element;
    },

    pushState: function(state, title, url) {
        const event = new CustomEvent('pushstate', {
            state,
            title,
            url,
        });

        if (typeof window.history == 'object') {
            window.history.pushState(state, title, url);
        } else {
            History.pushState(state, title, url);
        }

        window.dispatchEvent(event);
    },

    replaceState: function(state, title, url) {
        if (typeof window.history == 'object') {
            window.history.replaceState(state, title, url);
        } else {
            History.replaceState(state, title, url);
        }
    },

    back: function(delta) {
        if (typeof window.history == 'object') {
            if (delta) {
                window.history.go(delta);
            } else {
                window.history.back();
            }
        } else {
            History.back();
        }
    },

    saveOGData: function() {
        var attribute;

        this.og_data.title = document.title;

        var data = this.og_data;

        this.og_keys.forEach(function(key) {
            var attribute = key.indexOf('twitter') != -1 || key.indexOf('pinterest') != -1 ? 'name' : 'property';
            if (document.querySelector('meta[' + attribute + '="' + key + '"]') !== null) {
                data[key] = document.querySelector('meta[' + attribute + '="' + key + '"]').content;
            }
        });

        this.og_data = data;
    },

    restoreOGData: function() {
        var value;
        for (var key in this.og_data) {
            value = this.og_data[key];
            if (key == 'title') {
                document.title = value;
                continue;
            }
            var attribute = key.indexOf('twitter') !== -1 || key.indexOf('pinterest') != -1 ? 'name' : 'property';
            if (document.querySelector('meta[' + attribute + '="' + key + '"]') !== null) {
                document.querySelector('meta[' + attribute + '="' + key + '"]').content = value;
            }
        }
        if (document.querySelector('meta[property="og:image:alt"]')) {
            if (ww.view.section === 'pack-detail') {
                document.querySelector('meta[property="og:image:alt"]').content = document.querySelector(
                    'meta[property="og:title"]',
                ).content;
            } else {
                document.querySelector('meta[property="og:image:alt"]').remove();
            }
        }
    },

    updateOGData: function(premium, iconType) {
        let title = messages.og.title;
        let description = messages.og.desc;

        if (iconType === 'sticker') {
            title = title.replace('icons', 'stickers');
            description = description.replace('vector icon', 'sticker');
        }

        var is_premium = premium == 1;
        var _search = is_premium ? messages.common.free + ' ' : 'premium ';
        var _replace = is_premium ? 'premium ' : messages.common.free + ' ';

        var team = document.querySelector('#download-form #team').value;
        var id = parseInt(document.querySelector('#download-form #icon_id').value, 10);

        this.og_keys.forEach(function(key) {
            var splitted = key.split(':');
            var type = key.split(':')[1];
            var attribute = splitted[0] == 'twitter' || splitted[0] == 'pinterest' ? 'name' : 'property';

            if (document.querySelector('meta[' + attribute + '="' + key + '"]') !== null) {
                switch (type) {
                    case 'description':
                        document.querySelector('meta[' + attribute + '="' + key + '"]').content = description.replace(
                            new RegExp('(' + _search + ')', 'gi'),
                            _replace,
                        );
                        if (splitted[0] === 'og' || splitted[0] === 'twitter') {
                            const allTags = document.querySelectorAll('.detail-footer .tags .tag--related');
                            let tagsToShow = [];
                            let totalTags = allTags.length;
                            if (totalTags > 3) {
                                totalTags = 3;
                            }
                            for (let i = 0; i < totalTags; i++) {
                                let tagToPush = allTags[i].innerHTML.replace(/ /g, '');
                                tagsToShow.push(`#${tagToPush}`);
                            }

                            let tagsToString = tagsToShow.join(' ');

                            let ogDescriptionToSet = '';
                            const resourceName = ww.Resource.resource.name;
                            if (iconType === 'standard') {
                                ogDescriptionToSet = `${messages.ogDescriptions.standard.replace(
                                    '%s',
                                    ww.Resource.resource.category.name,
                                )} #flaticon #${messages.detail.icon} ${tagsToString}`;
                            }
                            if (iconType === 'sticker') {
                                ogDescriptionToSet = `${messages.ogDescriptions.sticker
                                    .replace('%s', resourceName)
                                    .replace('%s', ww.Resource.resource.category.name)} #flaticon #${
                                    messages.detail.sticker
                                } ${tagsToString}`;
                            }
                            if (iconType === 'uicon') {
                                ogDescriptionToSet = `${messages.ogDescriptions.uicon.replace(
                                    '%s',
                                    resourceName,
                                )} #flaticon #uicon ${tagsToString}`;
                            }
                            if (iconType === 'animated-icon') {
                                ogDescriptionToSet = `${messages.ogDescriptions.animatedIcon.replace(
                                    '%s',
                                    resourceName,
                                )} #flaticon #${messages.detail.animatedicon} ${tagsToString}`;
                            }
                            document.querySelector(
                                'meta[' + attribute + '="' + key + '"]',
                            ).content = ogDescriptionToSet;
                        }

                        break;
                    case 'title':
                        if (ww.Resource.resource.type === resourceTypes.animatedIcon) {
                            title = messages.og.animatedIconTitle;
                            document.querySelector('meta[' + attribute + '="' + key + '"]').content = title
                                .replace('{0}', ww.Resource.resource.name)
                                .replace('{1}', ww.Resource.resource.keyword.name);
                        } else {
                            let iconDescription = document.querySelector('.detail__sidebar h1').innerHTML;
                            let authorName = '';
                            if (document.querySelector('.author .username')) {
                                authorName = document.querySelector('.author .username').innerHTML;
                            }

                            document.querySelector('meta[' + attribute + '="' + key + '"]').content = title
                                .format(iconDescription, authorName)
                                .replace(new RegExp('(' + _search + ')', 'gi'), _replace);
                        }
                        break;
                    case 'url':
                        document.querySelector('meta[' + attribute + '="' + key + '"]').content =
                            document.location.href;
                        break;
                }
            }
        });

        var keywords = document.querySelector('meta[name="keywords"]');
        if (keywords) keywords.content = keywords.content.replace(new RegExp('(' + _search + ')', 'gi'), _replace);

        let imageog = document.querySelector('.icon-png-container').dataset.png;

        document.querySelector('meta[property="og:image"]').content = imageog;
        document.querySelector('meta[name="twitter:image"]').content = imageog;
        let imageAltMeta = document.querySelector('meta[property="og:image:alt"]');
        if (imageAltMeta && ww.Resource.resource.type !== 'animated-icon') {
            imageAltMeta.content = document.querySelector(`.detail__icon__inner div[data-id="${id}"] img`).alt;
        } else {
            imageAltMeta = document.createElement('meta');
            imageAltMeta.setAttribute('property', 'og:image:alt');
            imageAltMeta.content =
                ww.Resource.resource.type !== 'animated-icon'
                    ? document.querySelector(`a[data-id="${id}"] img`).alt
                    : '';
            document.querySelector('meta[property="og:image"]').insertAdjacentElement('afterEnd', imageAltMeta);
        }

        if (document.querySelector('meta[name="pinterest:image"]') !== null) {
            document.querySelector('meta[name="pinterest:image"]').content = imageog;
        }
    },
};
